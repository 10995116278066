/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import forEach from 'lodash/forEach';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import assign from 'lodash/assign';
import BasicService from '../Basic/BasicService';

export const FAMILY_COUNT_STATUS = {
    LOADING: -1,
    ERROR: -2,
};

const SAVE_TO_WORKSPACE_AUTO_UPDATE = 'SAVE_TO_WORKSPACE:AUTO_UPDATE';
class PatentService extends BasicService {
    constructor() {
        super();
        this.type = 'PATENT';
        this.searchGroupMode = {
            searchMode: 'publication', // 同族库(family)  公开库(publication)
            groupMode: 'EXTEND', // 分组类型 默认为 ALL
            selectedGroupCounts: 0, // 选中同族背后实际专利数量
        };
        this.searchGroupModeRange = 5000; // 同族from to 限制

        this.familyRangeCount = 0; // 选择同族范围后实际专利数量

        this.allGroupCounts = 0; // 全部同族背后实际专利数量

        this.familyRangeCountLoading = false;

        this.allGroupCountLoading = false;

        this.enableAutoUpdate = false;
        this.enableFamily = false;
        this.showRangeTip = true;

        this.rangeSelector = {
            ...this.rangeSelector,
            maxInputLimit: 100000,
            groupCounts: 0, // 同族分组后的数量
            family: {
                enable: false,
                selected: null,
                init: false,
                get ready() {
                    if (!this.selected) return true;

                    if (this.selected.loading) return false; // 接口请求中

                    if (this.selected.count === FAMILY_COUNT_STATUS.ERROR) return false; // 接口出错

                    return true;
                },
                familyExpansions: [{
                    key: 'apno_count',
                    value: 'APNO',
                    label: 'selectedPatentsApno',
                    count: FAMILY_COUNT_STATUS.LOADING,
                    get loading() { return this.count === FAMILY_COUNT_STATUS.LOADING; },
                }, {
                    key: 'simple_count',
                    value: 'SIMPLE',
                    label: 'selectedPatentsSimple',
                    count: FAMILY_COUNT_STATUS.LOADING,
                    get loading() { return this.count === FAMILY_COUNT_STATUS.LOADING; },
                }, {
                    key: 'inpadoc_count',
                    value: 'INPADOC',
                    label: 'selectedPatentsINPADOC',
                    count: FAMILY_COUNT_STATUS.LOADING,
                    get loading() { return this.count === FAMILY_COUNT_STATUS.LOADING; },
                }, {
                    key: 'extend_count',
                    value: 'EXTEND',
                    label: 'selectedPatentsExtended',
                    count: FAMILY_COUNT_STATUS.LOADING,
                    get loading() { return this.count === FAMILY_COUNT_STATUS.LOADING; },
                }],
            },
        };

        this.autoUpdate = {
            q: '',
            efq: '',
            enable: this.getAutoUpdateStorageStatus(),
        };
    }

    get familyMode() {
        return this.searchGroupMode.searchMode === 'family';
    }

    get groupMode() {
        return this.searchGroupMode.groupMode !== 'ALL';
    }

    getFolderIcon({ folder }) {
        if (folder.folder_auto_update) {
            return 'SolidAutoFolder';
        }
        return 'SolidFolderClosed2';
    }

    isFolderDisabled({ folder }) {
        if (!this.enableAutoUpdate) return false;

        // 如果开启了自动收录，则已开启自动收录的文件夹将不能选择
        return folder.folder_auto_update && this.autoUpdate.enable;
    }

    selectFolder(folder) {
        super.selectFolder(folder);
        // 如果自动选中了已经开启自动收录的文件夹，则关闭自动收录功能开关
        // 否则判定是否开启自动收录功能
        if (this.enableAutoUpdate && folder) {
            this.identifyAutoUpdateStatus();
        }
    }

    getWorkspaceElementsCountString(workspace) {
        const { total_patent_count = 0 } = workspace;
        const count = this.formatNumber(total_patent_count);
        return `(${count})`;
    }

    getFolderElementsCountString({ folder }) {
        const { patent_count = 0 } = folder;
        return `(${this.formatNumber(patent_count)})`;
    }

    getSubmitApiConfig() {
        const { selectedWorkspace: { space_id }, selectedFolder: { folder_id } } = this.folderSelector;
        return {
            url: `${this.baseUrl}/space/${space_id}/folder/${folder_id}/patents/v2`,
            method: 'PUT',
        };
    }

    getDisabledAllTypeTooltip() {
        if (!this.disabledAllType || this.folderSelector.loading) return '';
        const {
            type, formatNumber, rangeSelector: { maxPerFolder }, vm,
        } = this;
        return vm.$t(
            `BaseSaveToWorkspace.${type}.RangeSelector.error.disabledSelectAllError`,
            { limit: formatNumber(maxPerFolder) },
        );
    }

    isSubmitButtonDisabled() {
        const { rangeSelector: { family } } = this;
        if ((this.enableFamily && family.enable && !family.ready) || this.familyRangeCountLoading || this.allGroupCountLoading) return true; // 同族功能开启，并且接口等待中,或者出错
        return super.isSubmitButtonDisabled();
    }

    getCurrentParams() {
        const params = super.getCurrentParams();

        const {
            enableFamily,
            enableAutoUpdate,
            rangeSelector,
            autoUpdate,
            searchGroupMode,
        } = this;

        const {
            family, selectedType,
        } = rangeSelector;
        const { searchMode } = searchGroupMode;

        if (enableFamily && family.enable) {
            params.familyExpansion = family.selected.value;
            if (params.isAll) {
                // 在SRP，开启同族时，end(也就是to)的数量，以前是选中的同族的数量，start是1
                // 可能后端用来end-start来判断上限，但是现在后端会依据patentCount来判断，所以此处的to的逻辑理论可以移除，
                // 但是目前不会造成实质问题，所以不处理，如果后面需要支持范围选择的同族扩展，则需要前后端一起修改（前端只需移除这一行代码）
                params.to = family.selected.count;
                params.counts = family.selected.count;
            }
        }

        if (this.familyMode) {
            params.search_mode = searchMode;
        }

        if (this.familyMode && this.groupMode) {
            if (selectedType === 'RANGE') {
                params.counts = this.searchGroupMode.selectedGroupCounts || this.familyRangeCount;
            } else {
                params.counts = this.searchGroupMode.selectedGroupCounts || this.allGroupCounts;
            }
        }

        if (enableAutoUpdate && autoUpdate.enable) {
            params.autoUpdate = true;
        }

        return params;
    }

    initFolderSelector() {
        this
            .fetchWorkspaces()
            .then(() => {
                if (!this.folderSelector.response) return;
                const addLimit = get(this.folderSelector, 'response.addLimit');
                const maxPerFolder = get(this.folderSelector, 'response.maxPerFolder');

                this.rangeSelector.addLimit = addLimit;
                this.rangeSelector.maxPerFolder = maxPerFolder;
                this.rangeSelector.disabledTypes = [];
                const {
                    maxInputLimit, allCounts, groupCounts, to,
                } = this.rangeSelector;
                const { selectedGroupCounts } = this.searchGroupMode;

                if (this.familyMode && this.groupMode) {
                    const maxCounts = groupCounts || allCounts;
                    if (maxCounts > maxPerFolder) {
                        this.rangeSelector.selectedType = 'RANGE';
                        this.rangeSelector.disabledTypes = ['ALL'];
                    } else if (selectedGroupCounts === 0) {
                        // 代表超过限制不发送请求
                        this.getAllGroupCounts(maxPerFolder);
                    }
                    if (selectedGroupCounts === 0) {
                        this.getFamilyRangeCount();
                    }
                } else {
                    this.rangeSelector.countMax = groupCounts || allCounts;
                    if (allCounts > maxPerFolder) {
                        this.rangeSelector.selectedType = 'RANGE';
                        this.rangeSelector.disabledTypes = ['ALL'];
                    }
                }

                this.rangeSelector.max = Math.min(this.rangeSelector.countMax, maxInputLimit, addLimit);

                if (this.familyMode && this.groupMode) {
                    this.rangeSelector.to = to;
                } else {
                    this.rangeSelector.to = this.rangeSelector.max;
                }

                this.verifyRange();
            });
    }

    // 高级 - 自动收录
    toggleAutoUpdate() {
        const { autoUpdate } = this;
        const status = !autoUpdate.enable;
        autoUpdate.enable = status;
        this.setAutoUpdateStorageStatus(status);
    }

    // 识别自动收录状态
    identifyAutoUpdateStatus() {
        const isRangeType = this.rangeSelector.selectedType === 'RANGE';
        const isRangeFamilyEnable = this.rangeSelector.family.enable;
        const isAutoUpdateFolder = get(this.folderSelector, 'selectedFolder.folder_auto_update');
        if (isRangeType || isRangeFamilyEnable || isAutoUpdateFolder) {
            this.autoUpdate.enable = false;
        } else {
            this.autoUpdate.enable = this.getAutoUpdateStorageStatus();
        }
    }

    // 高级 - 同族
    generateFamilyExpansionParams() {
        return Promise.reject(new Error({
            _error_msg: 'Please implementation method <generateFamilyExpansionParams>',
        }));
    }

    handleFamilyError() {
        const { family } = this.rangeSelector;
        const { selected } = family;

        if (!selected) return false;

        const { count } = selected;
        if (count === FAMILY_COUNT_STATUS.ERROR) {
            this.onToastError({ message: this.vm.$t('BaseSaveToWorkspace.RangeSelector.family.error') });
        }
        return true;
    }

    async initFamily() {
        const { family } = this.rangeSelector;
        const { familyExpansions } = family;
        if (family.init || !family.enable) {
            return;
        }

        family.init = true;
        // eslint-disable-next-line prefer-destructuring
        family.selected = family.familyExpansions[0]; // 默认选中简单同族
        family.promiseUID = uniqueId();
        const { promiseUID } = family;

        let baseParams = {};
        try {
            baseParams = await this.generateFamilyExpansionParams(this.getCurrentParams());
        } catch (error) {
            this.onError(error, { module: 'Family' });
            return;
        }

        forEach(familyExpansions, (familyExpansionItem) => {
            this.http({
                url: `${this.baseUrl}/family-expansion-count`,
                method: 'POST',
                data: {
                    ...baseParams,
                    family_expansion: familyExpansionItem.value,
                },
            })
                .then((count) => {
                    if (promiseUID !== family.promiseUID) return;
                    familyExpansionItem.count = count >= 0 ? count : FAMILY_COUNT_STATUS.ERROR;
                })
                .catch(() => {
                    if (promiseUID !== family.promiseUID) return;
                    familyExpansionItem.count = FAMILY_COUNT_STATUS.ERROR;
                    this.handleFamilyError();
                });
        });
    }

    async getFamilyRangeCount() {
        // 不合法不发送请求
        if (!this.verifyRange()) return;
        // 仅在同族库切分组的时候请求组背后数量
        if (!this.familyMode || (this.familyMode && !this.groupMode)) return;
        this.familyRangeCountLoading = true;
        const countParam = {
            start: this.rangeSelector.from,
            end: this.rangeSelector.to,
            save_all: false,
        };
        const params = await this.generateSubmitSaveToWorkspaceParams(this.getCurrentParams());
        params.auto = false;
        params.range_params = assign({}, params.range_params, countParam);
        delete params.range_params.dbs;
        this.http({
            url: `${this.baseUrl}/family-base/count`,
            method: 'POST',
            data: {
                ...params,
            },
        })
            .then((data) => {
                this.familyRangeCount = data;
            })
            .catch(() => {
                this.familyRangeCount = 0;
            }).finally(() => {
                this.familyRangeCountLoading = false;
            });
    }

    async getAllGroupCounts(maxPerFolder) {
        // 仅在同族库切分组的时候请求组背后数量
        if (!this.familyMode || (this.familyMode && !this.groupMode) || !this.enableRange) return;
        this.allGroupCountLoading = true;
        const params = await this.generateSubmitSaveToWorkspaceParams(this.getCurrentParams());
        params.auto = false;
        params.range_params.save_all = true;
        delete params.range_params.dbs;
        this.http({
            url: `${this.baseUrl}/family-base/count`,
            method: 'POST',
            data: {
                ...params,
            },
        })
            .then((data) => {
                this.allGroupCounts = data || 1;
                // 专利或者同族数量大于 maxPerFolder
                if (this.allGroupCounts > maxPerFolder) {
                    this.rangeSelector.selectedType = 'RANGE';
                    this.rangeSelector.disabledTypes = ['ALL'];
                }
            })
            .catch(() => {
                this.allGroupCounts = 0;
            }).finally(() => {
                this.allGroupCountLoading = false;
            });
    }

    init() {
        this.initFolderSelector();
    }

    selectFamilyExpansion(item) {
        this.initFamily();
        this.rangeSelector.family.selected = item;
        this.handleFamilyError();
    }

    toggleFamily() {
        const { rangeSelector: { family }, enableAutoUpdate } = this;
        const status = !family.enable;
        family.enable = status;

        // 开启同族后，关闭并禁止开启自动收录
        // 关闭同族后，判定是否重新开启自动收录
        if (enableAutoUpdate) {
            this.identifyAutoUpdateStatus();
        }
        this.initFamily();
    }

    getAutoUpdateStorageStatus() {
        return localStorage.getItem(SAVE_TO_WORKSPACE_AUTO_UPDATE) === 'true';
    }

    setAutoUpdateStorageStatus(status) {
        localStorage.setItem(SAVE_TO_WORKSPACE_AUTO_UPDATE, status);
    }

    verifyRange() {
        const { enableRange } = this;
        const {
            from, to, addLimit, maxInputLimit, selectedType, allCounts, groupCounts,
        } = this.rangeSelector;
        this.rangeSelector.error = null;

        if (!enableRange || selectedType === 'ALL') {
            return true;
        }

        if (!from || !to || to < from) {
            this.rangeSelector.error = this.vm.$t(`BaseSaveToWorkspace.${this.type}.RangeSelector.error.invalidError`);
            return false;
        }

        if (from > maxInputLimit || to > maxInputLimit) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.overMaxCountError`,
                { limit: this.formatNumber(maxInputLimit) },
            );
            return false;
        }

        let maxCounts = this.rangeSelector.countMax;
        if (this.familyMode && this.groupMode) {
            maxCounts = groupCounts || allCounts;
        }

        if (from > maxCounts || to > maxCounts) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.invalidError`,
            );
            return false;
        }

        if ((to - from + 1) > maxCounts) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.invalidError`,
            );
            return false;
        }

        if (this.familyMode && this.groupMode) {
            if ((to - from + 1) > this.searchGroupModeRange) {
                this.rangeSelector.error = this.vm.$t(
                    `BaseSaveToWorkspace.${this.type}.RangeSelector.error.familyRangeError`,
                    { limit: this.formatNumber(this.searchGroupModeRange) },
                );
                return false;
            }
        } else if ((to - from + 1) > addLimit) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.rangeError`,
                { limit: this.formatNumber(addLimit) },
            );
            return false;
        }

        return true;
    }
}

export default PatentService;
