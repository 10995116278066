<script>
import Vue from 'vue';
import tippy from 'tippy.js';

const defaultTippyConfig = {
    placement: 'left-start',
    appendTo: document.body,
};

function pickConfigFromVueRootOptions($vm) {
    const options = {};
    if ($vm.$root && $vm.$root.$options) {
        ['router', 'store'].forEach((key) => {
            if (key in $vm.$root.$options) {
                Object.assign(options, { [key]: $vm.$root.$options[key] });
            }
        });
    }
    if ($vm.$root && $vm.$root.$options && 'i18n' in $vm.$root.$options) {
        Object.assign(options, { i18n: $vm.$root.$options.i18n });
    } else if ($vm.$i18n) { // 兼容局部注册国际化
        Object.assign(options, { i18n: $vm.$i18n });
    }
    return options;
}
// eslint-disable-next-line vue/one-component-per-file
export default {
    name: 'PtPopper',
    props: {
        /**
        * 需要渲染的组件
        */
        component: {
            type: [Object, Function],
            required: true,
        },
        /**
        * 为渲染的组件注入的props数据
        */
        data: {
            type: Object,
            default: () => ({}),
        },
        /**
        * 实例vue时,需要的额外参数(router,store,i18n)
        * 可以不传, 默认会从 this.$root.$options 去取
        */
        vueRootOptions: {
            type: Object,
            default: () => ({}),
        },
        /**
        * tippy配置
        * 详见: https://atomiks.github.io/tippyjs/v6/all-props/
        * 默认配置: {
        *       placement: 'left-start',
        *       appendTo: document.body,
        *   }
        */
        tippyConfig: {
            type: Object,
            default: () => ({}),
        },
        // eslint-disable-next-line vue/require-default-prop
        getZIndex: {
            type: Function,
        },
    },
    mounted() {
        this.tippyInstance = tippy(this.$el, {
            ...defaultTippyConfig,
            ...this.tippyConfig,
            onShow: (instance) => {
                if (!this.vm) {
                    // eslint-disable-next-line vue/one-component-per-file
                    const PtPopperContent = Vue.extend({
                        name: 'PtPopperContent',
                        ...pickConfigFromVueRootOptions(this),
                        ...this.vueRootOptions,
                        render: (h) => h(this.component, {
                            props: this.data,
                            on: {
                                update: () => {
                                    this.$nextTick(() => {
                                        instance.setContent(this.vm.$el);
                                    });
                                },
                            },
                        }),
                    });
                    this.vm = new PtPopperContent().$mount();
                    instance.setContent(this.vm.$el);
                }
                if (this.getZIndex && typeof this.getZIndex === 'function') {
                    instance.setProps({
                        zIndex: this.getZIndex(),
                    });
                }
            },

        });
    },
    beforeDestroy() {
        if (this.vm) {
            this.vm.$destroy();
        }
        if (this.tippyInstance) {
            this.tippyInstance.destroy();
        }
    },
    render() {
        return this.$slots.default;
    },
};
</script>
