var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "patsnap-biz-degenerate_info",
      class: { "is-common": _vm.isCommon },
    },
    [
      _vm.isCommon
        ? _c("div", [
            _c("span", [
              _vm._v(_vm._s(_vm.charInfo.location) + " | "),
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.commonReplaces && _vm.commonReplaces.length
                      ? _vm.lightMatch(_vm.commonReplaces)
                      : "-"
                  ),
                },
              }),
            ]),
          ])
        : [
            _c("div", { staticClass: "degenerate-info__title-container" }, [
              _vm.queryInfo
                ? _c("div", { staticClass: "degenerate-info__title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("degenerateInfo.query")) + ": "),
                    _c("span", { staticClass: "degenerate-info__title-num" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.queryInfo.qChar) +
                          "(" +
                          _vm._s(_vm.queryInfo.qNo) +
                          ") "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "degenerate-info__title" }, [
                _vm._v(" " + _vm._s(_vm.$t("degenerateInfo.subject")) + ": "),
                _c("span", { staticClass: "degenerate-info__title-num" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.charInfo.degenerateCode) +
                      "(" +
                      _vm._s(_vm.charInfo.location) +
                      ") "
                  ),
                ]),
              ]),
            ]),
            !_vm.isDegenerate
              ? _c(
                  "table",
                  { staticClass: "degenerate-info__pn-table" },
                  [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("degenerateInfo.description")) +
                            " "
                        ),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("degenerateInfo.pn")))]),
                    ]),
                    !_vm.replaces || !_vm.replaces.length
                      ? _c("tr", [
                          _c("td", [_vm._v("-")]),
                          _c("td", [_vm._v("-")]),
                        ])
                      : _vm._l(_vm.replaces, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.lightMatch(item.replaces, item.group)
                                ),
                              },
                            }),
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "degenerate-info__pn-table-link",
                                  attrs: {
                                    href: item.url
                                      ? item.url
                                      : "javascript:void(0)",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(item.pn))]
                              ),
                            ]),
                          ])
                        }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.isDegenerate
              ? _c(
                  "table",
                  {
                    staticClass:
                      "degenerate-info__pn-table degenerate-info__pn-table-degenerate",
                  },
                  [
                    _c("tr", [
                      _c("th", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("degenerateInfo.description")) +
                            " "
                        ),
                      ]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("degenerateInfo.pn")))]),
                    ]),
                    !_vm.bestGroup.length
                      ? _c("tr", [
                          _c("td", [_vm._v("-")]),
                          _c("td", [_vm._v("-")]),
                        ])
                      : _vm._l(_vm.bestGroup, function (item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.lightMatch(
                                    item[0].replaces,
                                    item[0].group
                                  )
                                ),
                              },
                            }),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass: "degenerate-info__pn-container",
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "degenerate-info__pn-table-link",
                                        attrs: {
                                          href: item[0].url
                                            ? item[0].url
                                            : "javascript:void(0)",
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(item[0].pn))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.length > 1 &&
                                              !_vm.isShowMore,
                                            expression:
                                              "item.length > 1 && !isShowMore",
                                          },
                                        ],
                                        staticClass:
                                          "degenerate-info__more-text",
                                        on: {
                                          click: function ($event) {
                                            _vm.isShowMore = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " [+" + _vm._s(item.length - 1) + "] "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm.isShowMore
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          item.slice(1),
                                          function (subItem, subIndex) {
                                            return _c(
                                              "div",
                                              { key: subIndex },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "degenerate-info__pn-table-link",
                                                    attrs: {
                                                      href: subItem.url
                                                        ? subItem.url
                                                        : "javascript:void(0)",
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(subItem.pn))]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isShowMore,
                                          expression: "isShowMore",
                                        },
                                      ],
                                      staticClass: "degenerate-info__more-text",
                                      on: {
                                        click: function ($event) {
                                          _vm.isShowMore = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" ["),
                                      _c("PtIcon", {
                                        attrs: { icon: "SolidArrowSmallUp" },
                                      }),
                                      _vm._v("] "),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.bestGroup.length
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "degenerate-info__best-match",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("degenerateInfo.bestMatch")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        }),
                  ],
                  2
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }