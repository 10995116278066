var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "s2w-family-selector-container",
      class: {
        disabled: _vm.disabled,
      },
    },
    [
      _c("div", {
        staticClass: "s2w-family-switch",
        class: { enable: _vm.enable },
        on: {
          click: function ($event) {
            return _vm.toggleFamily()
          },
        },
      }),
      _c("div", { staticClass: "s2w-family-desc" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("BaseSaveToWorkspace.RangeSelector.family.label")) +
            " "
        ),
      ]),
      _vm.enable
        ? _c("div", { staticClass: "s2w-family-selector" }, [
            _c(
              "div",
              {
                staticClass: "s2w-family-selector-current",
                class: {
                  active: _vm.showFamilyPopper,
                },
                on: {
                  mousedown: function ($event) {
                    $event.stopPropagation()
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.showFamilyPopper = !_vm.showFamilyPopper
                  },
                },
              },
              [
                _vm.selected
                  ? [
                      _c("div", { staticClass: "s2w-family-selector-label" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "BaseSaveToWorkspace.RangeSelector.family.types." +
                                  _vm.selected.value
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _vm.selected.loading
                        ? _c("PtIcon", {
                            staticStyle: { "font-size": "24px" },
                            attrs: { icon: "SolidLoadingBars" },
                          })
                        : _vm.selected.count >= 0
                        ? _c("div", [
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.service.formatNumber(_vm.selected.count)
                                ) +
                                ") "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _c("PtIcon", {
                  staticStyle: { "font-size": "24px" },
                  attrs: {
                    icon: _vm.showFamilyPopper
                      ? "SolidDropdownClose"
                      : "SolidDropdownOpen",
                  },
                }),
              ],
              2
            ),
            _vm.showFamilyPopper
              ? _c(
                  "div",
                  {
                    staticClass: "s2w-family-selector-popper",
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  _vm._l(_vm.familyExpansions, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.value,
                        staticClass: "s2w-family-selector-item",
                        class: { current: _vm.selected === item },
                        on: {
                          click: function ($event) {
                            return _vm.selectFamilyExpansion(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "s2w-family-selector-item-info" },
                          [
                            _c(
                              "div",
                              { staticClass: "s2w-family-selector-label" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "BaseSaveToWorkspace.RangeSelector.family.types." +
                                          item.value
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            item.loading
                              ? _c("PtIcon", {
                                  staticStyle: { "font-size": "24px" },
                                  attrs: { icon: "SolidLoadingBars" },
                                })
                              : _vm.selected.count >= 0
                              ? _c("div", [
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm.service.formatNumber(item.count)
                                      ) +
                                      ") "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("PtIcon", {
                          staticClass: "s2w-family-selector-item-tick",
                          staticStyle: { "font-size": "24px" },
                          attrs: { icon: "SolidTick" },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }