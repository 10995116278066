import { Token } from './Token';

/**
 * Represents a correction applied during parsing of the token sequence.
 */
export class Correction {
  /**
   * @param type
   * Correction type constant.
   * Defined by the language implementation.
   * @param tokens
   * An array of tokens that correction affects.
   */
  constructor(public type: number, public tokens: Token[]) {}
}
