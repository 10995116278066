import { Node } from '../../../Values/Node';
import { Token } from '../../../Values/Token';
import { GroupBegin } from '../Token';

/**
 * Group Node Visitor implementation.
 */
export class Group extends Node {
  constructor(
    public nodes: Node[] = [],
    public tokenLeft?: GroupBegin,
    public tokenRight?: Token,
  ) {
    super();
  }

  public getNodes() {
    return this.nodes;
  }

  public getReductionGroup() {
    return 'group';
  }
}
