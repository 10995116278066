import { TOKEN_TERM } from '../../../constants';
import { Token } from '../../../Values/Token';

/**
 * Phrase term token.
 *
 */
export class Phrase extends Token {
  constructor(
    lexeme: string,
    position: number,
    public domain: string | null,
    public quote: string,
    public phrase: string,
  ) {
    super(TOKEN_TERM, lexeme, position);
  }
}
