import { Iterator } from './dom';

export class SelectionUtil {
  public selection;

  public static _instance: SelectionUtil;

  public static getInstance(): SelectionUtil {
    if (!this._instance) {
      this._instance = new SelectionUtil();
    }
    return this._instance;
  }

  constructor() {
    this.selection = window.getSelection();
  }

  /**
   * Add selection / insert cursor.
   * @param range
   */
  public applyRange(range: Range) {
    this.selection?.removeAllRanges();
    this.selection?.addRange(range);
  }

  /**
   * Get current document selection
   * @returns
   */
  public getWindowSelection() {
    return this.selection;
  }

  public isSelected(el: Element) {
    if (!this.selection?.rangeCount) {
      return null;
    }

    let focusNode = this.selection.focusNode;

    // IE supports Node#contains for elements only
    // thus we ensure we check against an actual Element node
    if (focusNode && this.isText(focusNode)) {
      focusNode = focusNode.parentNode;
    }

    return el === focusNode || el.contains(focusNode);
  }

  public isText(node: Node) {
    return node.nodeType === 3;
  }
  public isSpan(node: Node) {
    return node.nodeType === 1 && node.nodeName === 'SPAN';
  }

  public isBr(node: Node) {
    return node.nodeType === 1 && node.nodeName === 'BR';
  }

  public isDiv(node: Node) {
    return node.nodeType === 1 && node.nodeName === 'DIV';
  }

  public isParagraph(node: Node) {
    return node.nodeType === 1 && node.nodeName === 'P';
  }

  public setRange(el: Element, selection: any) {
    const range = this.createRange(el, selection);

    this.applyRange(range);
  }

  public createRange(el: Element, selection: any) {
    var start = selection.start;
    var end = selection.end;

    var range = document.createRange();

    var i = new Iterator(el.firstChild, el);

    var next = i.node;
    var isClosing = false;

    var count = 0;
    var length;

    while (next) {
      if (count === start) {
        if (isClosing) {
          range.setStartAfter(next);
        } else {
          range.setStartBefore(next);
        }
      }

      if (count === end) {
        if (isClosing) {
          range.setEndAfter(next);
        } else {
          range.setEndBefore(next);
        }

        return range;
      }

      if (!isClosing) {
        if (
          this.isBr(next) ||
          (next.previousSibling && (this.isDiv(next) || this.isParagraph(next)))
        ) {
          count++;
        }
      }

      if (this.isText(next)) {
        length = next.textContent.length;

        if (count <= start && count + length > start) {
          range.setStart(next, start - count);
        }

        if (count + length > end) {
          range.setEnd(next, end - count);

          return range;
        }

        count += length;
      }

      next = i.next();
      isClosing = i.closingTag;
    }

    // out of range
    if (count <= start) {
      if (el.lastChild) {
        range.setStartAfter(el.lastChild);
      } else {
        range.setStart(el, 0);
      }
    }

    if (el.lastChild) {
      range.setEndAfter(el.lastChild);
    } else {
      range.setEnd(el, 0);
    }

    return range;
  }

  public getRange(el: any) {
    if (!this.isSelected(el)) {
      return null;
    }

    var range = this.selection?.getRangeAt(0);
    if (!range) return null;
    var startContainer = range.startContainer;
    var endContainer = range.endContainer;
    var startOffset = range.startOffset;
    var endOffset = range.endOffset;

    var i = new Iterator(el.firstChild, el);

    var next = i.node;
    var last;

    var isClosing = false;

    var selectionStart;
    var count = 0;

    function isBeforeEnd(node: any, referenceNode?: any) {
      if (arguments.length === 1) {
        referenceNode = node;
      }

      return (
        node.parentNode === endContainer &&
        referenceNode == endContainer.childNodes[endOffset]
      );
    }

    function isBeforeStart(node: any, referenceNode?: any) {
      if (arguments.length === 1) {
        referenceNode = node;
      }

      return (
        node.parentNode === startContainer &&
        referenceNode == startContainer.childNodes[startOffset]
      );
    }

    while (next) {
      // start before node
      if (isBeforeStart(next)) {
        selectionStart = count;
      }

      // end before node
      if (isBeforeEnd(next)) {
        break;
      }

      if (!isClosing) {
        if (
          this.isBr(next) ||
          (last &&
            last.nextSibling == next &&
            (this.isDiv(next) || this.isParagraph(next)))
        ) {
          count++;
        }
      }
      if (this.isText(next)) {
        // #text node
        if (startContainer === next) {
          selectionStart = count + startOffset;
        }

        if (endContainer === next) {
          count += endOffset;
          break;
        }

        count += next.textContent.length;
      } else if (this.isSpan(next) && !next.innerText) {
        if (startContainer === next) {
          selectionStart = count + startOffset;
        }
        if (endContainer === next) {
          count += endOffset;
          break;
        }
      }

      if (this.isText(next) || isClosing) {
        // start before node
        if (isBeforeStart(next, next.nextSibling)) {
          selectionStart = count;
        }

        // end before node
        if (isBeforeEnd(next, next.nextSibling)) {
          break;
        }
      }

      last = next;
      next = i.next();
      isClosing = i.closingTag;
    }
    // selection until end of text
    return {
      start: typeof selectionStart === 'undefined' ? count : selectionStart,
      end: count,
    };
  }
}
