/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import BasicService from '../Basic/BasicService';

class VCService extends BasicService {
    constructor() {
        super();
        this.type = 'VC';
        this.type_alias = 'investment';
    }

    getFolderIcon() {
        // Paper 只有普通文件夹图标
        return 'SolidFolderClosed2';
    }

    getWorkspaceElementsCountString(workspace) {
        const { total_vc_count = 0 } = workspace;
        const count = this.formatNumber(total_vc_count);
        return `(${count})`;
    }

    getFolderElementsCountString({ folder }) {
        const { vc_count = 0 } = folder;
        return `(${this.formatNumber(vc_count)})`;
    }

    getSubmitApiConfig() {
        const { selectedWorkspace: { space_id }, selectedFolder: { folder_id } } = this.folderSelector;
        return {
            url: `${this.baseUrl}/space/${space_id}/folder/${folder_id}/save-discovery-data`,
            method: 'POST',
        };
    }
}

export default VCService;
