var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "representative-item-container" },
    [
      _c(
        "ElSelect",
        {
          attrs: { "popper-class": "popper__no-arrow" },
          model: {
            value: _vm.ruleData.groupBy,
            callback: function ($$v) {
              _vm.$set(_vm.ruleData, "groupBy", $$v)
            },
            expression: "ruleData.groupBy",
          },
        },
        _vm._l(_vm.firstOptions, function (item, idx) {
          return _c("ElOption", {
            key: idx,
            attrs: { label: _vm.$t("app.userSetting." + item), value: item },
          })
        }),
        1
      ),
      _vm.secondOptions
        ? _c(
            "ElSelect",
            {
              attrs: { "popper-class": "popper__no-arrow" },
              model: {
                value: _vm.ruleData.groupSort,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "groupSort", $$v)
                },
                expression: "ruleData.groupSort",
              },
            },
            _vm._l(_vm.secondOptions, function (item, idx) {
              return _c("ElOption", {
                key: idx,
                attrs: {
                  label: _vm.$t("app.userSetting." + item),
                  value: item,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.ruleData.groupBy === "AUTHORITY"
        ? _c(
            "div",
            { staticClass: "priority-selector-box" },
            [
              _c(
                "ElPopover",
                {
                  attrs: {
                    transition: "setting-country-list__popper_transition",
                    "popper-class": [
                      "setting-country-list__popper",
                      _vm.countryPopperClass,
                    ].join(" "),
                    trigger: "manual",
                    value: _vm.isOpen,
                    "visible-arrow": false,
                    placement: "bottom-start",
                  },
                },
                [
                  _c("CountryList", {
                    directives: [
                      {
                        name: "clickoutside",
                        rawName: "v-clickoutside",
                        value: _vm.closeCountryList,
                        expression: "closeCountryList",
                      },
                    ],
                    attrs: {
                      "country-list-config": _vm.countryListConfig,
                      "group-family": _vm.ruleData.groupFamily,
                    },
                    on: { updateSelectCountry: _vm.updateSelectCountry },
                  }),
                  _c("span", {
                    staticClass: "setting-country-list__popper-reference",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  }),
                ],
                1
              ),
              _vm._l(_vm.countryLimit, function (i) {
                return _c(
                  "span",
                  { key: i, staticClass: "priority-selector-item" },
                  [
                    _c("CoutrySelectedName", {
                      attrs: {
                        index: i,
                        "current-key": _vm.countryListConfig.currentKey,
                      },
                      on: {
                        updateCountryListStatus: _vm.updateCountryListStatus,
                      },
                      model: {
                        value: _vm.ruleData.groupFamily[i - 1],
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData.groupFamily, i - 1, $$v)
                        },
                        expression: "ruleData.groupFamily[i-1]",
                      },
                    }),
                    i !== _vm.countryLimit
                      ? _c("PtIcon", {
                          staticStyle: { "font-size": "20px", fill: "#777" },
                          attrs: { width: 20, icon: "SolidArrowRight" },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _c(
                "ElPopover",
                {
                  attrs: {
                    placement: "top-end",
                    "visible-arrow": false,
                    width: "388",
                    trigger: "hover",
                    "popper-class":
                      "user-setting__popover user-setting__popover-country",
                  },
                },
                [
                  _c("div", { staticClass: "single-panel" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("app.userSetting.familyMsg")) + " "
                    ),
                  ]),
                  _c("PtIcon", {
                    staticClass: "hover-icon",
                    staticStyle: { "font-size": "24px", fill: "#B3BAC5" },
                    attrs: { slot: "reference", icon: "SolidInfo" },
                    slot: "reference",
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }