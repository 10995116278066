/* eslint-disable */
import queue from 'async/queue';

const isEdge = String(navigator.appVersion).indexOf('Edge') >= 0;
const parallelCurrentNum = isEdge ? 1 : 20;

const q = queue((task, callback) => {
    const {
        context, image, x1, y1, width, height, targetX, targetY, cvsWidth, cvsHeight,
    } = task;
    try {
	  context.drawImage(image, x1, y1, width, height, targetX, targetY, cvsWidth, cvsHeight);
    } catch (err) {
        console.error(err);
    }
    if (task.gapTime) {
        setTimeout(callback, task.gapTime);
    } else {
        callback();
    }
}, parallelCurrentNum);

const queuePush = (params) => new Promise(((resolve, reject) => {
    q.push(params, (err, result) => {
	  err ? reject(err) : resolve(result);
    });
}));

export default class ThumbModel {
    static fromJson(jsonData) {
        const model = new ThumbModel();
        model.id = jsonData.id;
        model.path = jsonData.path;
        model.bbox = jsonData.coordinates;
        model.oriWidth = jsonData.width;
        model.oriHeight = jsonData.height;
        return model;
    }

    static async imageLoader(imageUrl) {
        return new Promise(((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                resolve(image);
            };
            image.onerror = reject;
            image.src = imageUrl;
        }));
    }

    static async drawImage(canvas, thumbModel, box, size) {
        const image = await this.imageLoader(thumbModel.path);
        const x1 = thumbModel.oriWidth * box[0];
        const y1 = thumbModel.oriHeight * box[1];
        const width = thumbModel.oriWidth * (box[2] - box[0]);
        const height = thumbModel.oriHeight * (box[3] - box[1]);
        const context = canvas.getContext('2d');
        // 解决小图不清晰问题
        let cvsWidth = width;
        let cvsHeight = height;
        if (size && size.width && size.height && !size.origin) {
            const devicePixelRatio = window.devicePixelRatio || 1;
            const redio = Math.max(width / (size.width * devicePixelRatio), height / (size.height * devicePixelRatio));
            cvsWidth = toInt(width / redio);
            cvsHeight = toInt(height / redio);
        }
        canvas.width = cvsWidth;
        canvas.height = cvsHeight;

        const targetX = 0;
        const targetY = 0;

        await queuePush({
            gapTime: 20, context, image, x1, y1, width, height, targetX, targetY, cvsWidth, cvsHeight,
        });

        return { canvas, width, height };
    }
}

function toInt(n) {
    return 2 * Math.round(n / 2);
}
