/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import uniqBy from 'lodash/uniqBy';
import findIndex from 'lodash/findIndex';

class EmailAlertService {
    constructor() {
        // 是否显示发送给自己
        this.hasSendToMe = true;
        // 是否发送给自己
        this.sendToMe = true;
        // 自己的邮箱
        this.selfEmail = '';
        this.sendToMeDisplayLabel = '';

        // 当前邮箱输入框中的内容
        this.currentInputtingEmail = '';
        // 当前邮箱输入框的placeholder
        this.emailAutoCompletePlaceholder = '';
        // passport链接，需区分环境
        this.passportUrl = '';

        // 提醒频率
        this.alertFrequency = {
            frequency: 'W',
            date: 1,
        };
        this.alertFrequencyOptions = ['W', 'D', 'M'];
        // 无更新时仍提醒
        this.alertNoUpdate = false;

        // 所有选中的email列表
        this.selectedEmails = [];

        this.shouldSubmit = true;
        this.sendToOther = false;

        // 弹框的loading状态
        this.innerLoading = false; // 内部solt控制的loading
        this.outLoading = false; // dialog控制的loading

        // 标题排列方式
        this.titleLayout = 'column';

        // 提醒频率需要过滤掉的类型
        this.filterFreqType = '';
        // 编辑时提醒频率初始状态
        this.freqInitialType = '';

        // autocomplete父类class
        this.completeScrollSelector = '.pz-email-alert__body.email-alert-dialog';

        // 可选择的邮箱数量(包含team)
        this.emailLimitNum = undefined;
        this._currentSelectedEmailCount = 0;
        // 展示数量label
        this.showLimitLabel = false;
        // 超出限制仍允许选择
        this.enableSelectWhenOverLimit = false;

        this.slotSubData = undefined;
        this.slotDataError = undefined;
    }

    get concatEmail() {
        // 邮箱拼接，input框里的 + 自动提醒的
        const allOtherEmails = this.selectedEmails.map((item) => ({ ...item }));
        if (!isEmpty(trim(this.currentInputtingEmail))) {
            allOtherEmails.push({
                type: 'email',
                label: trim(this.currentInputtingEmail),
                value: trim(this.currentInputtingEmail),
            });
        }
        return uniqBy(allOtherEmails, 'value');
    }

    get isOnlySendToSelf() {
        return (this.concatEmail.length === 1
            && (findIndex(this.concatEmail,
                (item) => item.value === this.selfEmail) !== -1))
            || (!this.concatEmail.length && this.sendToMe);
    }

    get currentSelectedEmailCount() {
        return isEmpty(trim(this.currentInputtingEmail))
            ? this._currentSelectedEmailCount : this._currentSelectedEmailCount + 1;
    }

    setInnerLoadingStatus(status) {
        this.innerLoading = status;
    }

    setOutLoadingStatus(status) {
        this.outLoading = status;
    }

    setSendToMeStatus(status) {
        this.sendToMe = status;
    }

    setSendToOtherStatus(status) {
        this.sendToOther = status;
    }

    setAlertNoUpdateStatus(status) {
        this.alertNoUpdate = status;
    }

    onToastError({ message }) {
        return message;
    }

    onError(error) {
        if (!error || !error._error_msg) {
            // eslint-disable-next-line no-console
            return console.log('[Email Alert Error]: ', error);
        }
        return this.onToastError({ message: error._error_msg });
    }

    getEmailList(/* keyword */) {
        this.onError({
            _error_msg: 'Please implementation method <getEmailList>',
        });
    }

    onClose(hide) {
        hide();
    }

    onValidationFailed(type) {
        this.onError({
            _error_msg: type,
        });
    }

    onValidate() {
        return true;
    }

    onSubmit() {
    }

    onDataChange(err, data) {
        if (err) {
            if (isString(err)) this.onValidationFailed(err);
            this.slotSubData = undefined;
            this.slotDataError = err;
        } else {
            this.slotSubData = data;
            this.slotDataError = undefined;
        }
    }

    onEmailUpperLimit() {
    }

    updateCurrentSelectedEmailCount(count) {
        this._currentSelectedEmailCount = count;
    }
}
export default EmailAlertService;
