const componentXor = xor;

/**
 * XOR utility
 *
 * T T F
 * T F T
 * F T T
 * F F F
 *
 * @param {Boolean} a
 * @param {Boolean} b
 * @return {Boolean}
 */

function xor(a: any, b: any) {
  return a ^ b;
}

/**
 * Global Names
 */

const globals = /\b(Array|Date|Object|Math|JSON)\b/g;

/**
 * Return immediate identifiers parsed from `str`.
 *
 * @param {String} str
 * @param {String|Function} map function or prefix
 * @return {Array}
 * @api public
 */

const componentProps = (str: string, fn: any) => {
  const p = unique(props(str));
  if (fn && typeof fn === 'string') fn = prefixed(fn);
  if (fn) return map(str, p, fn);
  return p;
};

/**
 * Return immediate identifiers in `str`.
 *
 * @param {String} str
 * @return {Array}
 * @api private
 */

function props(str: string) {
  return (
    str
      .replace(/\.\w+|\w+ *\(|"[^"]*"|'[^']*'|\/([^/]+)\//g, '')
      .replace(globals, '')
      .match(/[a-zA-Z_]\w*/g) || []
  );
}

/**
 * Return `str` with `props` mapped with `fn`.
 *
 * @param {String} str
 * @param {Array} props
 * @param {Function} fn
 * @return {String}
 * @api private
 */

function map(str: string, props: any, fn: any) {
  var re = /\.\w+|\w+ *\(|"[^"]*"|'[^']*'|\/([^/]+)\/|[a-zA-Z_]\w*/g;
  return str.replace(re, function (_) {
    if (_[_.length - 1] == '(') return fn(_);
    if (!~props.indexOf(_)) return _;
    return fn(_);
  });
}

/**
 * Return unique array.
 *
 * @param {Array} arr
 * @return {Array}
 * @api private
 */

function unique(arr: any[]) {
  var ret = [];

  for (var i = 0; i < arr.length; i++) {
    if (~ret.indexOf(arr[i])) continue;
    ret.push(arr[i]);
  }

  return ret;
}

/**
 * Map with prefix `str`.
 */

function prefixed(str: any) {
  return function (_: any) {
    return str + _;
  };
}

/**
 * Module Dependencies
 */

/**
 * Export `Iterator`
 */

// var domIterator = Iterator;

/**
 * Initialize `Iterator`
 *
 * @param {Node} node
 * @param {Node} root
 * @return {Iterator} self
 * @api public
 */

export class Iterator {
  public start;
  public peeked;
  public closingTag;

  private _revisit;
  private _selects: any[];
  private _rejects: any[];

  constructor(public node: any, public root: any) {
    this.start = node;
    this.peeked = node;
    this.closingTag = false;
    this._revisit = true;
    this._selects = [];
    this._rejects = [];

    if (node && this.higher(node)) {
      throw new Error('root must be a parent or ancestor to node');
    }
  }

  public reset(node: any) {
    this.node = node || this.start;
    return this;
  }

  public revisit(revisit: any) {
    this._revisit = undefined == revisit ? true : revisit;
    return this;
  }

  public opening() {
    if (this.node.nodeType == 1) this.closingTag = false;
    return this;
  }

  public atOpening() {
    return !this.closingTag;
  }

  public closing() {
    if (this.node.nodeType == 1) this.closingTag = true;
    return this;
  }

  public atClosing() {
    return this.closingTag;
  }

  public next = this.traverse('nextSibling', 'firstChild');

  public prev = this.traverse('previousSibling', 'lastChild');

  public traverse(dir: any, child: any) {
    var next = dir == 'nextSibling';
    return (expr?: any, n?: any, peek?: any) => {
      expr = this.compile(expr);
      n = n && n > 0 ? n : 1;
      var node = this.node;
      var closing = this.closingTag;
      var revisit = this._revisit;

      while (node) {
        if (componentXor(next, closing) && node[child]) {
          // element with children: <em>...</em>
          node = node[child];
          closing = !next;
        } else if (
          node.nodeType == 1 &&
          !node[child] &&
          componentXor(next, closing)
        ) {
          // empty element tag: <em></em>
          closing = next;
          if (!revisit) continue;
        } else if (node[dir]) {
          // element has a neighbor: ...<em></em>...
          node = node[dir];
          closing = !next;
        } else {
          // done with current layer, move up.
          node = node.parentNode;
          closing = next;
          if (!revisit) continue;
        }

        if (!node || this.higher(node)) break;

        if (
          expr(node) &&
          this.selects(node, peek) &&
          this.rejects(node, peek)
        ) {
          if (--n) continue;
          if (!peek) this.node = node;
          this.closingTag = closing;
          return node;
        }
      }

      return null;
    };
  }

  public select(expr: any) {
    expr = this.compile(expr);
    this._selects.push(expr);
    return this;
  }

  public selects(node: any, peek: any) {
    var exprs = this._selects;
    var len = exprs.length;
    if (!len) return true;

    for (var i = 0; i < len; i++) {
      if (exprs[i].call(this, node, peek)) return true;
    }
    return false;
  }

  public reject(expr: any) {
    expr = this.compile(expr);
    this._rejects.push(expr);
    return this;
  }

  public rejects(node: any, peek: any) {
    var exprs = this._rejects;
    var len = exprs.length;
    if (!len) return true;

    for (var i = 0; i < len; i++) {
      if (exprs[i].call(this, node, peek)) return false;
    }
    return true;
  }

  public higher(node: any) {
    var root = this.root;
    if (!root) return false;
    node = node.parentNode;
    while (node && node != root) node = node.parentNode;
    return node != root;
  }

  public compile(expr: any) {
    switch (typeof expr) {
      case 'number':
        return function (node: any) {
          return expr == node.nodeType;
        };
      case 'string':
        return new Function('node', 'return ' + componentProps(expr, 'node.'));
      case 'function':
        return expr;
      default:
        return function () {
          return true;
        };
    }
  }

  public peek(expr: any, n: any) {
    if (arguments.length == 1) (n = expr), (expr = true);
    n = undefined == n ? 1 : n;
    if (!n) return this.node;
    else if (n > 0) return this.next(expr, n, true);
    else return this.prev(expr, Math.abs(n), true);
  }

  public use(fn: any) {
    fn(this);
    return this;
  }
}
