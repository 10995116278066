<template>
    <div class="patsnap-biz-user-setting-container">
        <div
            v-if="getStatus('collapse')"
            class="setting-item clearfix"
            :class="{'setting-item__disabled': getDisabledStatus('collapse')}"
        >
            <div class="setting-item-left">
                <label class="setting-item-label">
                    {{ i18nData.resultDisplay }}
                    <ElPopover
                        placement="bottom-start"
                        :visible-arrow="false"
                        width="388"
                        trigger="hover"
                        popper-class="user-setting__popover user-setting__popover-search"
                    >
                        <div
                            v-for="item in settingConfig.fieldsList"
                            :key="item"
                            class="panel"
                        >
                            <span class="title">{{ $t(`app.userSetting['${item}']`) }}:</span><br>
                            {{ $t(`app.userSetting['${item}Tips']`) }}
                        </div>
                        <PtIcon
                            slot="reference"
                            icon="SolidInfo"
                            style="font-size: 24px; fill: #B3BAC5;"
                            class="hover-icon"
                        />
                    </ElPopover>
                </label>
            </div>
            <div class="setting-item-right">
                <ul class="search-model-list">
                    <li
                        v-for="item in settingConfig.fieldsList"
                        :key="item"
                    >
                        <ElRadio
                            v-model="settingData.collapse.field"
                            :label="item"
                        >
                            {{ i18nData[item] }}
                        </ElRadio>
                        <Representative
                            v-if="settingData.collapse.field === item && item !== 'ALL'"
                            :collapse="getCurrentCollapse(item)"
                            :service-area="serviceArea"
                            :model-type="item"
                            @updateCollapse="updateCollapse"
                        />
                    </li>
                </ul>
            </div>
        </div>
        <div
            v-if="getStatus('family')"
            class="setting-item clearfix"
        >
            <div class="setting-item-left">
                <label class="setting-item-label">{{ $t('app.userSetting.tagFamily') }}</label>
            </div>
            <div class="setting-item-right">
                <ElSelect
                    v-model="settingData.tagFamilyType"
                    popper-class="popper__no-arrow"
                    class="setting-item-selector"
                >
                    <ElOption
                        v-for="(item, idx) in settingConfig.tagFamilyTypeList"
                        :key="idx"
                        :label="$t(`app.userSetting.${item}`)"
                        :value="item"
                    />
                </ElSelect>
                <ElSelect
                    v-model="settingData.tagFamily"
                    popper-class="popper__no-arrow"
                    class="setting-item-selector"
                >
                    <ElOption
                        v-for="(item, idx) in settingConfig.tagFamilyList"
                        :key="idx"
                        :label="$t(`app.userSetting.${item}`)"
                        :value="item"
                    />
                </ElSelect>
                <ElPopover
                    placement="bottom-end"
                    :visible-arrow="false"
                    width="388"
                    trigger="hover"
                    popper-class="user-setting__popover user-setting__popover-country"
                >
                    <div class="single-panel">
                        {{ $t('app.userSetting.searchTagFamilyMsg') }}
                    </div>
                    <PtIcon
                        slot="reference"
                        icon="SolidInfo"
                        style="font-size: 24px; fill: #B3BAC5;"
                        class="hover-icon"
                    />
                </ElPopover>
            </div>
        </div>
        <div
            v-if="getStatus('language')"
            class="setting-item clearfix"
        >
            <div class="setting-item-left">
                <label class="setting-item-label">{{ $t('app.userSetting.languageDisplay') }}</label>
            </div>
            <div class="setting-item-right">
                <ElSelect
                    v-model="settingData.language"
                    popper-class="popper__no-arrow"
                    class="setting-item-selector"
                >
                    <ElOption
                        v-for="(item, idx) in languageList"
                        :key="idx"
                        :label="item.label"
                        :value="item.value"
                    />
                </ElSelect>
                <label
                    v-if="settingData.language !== 'default' && getStatus('originText')"
                    class="language__original"
                >
                    <PtCheckbox v-model="settingData.originText">
                        {{ $t('app.userSetting.showOriginalText') }}
                    </PtCheckbox>
                    <ElPopover
                        placement="right"
                        :visible-arrow="false"
                        width="388"
                        trigger="hover"
                        popper-class="user-setting__popover user-setting__popover-family"
                    >
                        <div class="single-panel">{{ $t('app.userSetting.showOriginalTextTip') }}</div>
                        <PtIcon
                            slot="reference"
                            icon="SolidInfo"
                            style="font-size: 24px; fill: #B3BAC5;"
                            class="hover-icon"
                        />
                    </ElPopover>
                </label>
            </div>
        </div>
        <div
            v-if="getStatus('stem')"
            class="setting-item clearfix"
            :class="{'setting-item__disabled': getDisabledStatus('stem')}"
        >
            <div class="setting-item-left">
                <label class="setting-item-label">{{ $t('app.userSetting.stemming') }}</label>
            </div>
            <div class="setting-item-right">
                <ElRadio
                    v-for="(item, index) in settingConfig.stemList"
                    :key="index"
                    v-model="settingData.stem"
                    :label="item"
                >
                    {{ $t(`app.userSetting.${ item ? 'on': 'off'}`) }}
                </ElRadio>
                <ElPopover
                    placement="bottom-start"
                    :visible-arrow="false"
                    width="388"
                    trigger="hover"
                    popper-class="user-setting__popover user-setting__popover-stemming"
                >
                    <div class="single-panel">
                        <p
                            v-for="i in 4"
                            :key="i"
                        >
                            {{ $t('app.userSetting.stemmingMsg' + i) }}
                        </p>
                    </div>
                    <PtIcon
                        slot="reference"
                        icon="SolidInfo"
                        style="font-size: 24px; fill: #B3BAC5;"
                        class="hover-icon"
                    />
                </ElPopover>
            </div>
        </div>
    </div>
</template>
<script>
import Radio from 'element-ui/lib/radio';
import Popover from 'element-ui/lib/popover';
import Select from 'element-ui/lib/select';
import Option from 'element-ui/lib/option';
import Checkbox from 'element-ui/lib/checkbox';
import PtIcon from '@patsnap-biz/icon';
import get from 'lodash/get';
import map from 'lodash/map';
import includes from 'lodash/includes';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import {
    settingConfig, formatCollapse, searchCollapseTypeMap, apnoTypeMap,
} from './settingConfig';
import Representative from './Representative.vue';

const hooks = {
    settingConfig: (_) => _,
    searchCollapseTypeMap: (_) => _,
    formatCollapse: (_) => _,
    apnoTypeMap: (_) => _,
};

export default {
    components: {
        PtCheckbox: Checkbox,
        PtIcon,
        ElRadio: Radio,
        ElPopover: Popover,
        ElSelect: Select,
        ElOption: Option,
        Representative,
    },
    provide() {
        return {
            normalizeHooks: this.normalizeHooks,
            default: () => hooks,
        };
    },
    props: {
        defaultSettingData: {
            type: Object,
            default: () => ({}),
        },
        showOptions: {
            type: Array,
            default: () => ([]),
        },
        disabledOptions: {
            type: Array,
            default: () => ([]),
        },
        serviceArea: {
            type: String,
            default: 'cn',
        },
        showLanguageList: {
            type: Array,
            default: () => ([]),
        },
        hooks: {
            type: Object,
            default: () => ({
                ...hooks,
            }),
        },
        product: {
            type: String,
            default: 'analytics',
        },
    },
    data() {
        let settingData = cloneDeep(this.defaultSettingData);
        if (isEmpty(settingData)) {
            settingData = {
                collapse: {},
            };
        }
        this.normalizeHooks = { ...hooks, ...this.hooks };
        const collapseMap = this.updateCollapseMap(get(settingData, 'collapse', {}));
        const config = this.normalizeHooks.settingConfig(settingConfig);
        return {
            settingConfig: config,
            settingData,
            languageList: [],
            collapseMap,
        };
    },
    computed: {
        i18nData() {
            let defaultI18n = {
                resultDisplay: this.$t('app.userSetting.resultDisplay'),
                ALL: this.$t('app.userSetting.ALL'),
                APNO_FACET: this.$t('app.userSetting.APNO_FACET'),
                FAMILY_ID: this.$t('app.userSetting.FAMILY_ID'),
                INPADOC_FAMILY_ID: this.$t('app.userSetting.INPADOC_FAMILY_ID'),
                EXTEND_FAMILY: this.$t('app.userSetting.EXTEND_FAMILY'),
            };
            if (this.product === 'workspace') {
                defaultI18n = {
                    resultDisplay: this.$t('GroupSetting.groupSetting'),
                    ALL: this.$t('GroupSetting.groupALLName'),
                    APNO_FACET: this.$t('GroupSetting.groupAPNO_FACETName'),
                    FAMILY_ID: this.$t('GroupSetting.groupFAMILY_IDName'),
                    INPADOC_FAMILY_ID: this.$t('GroupSetting.groupINPADOC_FAMILY_IDName'),
                    EXTEND_FAMILY: this.$t('GroupSetting.groupEXTEND_FAMILYName'),
                };
            }
            return defaultI18n;
        },
    },
    watch: {
        settingData: {
            immediate: true,
            deep: true,
            handler() {
                let { settingData } = this;
                if (this.showOptions && this.showOptions.length) {
                    const settingDataTemp = {};
                    let showOptions = [...this.showOptions];
                    if (includes(showOptions, 'family')) {
                        showOptions = showOptions.concat(['tagFamilyType', 'tagFamily']);
                    }
                    forEach(showOptions, (item) => {
                        if (!isUndefined(settingData[item])) {
                            settingDataTemp[item] = settingData[item];
                        }
                    });
                    settingData = settingDataTemp;
                }
                const formatSettingData = this.normalizeHooks.formatCollapse(formatCollapse)(settingData);
                this.$emit('updateSettingData', formatSettingData);
                this.$emit('setting-change', formatSettingData);
            },
        },
    },
    created() {
        this.initLanguageList();
    },
    methods: {
        initLanguageList() {
            let languageList;
            const countryTag = this.serviceArea === 'cn' ? 'cn' : 'us';
            if (this.showLanguageList[0]) {
                languageList = this.showLanguageList;
            } else {
                languageList = this.settingConfig.languageList;
                if (countryTag !== 'cn' && !includes(languageList, 'translation_jp')) {
                    languageList.push('translation_jp');
                }
            }
            this.languageList = map(languageList, (item) => {
                let label = item;
                if (item === 'translation') {
                    label = `${item}_${countryTag}`;
                }
                label = this.$t(`app.userSetting.${label}`);
                return {
                    value: item,
                    label,
                };
            });
        },
        updateCollapse(collapse) {
            this.collapseMap = this.updateCollapseMap(collapse);
            this.settingData.collapse = collapse;
        },
        getStatus(type) {
            if (this.showOptions && this.showOptions.length) {
                if (includes(this.showOptions, type)) {
                    return true;
                }
                return false;
            }
            return true;
        },
        getDisabledStatus(type) {
            if (this.disabledOptions && this.disabledOptions.length) {
                if (includes(this.disabledOptions, type)) {
                    return true;
                }
                return false;
            }
            return false;
        },
        updateCollapseMap(collapse) {
            const collapseMap = this.collapseMap || {};
            const fieldType = this.getFieldType(collapse.field);
            if (fieldType) {
                collapseMap[fieldType] = cloneDeep(collapse);
            }
            return collapseMap;
        },
        getFieldType(field) {
            let fieldType = '';
            if (includes(this.normalizeHooks.apnoTypeMap(apnoTypeMap), field)) {
                fieldType = 'APNO_FACT';
            } else if (includes(this.normalizeHooks.searchCollapseTypeMap(searchCollapseTypeMap), field)) {
                fieldType = 'FAMILY';
            }
            return fieldType;
        },
        getCurrentCollapse(field) {
            const fieldType = this.getFieldType(field);
            return this.collapseMap[fieldType] || this.settingData.collapse;
        },
    },
};
</script>
