var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "setting-selected-country-item",
      on: {
        mousedown: function ($event) {
          $event.stopPropagation()
        },
        mouseup: function ($event) {
          $event.stopPropagation()
        },
        click: _vm.updateOpenStatus,
      },
    },
    [
      _vm.countryData
        ? _c("span", [_vm._v(_vm._s(_vm.countryData))])
        : _c("span", [_vm._v(_vm._s(_vm.$t("app.userSetting._")))]),
      _c("span", {
        staticClass: "arrow-icon",
        class: [_vm.isOpen ? "ai-open" : "ai-close"],
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }