var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "pt-email-auto-complete": true,
        error: _vm.isSomeoneEmailInvalid,
      },
      style: { width: _vm.autocompleteWidth },
    },
    [
      _c(
        "div",
        { ref: "container", staticClass: "pt-email-auto-complete__container" },
        [
          _c(
            "div",
            {
              ref: "wrapper",
              class: {
                "pt-email-auto-complete__users-input": true,
                "pt-email-auto-complete__users-input--active": _vm.isFocus,
              },
              on: { click: _vm.onFocus },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFocus && _vm.isPlaceholderDisplay,
                      expression: "!isFocus && isPlaceholderDisplay",
                    },
                  ],
                  staticClass:
                    "pt-email-auto-complete__users-input__placeholder",
                },
                [_vm._v(" " + _vm._s(_vm.placeholderContent) + " ")]
              ),
              _c(
                "div",
                { staticClass: "pt-email-auto-complete__users-input__main" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "pt-email-auto-complete__users-input__container",
                    },
                    [
                      _vm._l(_vm.currentSelected, function (user, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: {
                              "pt-email-auto-complete__users-input__item": true,
                              "pt-email-auto-complete__users-input__item--error":
                                !user.isValid ||
                                _vm.errorUserList.includes(user.userId),
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            user.isValid &&
                            !_vm.errorUserList.includes(user.userId)
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      "pt-email-auto-complete__users-input__item-avator": true,
                                      "pt-email-auto-complete__users-input__item-avator--group":
                                        user.type === "group",
                                    },
                                    style: {
                                      "background-color":
                                        _vm.getSuggestionColor(
                                          user.label.slice(0, 1)
                                        ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          user.label.slice(0, 1).toUpperCase()
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "pt-email-auto-complete__users-input__item-text",
                              },
                              [_vm._v(_vm._s(user.label))]
                            ),
                            _c("PtIcon", {
                              staticClass:
                                "pt-email-auto-complete__users-input__item-close",
                              attrs: { icon: "SolidCloseMedium" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onRemoveSelected(user)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.currentInputing,
                            expression: "currentInputing",
                          },
                        ],
                        ref: "input",
                        staticClass:
                          "pt-email-auto-complete__users-input__invisible-input",
                        attrs: { type: "text", spellcheck: "false" },
                        domProps: { value: _vm.currentInputing },
                        on: {
                          keydown: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "delete",
                                  [8, 46],
                                  $event.key,
                                  ["Backspace", "Delete", "Del"]
                                )
                              ) {
                                return null
                              }
                              return _vm.onInputDelete.apply(null, arguments)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.onInputPush.apply(null, arguments)
                            },
                          ],
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              $event.keyCode !== 188
                            ) {
                              return null
                            }
                            return _vm.onInputPush.apply(null, arguments)
                          },
                          blur: _vm.onInputBlur,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.currentInputing = $event.target.value
                          },
                        },
                      }),
                    ],
                    2
                  ),
                  _vm.showLimitLabel && _vm.emailLimitNum
                    ? _c(
                        "span",
                        {
                          class: [
                            "pt-email-auto-complete__users-input__count",
                            {
                              "pt-email-auto-complete__users-input__count--over-limit":
                                _vm.isSelectedEmailCountOverLimit,
                            },
                          ],
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.currentSelectedEmailCount) +
                              " / " +
                              _vm._s(_vm.emailLimitNum)
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSuggestionDisplay,
                  expression: "isSuggestionDisplay",
                },
              ],
              ref: "autoCompleteBody",
              staticClass: "pt-email-auto-complete__suggest",
            },
            [
              _c(
                "div",
                { staticClass: "pt-email-auto-complete__suggest-container" },
                [
                  _vm.isShowAddEmail
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "pt-email-auto-complete__suggest-content",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "pt-email-auto-complete__suggest-content-add-email",
                              on: {
                                click: function ($event) {
                                  return _vm.onSelect(
                                    {
                                      label: _vm.inputing,
                                      value: _vm.inputing,
                                      type: "email",
                                    },
                                    false
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "" +
                                      _vm.$t(
                                        "emailAlertBiz.common.emailAutoComplete.addEmail"
                                      ) +
                                      _vm.inputing
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pt-email-auto-complete__suggest-content pt-email-auto-complete__suggest-team",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pt-email-auto-complete__suggest-content-title",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "emailAlertBiz.common.emailAutoComplete.team"
                                )
                              )
                            ),
                          ]),
                          _vm.passportUrl
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "pt-email-auto-complete__suggest-content-title__link",
                                  on: { click: _vm.linkToTeamManage },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "emailAlertBiz.common.emailAutoComplete.teamManage"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.isLoadingList
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pt-email-auto-complete__suggest-content-loading",
                            },
                            [
                              _c("PtIcon", {
                                attrs: { icon: "SolidLoadingBars" },
                              }),
                            ],
                            1
                          )
                        : [
                            _vm.filteredGroupList.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-email-auto-complete__suggest-content-body",
                                  },
                                  _vm._l(
                                    _vm.filteredGroupList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "pt-email-auto-complete__suggest-content-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSelect(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "pt-email-auto-complete__suggest-content-item-avator pt-email-auto-complete__suggest-content-item-avator--group",
                                              style: {
                                                "background-color":
                                                  _vm.getSuggestionColor(
                                                    item.firstLatter
                                                  ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.firstLatter) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pt-email-auto-complete__suggest-content-item-text",
                                            },
                                            [
                                              _c("p", {
                                                staticClass:
                                                  "pt-email-auto-complete__suggest-content-item-text__label",
                                                attrs: {
                                                  title: item.displayLabel,
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.displayLabel
                                                  ),
                                                },
                                              }),
                                              item.external
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "pt-email-auto-complete__suggest-content-item-text__tip",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "emailAlertBiz.common.emailAutoComplete.includeExternal"
                                                            )
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "pt-email-auto-complete__suggest-content-item-sub",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "emailAlertBiz.common.emailAutoComplete.teamCount",
                                                      { count: item.total }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-email-auto-complete__suggest-content-nodata",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "emailAlertBiz.common.emailAutoComplete.noMatchedTeam"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pt-email-auto-complete__suggest-content pt-email-auto-complete__suggest-user",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "pt-email-auto-complete__suggest-content-title",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "emailAlertBiz.common.emailAutoComplete.single"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.isLoadingList
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "pt-email-auto-complete__suggest-content-loading",
                            },
                            [
                              _c("PtIcon", {
                                attrs: { icon: "SolidLoadingBars" },
                              }),
                            ],
                            1
                          )
                        : [
                            _vm.filteredEmailList.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-email-auto-complete__suggest-content-user-body",
                                  },
                                  _vm._l(
                                    _vm.filteredEmailList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          class: {
                                            "pt-email-auto-complete__suggest-content-item": true,
                                            "pt-email-auto-complete__suggest-content-item--disabled":
                                              item.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSelect(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "pt-email-auto-complete__suggest-content-item-avator",
                                              style: {
                                                "background-color":
                                                  _vm.getSuggestionColor(
                                                    item.firstLatter
                                                  ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.firstLatter) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pt-email-auto-complete__suggest-content-item-text",
                                            },
                                            [
                                              item.nickname
                                                ? _c("p", {
                                                    staticClass: "nick-name",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.nicknameDisplay
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "user-info" },
                                                [
                                                  item.email
                                                    ? _c(
                                                        "ElPopover",
                                                        {
                                                          attrs: {
                                                            placement: "top",
                                                            trigger: "hover",
                                                            "visible-arrow": false,
                                                            "open-delay": 200,
                                                            "close-delay": 0,
                                                            "popper-class":
                                                              "pt-email-auto-complete__popover",
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "user-info-email",
                                                            attrs: {
                                                              slot: "reference",
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.emailDisplay
                                                              ),
                                                            },
                                                            slot: "reference",
                                                          }),
                                                          _c("p", {
                                                            staticStyle: {
                                                              "max-width":
                                                                "500px",
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.emailDisplay
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.mobile_number
                                                    ? _c("span", {
                                                        staticClass:
                                                          "user-info-mobile-number",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.mobileNumberDisplay
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-email-auto-complete__suggest-content-nodata",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "emailAlertBiz.common.emailAutoComplete.noMatchedSingle"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ],
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }