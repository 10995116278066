import { IErrorMap } from '../interface/error';
import { ILang } from '../interface/lang';
import { formatStr } from './format';
import { SelectionUtil } from './selection';
import { TokenUtil } from './token';

export function generateSpan(params: {
  dom: HTMLElement;
  str?: string;
  lang: ILang;
  errorMap?: Partial<IErrorMap>;
  syncCursorPos?: boolean;
}) {
  TokenUtil.getInstance().clearErrorMsgBox();
  const { dom, str, lang, errorMap, syncCursorPos } = params;
  let range;
  if (syncCursorPos) {
    range = SelectionUtil.getInstance().getRange(dom);
  }
  const fStr = formatStr((str ?? dom.innerText) || '');
  const syntaxTree = TokenUtil.getInstance().getSyntaxTree(fStr);
  const tokens = syntaxTree.getTokensWithCorrections();
  const spanContainer = TokenUtil.getInstance().generateSpanContainer({
    dom,
    tokens,
    lang,
    errorMap,
  });
  dom.innerHTML = '';
  if (spanContainer.hasChildNodes()) {
    dom.appendChild(spanContainer);
  }

  if (syncCursorPos) {
    SelectionUtil.getInstance().setRange(dom, range);
  }
  return syntaxTree;
}
