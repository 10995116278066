var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showHelpService
    ? _c(
        "div",
        { staticClass: "help-service" },
        [
          _vm._t("entryIcon", function () {
            return [
              _c("PtIcon", {
                style: {
                  fill: "#1976D2",
                  "font-size": "24px",
                  "flex-shrink": 0,
                },
                attrs: { icon: "SolidService" },
              }),
            ]
          }),
          _c("div", { staticClass: "help-service__text" }, [
            _vm._v(" " + _vm._s(_vm.entryText) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "help-service__close",
              on: {
                click: function ($event) {
                  _vm.showHelpService = false
                },
              },
            },
            [
              _c("PtIcon", {
                style: { "font-size": "24px" },
                attrs: { icon: "SolidCircleClose" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "help-service__menuContainer" }, [
            _c(
              "div",
              { staticClass: "help-service__menu" },
              _vm._l(_vm.menu, function (item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "help-service__menu-item" },
                    [
                      _c("PtIcon", {
                        class: "help-service__menu-item__icon--" + item.icon,
                        style: { "font-size": "24px", "flex-shrink": 0 },
                        attrs: { icon: item.icon },
                        on: {
                          click: function () {
                            return item.clickHandler && item.clickHandler()
                          },
                        },
                      }),
                      _c(
                        "div",
                        {
                          on: {
                            click: function () {
                              return item.clickHandler && item.clickHandler()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.title) + " ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "help-service__menu-qrCodeContainer" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.slotName,
                                  expression: "item.slotName",
                                },
                              ],
                              staticClass: "help-service__menu-qrCode",
                            },
                            [_vm._t(item.slotName, null, { item: item })],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  index !== _vm.menu.length - 1
                    ? _c("div", { staticClass: "help-service__menu-divider" })
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }