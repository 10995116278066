var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "patsnap-biz-master_nav master_nav",
      attrs: {
        role: "navigation",
        "aria-expanded": _vm.service.isWide ? "true" : "false",
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "master_nav__trigger",
          attrs: { flex: "-" },
          on: { click: _vm.onClickTrigger },
        },
        [
          _vm.service.isWide
            ? _c(
                "div",
                {
                  staticClass: "master_nav__trigger__icon-wrap",
                  attrs: { "flex-item": ")0(center" },
                },
                [_c("ToggleIcon", { attrs: { value: _vm.service.isWide } })],
                1
              )
            : _c(
                "ElTooltip",
                {
                  attrs: {
                    "popper-class": "master_nav__tip",
                    effect: "dark",
                    placement: "right",
                    "visible-arrow": false,
                    disabled: _vm.service.isWide,
                    content: _vm.service.$t("expandSidebar"),
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "master_nav__trigger__icon-wrap" },
                    [
                      _c("ToggleIcon", {
                        attrs: { value: _vm.service.isWide },
                      }),
                    ],
                    1
                  ),
                ]
              ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.service.isWide,
                  expression: "service.isWide",
                },
              ],
              staticClass: "master_nav__trigger__text",
              attrs: { "flex-item": "()center" },
            },
            [_vm._v(" " + _vm._s(_vm.service.$t("hideSidebar")) + " ")]
          ),
        ],
        1
      ),
      _c("DivLine", {
        staticClass: "master_nav__top-divline",
        attrs: { label: "" },
      }),
      _c(
        "nav",
        [
          _vm._l(_vm.service.partitions, function (partition, partIndex) {
            return [
              typeof partition === "string" && _vm.getSlotName(partition)
                ? _vm._t(_vm.getSlotName(partition), null, {
                    service: _vm.service,
                  })
                : typeof partition === "string" && !_vm.getSlotName(partition)
                ? _c("DivLine", {
                    key: "divLine_" + partIndex,
                    attrs: { label: _vm.service.$t(partition) },
                  })
                : _c("NavItem", {
                    key: partition.key,
                    attrs: { info: partition, service: _vm.service },
                    on: {
                      onClick: function (o) {
                        return _vm.service.onClickedLinkHook(o.key, o)
                      },
                      onClickExpand: function (key) {
                        return _vm.service.toggleExpandLv2(key)
                      },
                    },
                  }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }