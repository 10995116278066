import { Tokenizing } from '../Tokenizing';
import { Token } from '../Values/Token';
import { TokenSequence } from '../Values/TokenSequence';
import { TokenExtractor } from './TokenExtractor';

export class Tokenizer implements Tokenizing {
  constructor(private _TokenExtractor: TokenExtractor) {}

  public tokenize(str: string) {
    const length = str.length;
    let position = 0;
    const tokens: Token[] = [];
    while (position < length) {
      const token = this._TokenExtractor.extract(str, position);
      position += token.lexeme.length;
      tokens.push(token);
    }
    return new TokenSequence(tokens, str);
  }
}
