var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BzDialog",
    _vm._b(
      {
        attrs: {
          visible: "",
          width: "960px",
          "custom-class":
            "pz-save-to-workspace-dialog pz-save-to-workspace-dialog__" +
            _vm.service.type,
          "before-close": function () {
            return _vm.service.onClose()
          },
          "close-on-click-modal": false,
          "close-on-press-escape": false,
          title: _vm.$t("BaseSaveToWorkspace.Main.title"),
        },
      },
      "BzDialog",
      _vm.$attrs,
      false
    ),
    [
      _c("RangeSelector"),
      _c("FolderSelector"),
      _c("template", { staticClass: "dialog-footer", slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "pt-ui-btn",
            attrs: { size: "small" },
            on: {
              click: function ($event) {
                return _vm.service.onClose()
              },
            },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("BaseSaveToWorkspace.Main.cancel")) + " "
            ),
          ]
        ),
        _c(
          "button",
          {
            staticClass: "pt-ui-btn",
            attrs: {
              size: "small",
              type: "primary",
              "data-dialog-type": _vm.service.type,
              disabled: _vm.service.isSubmitButtonDisabled(),
            },
            on: {
              click: function ($event) {
                return _vm.service.onSubmit()
              },
            },
          },
          [
            _vm.service.isSubmitting
              ? _c("PtIcon", {
                  staticClass: "s2w-submit-loading-icon",
                  attrs: { icon: "SolidLoadingBars" },
                })
              : [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("BaseSaveToWorkspace.Main.submit")) +
                      " "
                  ),
                ],
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }