var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patsnap-biz-email_alert__common" },
    [
      _c(
        "Layout",
        {
          staticClass: "pz-biz-email_alert__item",
          attrs: { mode: _vm.service.titleLayout },
          scopedSlots: _vm._u(
            [
              {
                key: "content",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "pz-biz-email_alert__send-to" },
                      [
                        _vm.service.hasSendToMe && _vm.service.selfEmail
                          ? _c(
                              "div",
                              { staticClass: "pz-biz-email_alert__checkbox" },
                              [
                                _c(
                                  "Checkbox",
                                  {
                                    attrs: { checked: _vm.service.sendToMe },
                                    on: { change: _vm.changeSendToMeStatus },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.service.sendToMeDisplayLabel ||
                                            _vm.service.selfEmail
                                        ) +
                                        " (" +
                                        _vm._s(
                                          _vm.$t(
                                            "emailAlertBiz.common.commonModule.self"
                                          )
                                        ) +
                                        ") "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._t("wechat"),
                      ],
                      2
                    ),
                    _c("EmailAutoComplete", {
                      class: {
                        "pz-biz-email_alert__other-email": true,
                        error: !_vm.isAllEmailValid,
                      },
                      attrs: {
                        loading:
                          _vm.service.innerLoading || _vm.service.outLoading,
                        inputing: _vm.service.currentInputtingEmail,
                        selected: _vm.service.selectedEmails,
                        "passport-url": _vm.service.passportUrl,
                        "get-email-list": _vm.getEmailList,
                        "scroll-selector": _vm.service.completeScrollSelector,
                        "placeholder-content":
                          _vm.service.emailAutoCompletePlaceholder ||
                          _vm.$t(
                            "emailAlertBiz.common.emailAutoComplete.sendToTip"
                          ),
                        "email-limit-num": _vm.service.emailLimitNum,
                        "show-limit-label": _vm.service.showLimitLabel,
                        "enable-select-when-over-limit":
                          _vm.service.enableSelectWhenOverLimit,
                      },
                      on: {
                        "update:inputing": function ($event) {
                          return _vm.$set(
                            _vm.service,
                            "currentInputtingEmail",
                            $event
                          )
                        },
                        "update:selected": function ($event) {
                          return _vm.$set(_vm.service, "selectedEmails", $event)
                        },
                        onEmailUpperLimit: _vm.onEmailUpperLimit,
                        updateCurrentSelectedEmailCount:
                          _vm.updateCurrentSelectedEmailCount,
                      },
                    }),
                    !_vm.isAllEmailValid
                      ? _c(
                          "div",
                          { staticClass: "pz-biz-email_alert__email-errmsg" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "emailAlertBiz.common.commonModule.errorEmail"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showEmailToOtherTips
                      ? _c(
                          "div",
                          {
                            class: {
                              "pz-biz-email_alert__other-tips": true,
                              "pz-biz-email_alert__other-tips--error":
                                _vm.isSentToOtherTipUnchecked,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pt-email-alert__container-checkbox",
                              },
                              [
                                _c(
                                  "Checkbox",
                                  {
                                    attrs: { checked: _vm.service.sendToOther },
                                    on: { change: _vm.changeSendToOtherStauts },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "emailAlertBiz.common.commonModule.emailToOtherTips"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._t("unsubscribed"),
                    _vm._t("extra"),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _c("span", [
            _vm._v(_vm._s(_vm.$t("emailAlertBiz.common.commonModule.sendTo"))),
          ]),
          _vm._t("tips"),
        ],
        2
      ),
      _c(
        "Layout",
        {
          staticClass: "pz-biz-email_alert__item",
          attrs: { mode: _vm.service.titleLayout },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("AlertFrequency", {
                    attrs: {
                      "filter-type": _vm.service.filterFreqType,
                      "freq-initial-type": _vm.service.freqInitialType,
                      options: _vm.service.alertFrequencyOptions,
                    },
                    model: {
                      value: _vm.service.alertFrequency,
                      callback: function ($$v) {
                        _vm.$set(_vm.service, "alertFrequency", $$v)
                      },
                      expression: "service.alertFrequency",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pz-biz-email_alert__checkbox pz-biz-email_alert__no-update",
                    },
                    [
                      _c(
                        "Checkbox",
                        {
                          attrs: { checked: _vm.service.alertNoUpdate },
                          on: { change: _vm.changeAlertNoUpdateStauts },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "emailAlertBiz.common.commonModule.alertNoUpdate"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm.$t("emailAlertBiz.common.commonModule.frequency"))
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }