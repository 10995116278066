import { ERROR_MAP } from '../constants';
import { ERROR_TOKEN_TYPE, IErrorMap } from '../interface/error';
import { ILang } from '../interface/lang';
import { Parser } from '../Languages/Parser';
import { Full } from '../Languages/TokenExtractor/Full';
import { Tokenizer } from '../Languages/Tokenizer';
import { Token } from '../Values/Token';
import { TokenSequence } from '../Values/TokenSequence';

/**
 * token的工具类
 */
export class TokenUtil {
  private static _instance: TokenUtil;

  public static getInstance(): TokenUtil {
    if (!this._instance) {
      this._instance = new TokenUtil();
    }
    return this._instance;
  }

  /**
   * 生成的spans包一层父亲
   */
  private _spanContainer: HTMLSpanElement = document.createElement('span');

  private _lang: ILang = 'cn';

  private _dom: HTMLElement | null = null;

  private _errorMap: Record<ILang, IErrorMap> = ERROR_MAP;

  private _userErrorMap: Partial<IErrorMap> = {};

  /**
   * 提供默认的token的错误语义化文案
   */
  public getDefaultTokenErrorMsg(type: ERROR_TOKEN_TYPE | undefined) {
    const commonError = this._lang === 'cn' ? '暂不支持' : 'unsupported';
    if (type === undefined) {
      return commonError;
    }
    const errorMap = {
      ...this._errorMap[this._lang],
      ...this._userErrorMap,
    };
    return errorMap[type] || commonError;
  }

  public getTokenSequence(tokenizer: Tokenizer, queryStr: string) {
    return tokenizer.tokenize(queryStr);
  }

  public getSyntaxTreeByTokenSequence(
    parser: Parser,
    tokenSequence: TokenSequence,
  ) {
    return parser.parse(tokenSequence);
  }

  /**
   * str转成语法树
   * @param queryStr
   * @returns
   */
  public getSyntaxTree(queryStr: string) {
    const tokenizer = new Tokenizer(new Full());
    const parser = new Parser();
    const tokenSequence = this.getTokenSequence(tokenizer, queryStr);
    return this.getSyntaxTreeByTokenSequence(parser, tokenSequence);
  }

  /**
   * 清空错误弹框
   */
  public clearErrorMsgBox() {
    const errorMsgContainer = document.getElementsByClassName(
      'error-msg-container',
    );
    if (errorMsgContainer) {
      Array.from(errorMsgContainer).forEach((el) => {
        this._spanContainer.removeChild(el);
      });
    }
  }

  /**
   * token生成对应的span节点
   * @param token
   * @returns
   */
  public generateSpan(token: Token) {
    const span = document.createElement('span');

    const type = `${token.getTokenType()}`;
    const className = token.errType ? `${type} error` : type;
    const errorType = token.errType ? `${token.errType}` : '';

    span.setAttribute('type', type);
    if (className) {
      span.setAttribute('class', className);
    }
    if (errorType) {
      span.setAttribute('error-type', errorType);
    }

    span.innerHTML = token.lexeme;

    if (errorType) {
      span.addEventListener('mouseenter', this._onMouseenter);
      span.addEventListener('mouseleave', this._onMouseleave);
    }

    return span;
  }

  /**
   * 根据tokens生成一个span的container
   * @param tokens
   * @param lang 语言
   * @param errorMap 外部自定义error内容
   * @returns
   */
  public generateSpanContainer(params: {
    dom: HTMLElement;
    tokens: Token[];
    lang?: ILang;
    errorMap?: Partial<IErrorMap>;
  }) {
    const { tokens, lang = 'cn', errorMap = {}, dom } = params;
    this._lang = lang;
    this._dom = dom;
    this._userErrorMap = errorMap;
    this._spanContainer.innerHTML = '';
    const tokenSpans = tokens.map((token) => this.generateSpan(token));
    tokenSpans.forEach((span) => {
      this._spanContainer.appendChild(span);
    });
    return this._spanContainer;
  }

  private _onMouseenter = (e: MouseEvent) => {
    const target = e.target as HTMLSpanElement;
    const errorType = target.getAttribute(
      'error-type',
    ) as unknown as ERROR_TOKEN_TYPE;
    const errorMsg = this.getDefaultTokenErrorMsg(errorType);
    const containerScroll = this._dom?.scrollTop || 0;
    this._createErrorMsgBox({
      x: target.offsetLeft,
      y: target.offsetTop - containerScroll + 25,
      msg: errorMsg,
    });
  };

  private _onMouseleave = (e: MouseEvent) => {
    this.clearErrorMsgBox();
  };

  /**
   * 创建错误弹框
   */
  private _createErrorMsgBox(params: { x: number; y: number; msg: string }) {
    const msgContainer = document.createElement('div');
    const { x, y, msg } = params;
    msgContainer.innerHTML = `${msg}`;
    msgContainer.style.left = `${x}px`;
    msgContainer.style.top = `${y}px`;
    msgContainer.className = 'error-msg-container';
    this._spanContainer.appendChild(msgContainer);
  }
}
