var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pz-save-to-workspace-folder-tree" },
    _vm._l(_vm.folders, function (folder) {
      return _c("FolderTreeItem", {
        key: folder.folder_id,
        attrs: { folder: folder, level: 0 },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }