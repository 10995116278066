export function isEmpty(val: any[]) {
  return !val.length;
}

export function isNumber(val: any): val is number {
  return typeof val === 'number';
}

export function isNil(val: any) {
  return val === null || val === undefined;
}
