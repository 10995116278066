import {
  TOKEN_BAILOUT,
  TOKEN_GROUP_BEGIN,
  TOKEN_GROUP_END,
  TOKEN_LOGICAL_AND,
  TOKEN_LOGICAL_NOT,
  TOKEN_LOGICAL_NOT_2,
  TOKEN_LOGICAL_OR,
  TOKEN_MANDATORY,
  TOKEN_PROHIBITED,
  TOKEN_TERM,
  TOKEN_WHITESPACE,
} from '../constants';
import { ERROR_TOKEN_TYPE } from '../interface/error';

export class Token {
  /**
   *
   * @param type  Categorizes the token for the purpose of parsing. Defined by the language implementation.
   * @param lexeme Token lexeme is a part of the input string recognized as token.
   * @param position Position of the lexeme in the input string.
   * @param errType error reason
   */
  constructor(
    public type: number,
    public lexeme: string,
    public position: number,
    public errType?: ERROR_TOKEN_TYPE,
  ) {}

  public getTokenType() {
    switch (this.type) {
      case TOKEN_WHITESPACE:
        return 'space';
      case TOKEN_LOGICAL_AND:
      case TOKEN_LOGICAL_OR:
      case TOKEN_LOGICAL_NOT:
      case TOKEN_LOGICAL_NOT_2:
      case TOKEN_MANDATORY:
      case TOKEN_PROHIBITED:
        return 'logic';
      case TOKEN_GROUP_BEGIN:
      case TOKEN_GROUP_END:
        return 'operator';
      case TOKEN_TERM:
        return 'word';
      case TOKEN_BAILOUT:
        return 'word';
      default:
        return this.type;
    }
  }
}
