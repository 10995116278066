/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
import {
  TOKEN_GROUP_BEGIN,
  TOKEN_GROUP_END,
  TOKEN_LOGICAL_AND,
  TOKEN_LOGICAL_NOT,
  TOKEN_LOGICAL_NOT_2,
  TOKEN_LOGICAL_OR,
  TOKEN_MANDATORY,
  TOKEN_PROHIBITED,
  TOKEN_TERM,
  TOKEN_WHITESPACE,
} from '../../constants';
import { isNil } from '../../utils/common';
import { TokenExtractor } from '../TokenExtractor';
import { Phrase, Tag, User, Word } from '../Values/Token';

export class Full extends TokenExtractor {
  /**
   * 正则的/u修饰符: https://zh.javascript.info/regexp-unicode
   * 正则的/y修饰符: https://zh.javascript.info/regexp-sticky
   */
  private expressionTypeMap = [
    {
      express: /(?<lexeme>[\s]+)/uy, // 匹配空白字符
      type: TOKEN_WHITESPACE,
    },
    {
      express: /(?<lexeme>\+)/uy, // 匹配+
      type: TOKEN_MANDATORY,
    },
    {
      express: /(?<lexeme>-)/uy, // 匹配-
      type: TOKEN_PROHIBITED,
    },
    {
      express: /(?<lexeme>!)/uy, // 匹配!
      type: TOKEN_LOGICAL_NOT_2,
    },
    {
      express: /(?<lexeme>\))/uy, // 匹配)
      type: TOKEN_GROUP_END,
    },
    {
      express: /(?<lexeme>NOT)(?:[\s"()+\-!]|$)/uy, // 匹配NOT
      type: TOKEN_LOGICAL_NOT,
    },
    {
      express: /(?<lexeme>(?:AND|&&))(?:[\s"()+\-!]|$)/uy, // 匹配AND
      type: TOKEN_LOGICAL_AND,
    },
    {
      express: /(?<lexeme>(?:OR|\|\|))(?:[\s"()+\-!]|$)/uy, // 匹配OR
      type: TOKEN_LOGICAL_OR,
    },
    {
      express:
        /(?<lexeme>(?:(?<domain>[a-zA-Z_][a-zA-Z0-9_\-.]*):)?(?<delimiter>\())/uy, // 匹配(
      type: TOKEN_GROUP_BEGIN,
    },
    {
      express:
        /(?<lexeme>(?:(?<marker>(?<!\\\\)#)(?<tag>[a-zA-Z0-9_][a-zA-Z0-9_\-.]*)))(?:[\s"()+!]|$)/uy, // 匹配#tag
      type: TOKEN_TERM,
    },
    {
      express:
        /(?<lexeme>(?:(?<marker>(?<!\\\\)@)(?<user>[a-zA-Z0-9_][a-zA-Z0-9_\-.]*)))(?:[\s"()+!]|$)/uy, // 匹配@user
      type: TOKEN_TERM,
    },
    {
      express:
        /(?<lexeme>(?:(?<domain>[a-zA-Z_][a-zA-Z0-9_\-.]*):)?(?<quote>(?<!\\)["])(?<phrase>.*?)(?:(?<!\\)(?<quoteEnd>(?<!\\)["])))/uy, // 匹配"phrase"
      type: TOKEN_TERM,
    },
    {
      express:
        /(?<lexeme>(?:(?<domain>[a-zA-Z_][a-zA-Z0-9_\-.]*):)?(?<word>(?:\\\\\\\\|\\\\ |\\\\\(|\\\\\)|\\"|[^"()\s])+?))(?:(?<!\\)["]|\(|\)|$|\s)/uy, // 匹配word
      type: TOKEN_TERM,
    },
  ];

  protected getExpressionTypeMap() {
    return this.expressionTypeMap;
  }

  protected createTermToken(position: number, data: any) {
    const lexeme = data['lexeme'];
    switch (true) {
      case !isNil(data['word']):
        return new Word(
          lexeme,
          position,
          data['domain'],
          data['word'].replace(/(?:\\(\\|(["+\-!():#@ ])))/, '$1'),
        );
      case !isNil(data['phrase']):
        const quote = data['quote'];
        const reg = new RegExp(`(?:\\\\([' . ${quote} . ']))`);
        return new Phrase(
          lexeme,
          position,
          data['domain'],
          quote,
          data['phrase'].replace(reg, '$1'),
        );
      case !isNil(data['tag']):
        return new Tag(lexeme, position, data['marker'], data['tag']);
      case !isNil(data['user']):
        return new User(lexeme, position, data['marker'], data['user']);
      default:
        break;
    }

    throw new Error('Could not extract term token from the given data');
  }
}
