import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';

const settingConfig = {
    fieldsList: ['ALL', 'APNO_FACET', 'FAMILY_ID', 'INPADOC_FAMILY_ID', 'EXTEND_FAMILY'],
    searchCollapseType: ['AUTHORITY', 'APD', 'PBD', 'PATENT_TYPE', 'SCORE'],
    apnoType: ['PBD', 'PATENT_TYPE'],
    patentType: ['A', 'B'],
    authorityType: ['AE', 'AM', 'AP', 'AR', 'AT', 'AU', 'AZ', 'BA', 'BD', 'BE', 'BG', 'BH', 'BN', 'BO',
        'BR', 'BW', 'BX', 'BY', 'BZ', 'CA', 'CH', 'CL', 'CN', 'CO', 'CR', 'CS', 'CU', 'CY', 'CZ', 'DD',
        'DE', 'DK', 'DO', 'DZ', 'EA', 'EC', 'EE', 'EG', 'EP', 'ES', 'EU', 'FI', 'FR', 'GB', 'GC', 'GE',
        'GH', 'GR', 'GT', 'HK', 'HN', 'HR', 'HU', 'ID', 'IE', 'IL', 'IN', 'IS', 'IT', 'JO', 'JP', 'KE',
        'KG', 'KH', 'KR', 'KZ', 'LA', 'LB', 'LT', 'LU', 'LV', 'MA', 'MC', 'MD', 'ME', 'MK', 'MN', 'MO',
        'MT', 'MW', 'MX', 'MY', 'MZ', 'NI', 'NL', 'NO', 'NZ', 'OA', 'PA', 'PE', 'PG', 'PH', 'PL', 'PT',
        'PY', 'RO', 'RS', 'RU', 'RW', 'SA', 'SE', 'SG', 'SI', 'SK', 'SM', 'SU', 'SV', 'SY', 'TH', 'TJ',
        'TN', 'TR', 'TT', 'TW', 'TZ', 'UA', 'US', 'UY', 'UZ', 'VE', 'VN', 'WO', 'YU', 'ZA', 'ZM', 'ZW'],
    dateType: ['Latest', 'Oldest'],
    documentType: ['APD', 'PBD'],
    tagFamilyTypeList: ['EXTEND', 'DOCDB', 'INPADOC'],
    tagFamilyList: ['all', 'CN', 'DE', 'EP', 'FR', 'GB', 'JP', 'KR', 'RU', 'US', 'WO'],
    languageList: ['default', 'translation'],
    stemList: [true, false],
};

const apnoTypeMap = ['APNO_FACET'];
const searchCollapseTypeMap = ['FAMILY_ID', 'INPADOC_FAMILY_ID', 'EXTEND_FAMILY'];
const usFamilyList = ['US', 'EP', 'WO', 'JP', 'KR'];
const cnFamilyList = ['CN', 'US', 'EP', 'JP', 'KR'];

function formatUserSetting(data) {
    let {
        /* eslint-disable */
        collapse, stem, tag_family, tag_family_type, origin_text, language,
    } = data;
    if (isEmpty(collapse)) {
        collapse = {
            field: 'ALL',
            rules: [],
        };
    }
    let userSetting = {
        collapse,
        stem,
        tagFamily: tag_family,
        tagFamilyType: tag_family_type,
        originText: origin_text,
        language,
    };
    userSetting = omitBy(userSetting, (item) => isUndefined(item));
    return userSetting;
}

function formatCollapse(data) {
    if (!isEmpty(data)) {
        let { collapse } = data;
        const field = get(collapse, 'field');
        if (field === 'ALL') {
            collapse = {};
        }
        const setting = { ...data };
        setting.collapse = collapse;
        return setting;
    }
}

function formatUserSettingToReq(data) {
    if (!isEmpty(data)) {
        const {
            collapse, stem, tagFamily, tagFamilyType, originText, language,
        } = data;
        let userSetting = {
            collapse,
            stem,
            tag_family: tagFamily,
            tag_family_type: tagFamilyType,
            origin_text: originText,
            language,
        };
        userSetting = omitBy(userSetting, (item) => isUndefined(item));
        return userSetting;
    }
}

function formatUserSettingFromWorkspace(data) {
    const userSetting = {
        collapse: get(data, 'collapse'),
        stem: get(data, 'stem'),
        tagFamily: get(data, 'family_country'),
        tagFamilyType: get(data, 'family_tag'),
        language: get(data, 'language'),
    };
    return userSetting;
}

function ruleToGroupData(rule) {
    if (isEmpty(rule)) return {};
    const { type, field, value } = rule;
    const groupData = {};
    if (type === 'sort') {
        if (field === 'COUNTRY') {
            groupData.groupBy = 'AUTHORITY';
            groupData.groupFamily = value.split(',');
        } else if (field === 'PATENT_TYPE') {
            groupData.groupBy = 'PATENT_TYPE';
            groupData.groupSort = value;
        }
    } else if (type === 'min') {
        groupData.groupSort = 'Oldest';
        groupData.groupBy = field === 'APD_YEARMONTHDAY' ? 'APD' : 'PBD';
    } else if (type === 'max') {
        groupData.groupSort = 'Latest';
        groupData.groupBy = field === 'APD_YEARMONTHDAY' ? 'APD' : 'PBD';
    } else if (type === 'relevant') {
        groupData.groupBy = 'SCORE';
    }
    return groupData;
}

function groupDataToRule(groupData) {
    let rule = {};
    const { groupBy, groupFamily, groupSort } = groupData;
    switch (groupBy) {
    case 'AUTHORITY':
        rule = {
            type: 'sort',
            field: 'COUNTRY',
            value: groupFamily.join(','),
        };
        break;
    case 'APD':
        if (groupSort === 'Oldest') {
            rule = {
                type: 'min',
                field: 'APD_YEARMONTHDAY',
            };
        } else {
            rule = {
                type: 'max',
                field: 'APD_YEARMONTHDAY',
            };
        }
        break;
    case 'PBD':
        if (groupSort === 'Oldest') {
            rule = {
                type: 'min',
                field: 'PBDT_YEARMONTHDAY',
            };
        } else {
            rule = {
                type: 'max',
                field: 'PBDT_YEARMONTHDAY',
            };
        }
        break;
    case 'SCORE':
        rule = {
            type: 'relevant',
            field: 'score',
        };
        break;
    case 'PATENT_TYPE':
        rule = {
            field: 'PATENT_TYPE',
            type: 'sort',
            value: groupSort,
        };
        break;
    default:
        break;
    }
    return rule;
}

function getLocalGroupFamily(){
    const localGroupFamilyStr = localStorage.getItem('USER_SETTING_LOCAL_GROUP_FAMILY')
    if (localGroupFamilyStr){
        return localGroupFamilyStr.split(',')
    }
    return []
}

function setLocalGroupFamily(localGroupFamilyStr) {
    localStorage.setItem('USER_SETTING_LOCAL_GROUP_FAMILY', localGroupFamilyStr)
}

export {
    settingConfig,
    apnoTypeMap,
    searchCollapseTypeMap,
    usFamilyList,
    cnFamilyList,
    formatUserSetting,
    formatUserSettingToReq,
    formatCollapse,
    formatUserSettingFromWorkspace,
    ruleToGroupData,
    groupDataToRule,
    getLocalGroupFamily,
    setLocalGroupFamily
};
