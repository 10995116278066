import { Node } from '../../../Values/Node';
import { Token } from '../../../Values/Token';

export class Prohibited extends Node {
  constructor(public operand: Node, public token: Token) {
    super();
  }

  public getNodes() {
    return [this.operand];
  }

  public getReductionGroup() {
    return 'unaryOperator';
  }
}
