var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patsnap-biz-image_loader ai-common-image-loader" },
    [
      _c("div", { staticClass: "image-wrap", style: _vm.cvsStyle }, [
        _c("canvas", { ref: "canvas", staticClass: "image-canvas" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }