var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BzDialog",
    {
      staticClass: "patsnap-biz-email_alert__dialog",
      attrs: {
        width: "600px",
        title: _vm.$t("emailAlertBiz.common.alertDialog.createTitle"),
        visible: _vm.visible,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: { close: _vm.onCancel },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "pz-email-alert__body email-alert-dialog",
        },
        [
          _vm._t("default", null, { service: _vm.service }),
          _c("AlertCommonModule", {
            attrs: {
              service: _vm.service,
              "is-all-email-valid": _vm.isAllEmailValid,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "wechat",
                  fn: function () {
                    return [_vm._t("wechat")]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "pz-email-alert__body email-alert-dialog pz-email-alert__loading",
        },
        [
          _c("Icon", {
            staticClass: "pz-email-alert__loading-icon",
            attrs: { icon: "SolidLoadingBars" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "pt-email-alert__footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "pt-ui-btn",
              attrs: { size: "small" },
              on: { click: _vm.onCancel },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("emailAlertBiz.common.alertDialog.cancel")) +
                  " "
              ),
            ]
          ),
          _c(
            "button",
            {
              staticClass: "pt-ui-btn",
              attrs: {
                size: "small",
                type: "submit",
                disabled: _vm.submitBtnDisabled,
              },
              on: { click: _vm.onSubmit },
            },
            [
              _vm.submitting
                ? _c("Icon", {
                    staticClass: "submitting-icon",
                    attrs: { icon: "SolidLoadingBars" },
                  })
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("emailAlertBiz.common.alertDialog.submit")) +
                  " "
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }