var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "representative-container", class: _vm.representativeClass },
    [
      _c(
        "p",
        { staticClass: "representative-title" },
        [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t("app.userSetting.criteriaForChoosingRepresentative")
              )
            ),
          ]),
          _vm.showTitleTips
            ? _c(
                "ElPopover",
                {
                  attrs: {
                    placement: "top",
                    "visible-arrow": false,
                    width: "388",
                    trigger: "hover",
                    "popper-class":
                      "user-setting__popover user-setting__popover-title-tips",
                  },
                },
                [
                  _c("div", { staticClass: "single-panel" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "app.userSetting.criteriaForChoosingRepresentativeTips"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("PtIcon", {
                    staticClass: "hover-icon",
                    staticStyle: { "font-size": "24px", fill: "#B3BAC5" },
                    attrs: { slot: "reference", icon: "SolidInfo" },
                    slot: "reference",
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "representative-item clearfix" },
        [
          _c("RepresentativeItem", {
            attrs: {
              "model-type": _vm.modelType,
              "service-area": _vm.serviceArea,
              "group-data": _vm.firstRuleData,
            },
            on: { updateData: _vm.updateFirstRuleData },
          }),
          _vm.showAddBtn
            ? _c(
                "ElTooltip",
                {
                  attrs: {
                    placement: "top",
                    "popper-class": "representative__add-rule-tooltip",
                    "visible-arrow": false,
                    content: _vm.$t("app.userSetting.AddRule"),
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "rule-btn",
                      on: {
                        click: function ($event) {
                          _vm.showSecondRule = true
                        },
                      },
                    },
                    [
                      _c("PtIcon", {
                        staticStyle: { "font-size": "24px", fill: "#505F79" },
                        attrs: { icon: "SolidAddLarge" },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.multipleRule && _vm.modelType !== "APNO_FACET"
        ? _c(
            "div",
            { staticClass: "representative-item clearfix" },
            [
              _c("span", { staticClass: "representative-item-left" }, [
                _vm._v(_vm._s(_vm.$t("app.userSetting.secondRule"))),
              ]),
              _c("RepresentativeItem", {
                attrs: {
                  "model-type": _vm.modelType,
                  "group-data": _vm.secondRuleData,
                  "service-area": _vm.serviceArea,
                  "exclude-field": _vm.firstRuleData.groupBy,
                },
                on: { updateData: _vm.updateSecondRuleData },
              }),
              _c(
                "ElTooltip",
                {
                  attrs: {
                    placement: "top",
                    "popper-class": "representative__add-rule-tooltip",
                    "visible-arrow": false,
                    content: _vm.$t("app.userSetting.RemoveRule"),
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "rule-btn", on: { click: _vm.removeRule } },
                    [
                      _c("PtIcon", {
                        staticStyle: { "font-size": "24px", fill: "#505F79" },
                        attrs: { icon: "SolidDelete" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }