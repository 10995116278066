var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "patsnap-biz-slider_range ai-common-slider-range",
      class: [
        _vm.layoutIsHorizontal ? "layout-is-horizontal" : "layout-is-vertical",
        _vm.minInputErrorMsg || _vm.maxInputErrorMsg ? "has-error" : "",
      ],
    },
    [
      _c("ElSlider", {
        ref: "ElSlider",
        attrs: {
          value: _vm.value,
          max: _vm.max,
          min: 0,
          range: true,
          disabled: _vm.disabled,
          "show-tooltip": _vm.showTooltip,
        },
        on: { input: _vm.inputChangeRange },
      }),
      _c(
        "div",
        {
          staticClass: "range-input min",
          class: {
            "is-error": _vm.minInputErrorMsg,
            "show-unit": _vm.showUnit(_vm.typeIsMin),
          },
        },
        [
          _vm.disabled ? _c("div", { staticClass: "disable-bg" }) : _vm._e(),
          _vm.minInputErrorMsg
            ? _c("div", { staticClass: "error-msg" }, [
                _c("p", [_vm._v(_vm._s(_vm.minInputErrorMsg))]),
              ])
            : _vm._e(),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.minInput,
                expression: "minInput",
              },
            ],
            ref: "inputMin",
            attrs: { type: "text" },
            domProps: { value: _vm.minInput },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minInput = $event.target.value
                },
                function ($event) {
                  return _vm.limitInput(_vm.typeIsMin)
                },
              ],
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.updateRange(_vm.typeIsMin)
              },
              blur: function ($event) {
                return _vm.updateRange(_vm.typeIsMin)
              },
            },
          }),
          _vm.showUnit(_vm.typeIsMin)
            ? _c("div", { staticClass: "number-unit" }, [
                _vm._v(" " + _vm._s(_vm.unit) + " "),
              ])
            : _vm._e(),
        ]
      ),
      !_vm.layoutIsHorizontal
        ? _c(
            "div",
            {
              staticClass: "range-input-line",
              class: { disabled: _vm.disabled },
            },
            [_vm._v(" - ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "range-input max",
          class: {
            "is-error": _vm.maxInputErrorMsg,
            "show-unit": _vm.showUnit(_vm.typeIsMax),
          },
        },
        [
          _vm.disabled ? _c("div", { staticClass: "disable-bg" }) : _vm._e(),
          _vm.maxInputErrorMsg
            ? _c("div", { staticClass: "error-msg" }, [
                _c("p", [_vm._v(_vm._s(_vm.maxInputErrorMsg))]),
              ])
            : _vm._e(),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.maxInput,
                expression: "maxInput",
              },
            ],
            ref: "inputMax",
            attrs: { type: "text", placeholder: _vm.maxPlaceholder },
            domProps: { value: _vm.maxInput },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.maxInput = $event.target.value
                },
                function ($event) {
                  return _vm.limitInput(_vm.typeIsMax)
                },
              ],
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.updateRange(_vm.typeIsMax)
              },
              blur: function ($event) {
                return _vm.updateRange(_vm.typeIsMax)
              },
            },
          }),
          _vm.showUnit(_vm.typeIsMax)
            ? _c("div", { staticClass: "number-unit" }, [
                _vm._v(" " + _vm._s(_vm.unit) + " "),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }