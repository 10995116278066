/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import BasicService from '../Basic/BasicService';

class PaperService extends BasicService {
    constructor() {
        super();
        this.type = 'PAPER';
    }

    getFolderIcon() {
        // Paper 只有普通文件夹图标
        return 'SolidFolderClosed2';
    }

    getWorkspaceElementsCountString(workspace) {
        const { total_paper_count = 0 } = workspace;
        const count = this.formatNumber(total_paper_count);
        return `(${count})`;
    }

    getFolderElementsCountString({ folder }) {
        const { paper_count = 0 } = folder;
        return `(${this.formatNumber(paper_count)})`;
    }

    getSubmitApiConfig() {
        const { selectedWorkspace: { space_id }, selectedFolder: { folder_id } } = this.folderSelector;
        return {
            url: `${this.baseUrl}/space/${space_id}/folder/${folder_id}/save-papers`,
            method: 'POST',
        };
    }
}

export default PaperService;
