import en from './en.json';
import cn from './cn.json';
import jp from './jp.json';
import tw from './tw.json';

export default {
    fallbackLocale: 'en',
    messages: {
        en,
        cn,
        jp,
        tw,
        'en-US': en,
        'zh-CN': cn,
        'zh-TW': tw,
        'ja-JP': jp,
    },
};
