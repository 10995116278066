import { TOKEN_GROUP_BEGIN } from '../../../constants';
import { Token } from '../../../Values/Token';

/**
 * GroupBegin token represents group's domain and left side delimiter.
 */
export class GroupBegin extends Token {
  constructor(
    lexeme: string,
    position: number,
    public delimiter: string,
    public domain: string,
  ) {
    super(TOKEN_GROUP_BEGIN, lexeme, position);
  }
}
