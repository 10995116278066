import { Correction } from './Correction';
import { TokenSequence } from './TokenSequence';
import { Node } from './Node';
import { Token } from './Token';
import { isEmpty } from '../utils/common';

/**
 * Syntax tree is an abstract hierarchical representation of the query syntax,
 * intended for easy conversion into different concrete formats.
 */
export class SyntaxTree {
  /**
   *
   * @param rootNode The root node of the syntax tree.
   * @param tokenSequence Token sequence that was parsed into this syntax tree.
   * @param corrections An array of corrections performed while parsing the token sequence.
   */
  constructor(
    public rootNode: Node,
    public tokenSequence: TokenSequence,
    public corrections: Correction[],
  ) {}

  /**
   * 获取解析的tokens
   * 如果有correction信息会合并上去
   * @returns
   */
  public getTokensWithCorrections(): Token[] {
    if (!isEmpty(this.corrections)) {
      const correctionTokens = this.corrections.flatMap((c) => c.tokens);
      this.tokenSequence.tokens.forEach((token) => {
        const correct = correctionTokens.find(
          (c) => c.position === token.position,
        );
        if (correct) {
          token.errType = correct.errType;
        }
      });
    }
    return this.tokenSequence.tokens;
  }

  /**
   * 是否是空的树
   * @returns
   */
  public isEmpty() {
    return isEmpty(this.rootNode.getNodes());
  }

  /**
   * 是否合法
   * @returns
   */
  public isValid() {
    return isEmpty(this.corrections);
  }

  /**
   * 获取字符串源数据
   * @returns
   */
  public getSource() {
    return this.tokenSequence.source;
  }
}
