/* eslint-disable max-len */
import fill from 'lodash/fill';

const EMAIL_REG_EXP = new RegExp(/^[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[A-Za-z0-9!#$%&'*+\-/=?^_`{|}~]+)*@([A-Za-z0-9-])+(\.[A-Za-z0-9-]+)*((\.[A-Za-z0-9]{2,})|(\.[A-Za-z0-9]{2,}\.[A-Za-z0-9]{2,}))$/);
export const checkEmail = (email) => EMAIL_REG_EXP.test(email);

const colors = [
    '#1976D2',
    '#FF0000',
    '#27B4D1',
    '#6F2EA3',
    '#DC7C08',
];
export function getSuggestionColor(letter) {
    const code = (letter || '').toUpperCase().charCodeAt();

    if (code >= 48 && code <= 57) {
        return colors[(code - 48) % colors.length];
    }

    if (code >= 65 && code <= 90) {
        return colors[(code - 65) % colors.length];
    }

    return colors[0];
}

export function getMoblieDesensitization(number) {
    if (typeof number !== 'string') return;
    const tel = number.split('');
    const res = fill(tel, '*', 3, tel.length - 4);
    // eslint-disable-next-line consistent-return
    return res.join('');
}
