/**
 * `DialogAutoMounterManager` - 管理 Vue 弹窗组件的注册、打开/关闭、以及属性更新等操作
 *
 * 在顶部总入口模版里需要加入
 *
 * <template v-for="name in DialogAutoMounterManager.loopForNames">
 *             <component v-if="DialogAutoMounterManager.attrMap[name].vIf"
 *                    :key="name"
 *                    :is="DialogAutoMounterManager.componentMap[name]"
 *                    v-on="DialogAutoMounterManager.attrMap[name].$listeners"
 *                    v-bind="DialogAutoMounterManager.attrMap[name].$props"/>
 * </template>
 *
 * 通用用法：
 * - 注册：DialogAutoMounterManager.register(【Dialog Vue组件】) ## 可选执行, 注意 Dialog组件这个Function或对象必须保持内存引用，否则会当成不同的组件
 * - 传参数：DialogAutoMounterManager.update(【Dialog Vue组件】,【props】，【事件监听函数】)
 * - 打开/关闭：DialogAutoMounterManager.toggleOpenState(【Dialog Vue组件】,true/false)
 *
 * 优雅用法：
 * - 传参数：DialogAutoMounterManager.update(【Dialog Vue组件】,【props】，【事件监听函数, 不需要resolve, reject, close 函数】)
 * - 打开，在用户submit时resolve，在关闭或点取消时reject：
 *     DialogAutoMounterManager.openAsPromise(【Dialog Vue组件】)
 *      .then((【Dialog emit resolve 事件的内容】) {} )
 *      .catch((【Dialog emit reject 或 close 事件的内容】) {} )
 *
 * [可选] 和某个View的生命周期绑定 （某个View Model 销毁时，在本View Model active 期间打开的 Dialog 会跟着一起 注销）:
 * 即：用户按浏览器后退按钮时，Dialog 忘记销毁的bug
 * - DialogAutoMounterManager.bindAutoUnregisterToLifecycle(vm) // 放在 created hook里
 *
 */

import Vue from 'vue';
import type { Component, AsyncComponent } from 'vue';
import _ from 'lodash';
// @ts-ignore
import DialogAutoMountPoint from './DialogAutoMountPoint.vue';


type AnyComponent = Component | AsyncComponent;

const VUE_RESERVED_PROPS = ['vIf', 'vShow', 'vSlot', 'vBind', 'key'];

const defaultNamespace = Symbol('defaultNamespace');

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getObjectsByValue(map: Map<AnyComponent, string | symbol>, namespace: string | symbol) {
    const objects = [];
    for (const [key, value] of map) {
        if (value === namespace) {
            objects.push(key);
        }
    }
    return objects;
}

export class DialogAutoMounterManager {
    private _dialogToNameMap: WeakMap<AnyComponent, string> = new WeakMap();

    /**
     * 组件名称到组件对象的映射
     */
    public componentMap: { [compName: string]: AnyComponent } = {};

    private nameSpaceMap: Map<AnyComponent, string | symbol> = new Map();

    /**
     * 组件名称到组件属性的映射，包括 v-if 状态、props 属性和事件监听函数
     */
    public attrMap: {
        [key: string]: {
            vIf: boolean,
            $props: Vue['$props'],
            $listeners: Vue['$listeners']
        }
    };

    static MountPoint = DialogAutoMountPoint;

    /**
     * 构造函数，初始化属性映射对象
     */
    constructor() {
        Vue.set(this, 'attrMap', {});
    }

    /**
     * 获取已注册的组件名称列表
     */
    loopForNames: string[] = [];

    protected currentNamespace: string | symbol = defaultNamespace;

    private hasRegistered(dialogComp: AnyComponent) {
        return this._dialogToNameMap.has(dialogComp);
    }

    private getName(dialogComp: AnyComponent) {
        return this._dialogToNameMap.get(dialogComp);
    }

    /**
     * 注册一个组件对象
     *
     * @param dialogComp - 弹窗组件对象
     * @param autoUnregisterNamespace - 自动销毁的命名空间
     */
    public register(dialogComp: AnyComponent, autoUnregisterNamespace: string | symbol = defaultNamespace) {
        let name;
        if (this.hasRegistered(dialogComp)) {
            name = this.getName(dialogComp);
        } else {
            name = capitalizeFirstLetter(_.camelCase(_.uniqueId(`${dialogComp.name}_as_auto_mounter_dialog_`)))
            this._dialogToNameMap.set(dialogComp, name);
        }

        if (this.componentMap[name] && this.componentMap[name] !== dialogComp) {
            console.error(`Different Dialog with same ${name}!`);
            return;
        }
        if (this.componentMap[name]) {
            return;
        }

        // 将组件对象添加到组件名称映射中
        this.componentMap[name] = dialogComp;
        this.loopForNames.push(name);
        this.nameSpaceMap.set(dialogComp, autoUnregisterNamespace);

        // 初始化属性映射对象
        Vue.set(this.attrMap, name, {
            vIf: false,
            $props: {},
            $listeners: {},
        });
    }

    /**
     * 更新指定组件的属性
     *
     * @param dialogComp - 弹窗组件对象
     * @param $props - 更新的 props 属性
     * @param $listeners - 更新的事件监听函数
     */
    public update(dialogComp: AnyComponent, $props: Vue['$props'], $listeners: Vue['$listeners'] = {}) {
        if (!this.hasRegistered(dialogComp)) {
            this.register(dialogComp);
        }

        const name = this.getName(dialogComp);
        const props = _.omit($props, ['vIf']);
        this.attrMap[name] = {
            ...this.attrMap[name],
            $props: { ...this.attrMap[name].$props, ...props },
            $listeners: { ...this.attrMap[name].$listeners, ...$listeners },
        };

        // 有vue保留属性
        const reservedProps = Object.keys($props).filter((key) => VUE_RESERVED_PROPS.includes(key));
        const hasVueReservedProp = reservedProps.length > 0;

        if (hasVueReservedProp) {
            if ($props.vIf !== undefined) {
                this.attrMap[name].vIf = $props.vIf;
            } else {
                console.error('Unsupported reserved props:', reservedProps.join(', '));
            }
        }
        if (this.attrMap[name].vIf) {
            this.nameSpaceMap.set(dialogComp, this.currentNamespace);
        }
    }

    static DialogCloseSignal = 'DialogCloseSignal';
    /**
     * 打开弹窗，但是返回了Promise，
     * 弹窗需要emit ‘resolve’ 和 ‘reject’ 事件
     * 如果有其他属性，需要先调用 DialogAutoMounterManager.update 准备好
     *
     * @param dialogComp - 弹窗组件对象
     */
    public openAsPromise<SubmitResult>(dialogComp: AnyComponent): Promise<SubmitResult> {
        if (!this.hasRegistered(dialogComp)) {
            this.register(dialogComp);
        }
        return new Promise((resolve, reject) => {
            const name = this.getName(dialogComp);
            const originCloseFunc = this.attrMap[name].$listeners.close;
            this.attrMap[name] = {
                ...this.attrMap[name],
                $listeners: {
                    ...this.attrMap[name].$listeners,
                    resolve: (result: SubmitResult) => {
                        this.attrMap[name].vIf = false;
                        resolve(result);
                    },
                    reject: (err: any = DialogAutoMounterManager.DialogCloseSignal) => {
                        this.attrMap[name].vIf = false;
                        reject(err);
                    },
                    close: (...closeArgs) => {
                        if (typeof originCloseFunc === 'function') {
                            originCloseFunc(...closeArgs);
                        }
                        this.attrMap[name].vIf = false;
                        if (closeArgs[0]) {
                            reject(closeArgs[0])
                        } else {
                            reject(DialogAutoMounterManager.DialogCloseSignal)
                        }
                    },
                },
            };
            this.attrMap[name].vIf = true;
            this.nameSpaceMap.set(dialogComp, this.currentNamespace);
        });
    }

    /**
     * 切换指定组件的 v-if 状态
     *
     * @param dialogComp - 弹窗组件对象
     * @param vIf - v-if 状态，true 表示显示，false 表示隐藏
     */
    public toggleOpenState(dialogComp: AnyComponent, vIf: boolean) {
        if (!this.hasRegistered(dialogComp)) {
            this.register(dialogComp);
        }
        const name = this.getName(dialogComp);
        this.attrMap[name] = { ...this.attrMap[name], vIf };

        if (this.attrMap[name].vIf) {
            this.nameSpaceMap.set(dialogComp, this.currentNamespace);
        }
    }

    /**
     * 是否有打开的弹窗
     */
    public hasOpenDialog(): boolean {
        return this.loopForNames.some((name) => this.attrMap[name].vIf);
    };

    /**
     * 注销指定名称的组件, 不一定需要执行
     *
     * @param dialogComp - 弹窗组件对象
     */
    public unregister(dialogComp: AnyComponent): void {
        if (!this.hasRegistered(dialogComp)) {
            console.warn(`Dialog ${dialogComp.name} not registered! ignore`);
            return;
        }
        const name = this.getName(dialogComp);
        // 从组件名称映射和属性映射中删除对应的对象
        delete this.componentMap[name];
        this.loopForNames = this.loopForNames.filter((item) => item !== name);
        delete this.attrMap[name];
        // 从弱引用映射中删除对应的对象
        this._dialogToNameMap.delete(dialogComp);

        // 更新组件名称映射对象
        this.componentMap = Object.assign({}, this.componentMap);
        this.nameSpaceMap.delete(dialogComp);
    }

    /*
     * 无脑关闭所有弹窗
     * 一般用于路由切换时，在路由hooks中call这个方法
     */
    public unregisterByNamespace(namespace: string | symbol = defaultNamespace) {
        const dialogCompList = getObjectsByValue(this.nameSpaceMap, namespace)
        dialogCompList.forEach((dialogComp) => {
            this.unregister(dialogComp);
        });
        this.currentNamespace = defaultNamespace;
    }

    /**
     * 绑定自动注销到Vue实例的生命周期
     *
     * @param vm - Vue实例
     */
    public bindAutoUnregisterToLifecycle(vm: Vue, _namespace?: string | symbol) {
        const namespace = _namespace || Symbol(_.uniqueId('namespace_'));

        this.currentNamespace = namespace;
        vm.$once('hook:beforeDestroy', () => {
            this.unregisterByNamespace(namespace);
        })
    }
}
