<!-- eslint-disable vue/no-v-html-->
<template>
    <Transition name="pt-message-fade">
        <div
            v-show="visible"
            :class="[
                'patsnap-biz-toast',
                `patsnap-biz-toast--${ type }`,
                `patsnap-biz-toast--${ size }`,
                {
                    'patsnap-biz-toast--closable': showClose,
                    'patsnap-biz-toast--without-icon': !iconName,
                }
            ]"
            role="alert"
            @mouseenter="clearTimer"
            @mouseleave="startTimer"
        >
            <PtIcon
                v-if="iconName"
                :icon="iconName"
                :style="{ fontSize: iconSize }"
            />
            <slot>
                <p
                    v-if="!dangerouslyUseHTMLString"
                    class="patsnap-biz-toast__content"
                >
                    {{ message }}
                </p>
                <p
                    v-else
                    class="patsnap-biz-toast__content"
                    v-html="message"
                />
            </slot>
            <span
                v-if="showClose"
                class="patsnap-biz-toast__close-btn"
            >
                <PtIcon
                    icon="SolidCloseMedium"
                    :style="{ fontSize: iconSize }"
                    @click="close"
                />
            </span>
        </div>
    </Transition>
</template>

<script>
import Icon from '@patsnap-biz/icon';
import '@patsnap-ui/icon/assets/solid/CloseMedium.svg';
import '@patsnap-ui/icon/assets/solid/SmilesHappy.svg';
import '@patsnap-ui/icon/assets/solid/info.svg';
import '@patsnap-ui/icon/assets/solid/warning.svg';
import '@patsnap-ui/icon/assets/solid/SmilesSad.svg';

const iconNameMap = {
    success: 'SolidSmilesHappy',
    info: 'SolidInfo',
    warning: 'SolidWarning',
    error: 'SolidSmilesSad',
};

export default {
    name: 'PtToast',
    components: {
        PtIcon: Icon,
    },
    data() {
        return {
            visible: false,
            message: '',
            duration: 3000,
            type: 'info',
            icon: '',
            onClose: null,
            showClose: false,
            closed: false,
            size: 'medium',
            timer: null,
            dangerouslyUseHTMLString: false,
        };
    },
    computed: {
        iconName() {
            if (this.icon === null) {
                return null;
            }
            return this.icon || iconNameMap[this.type];
        },
        iconSize() {
            return this.size === 'medium' ? '24px' : '16px';
        },
    },
    watch: {
        closed(val) {
            if (val) {
                this.visible = false;
                /* istanbul ignore next */
                if (process.env.NODE_ENV === 'test') {
                    this.destroyElement();
                } else {
                    this.$el.addEventListener('transitionend', this.destroyElement);
                }
            }
        },
    },
    mounted() {
        this.startTimer();
        document.addEventListener('keydown', this.keydown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.keydown);
    },
    methods: {
        destroyElement() {
            this.$el.removeEventListener('transitionend', this.destroyElement);
            this.$destroy(true);
            this.$el.parentNode.removeChild(this.$el);
        },
        close() {
            this.closed = true;
            if (typeof this.onClose === 'function') {
                this.onClose(this);
            }
        },
        clearTimer() {
            clearTimeout(this.timer);
        },
        startTimer() {
            if (this.duration > 0) {
                this.timer = setTimeout(() => {
                    if (!this.closed) {
                        this.close();
                    }
                }, this.duration);
            }
        },
        keydown(e) {
            if (e.keyCode === 27) { // Close after press ESC
                if (!this.closed) {
                    this.close();
                }
            }
        },
    },
};
</script>
