var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.imagePanelData.list.length > 0
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imagePanelShow,
              expression: "imagePanelShow",
            },
          ],
          staticClass: "patsnap-biz-image_panel ai-common-image-panel",
          on: { mouseup: _vm.endMove },
        },
        [
          _c("div", { staticClass: "ai-common-image-panel__container" }, [
            _c(
              "div",
              {
                ref: "imageContent",
                staticClass: "ai-common-image-panel__content",
              },
              [
                _c(
                  "div",
                  {
                    ref: "imagePanelLazy",
                    staticClass: "ai-common-image-panel__move-content",
                    style: _vm.position,
                    on: { mousemove: _vm.moveImage, mousedown: _vm.startMove },
                  },
                  [
                    _vm.isUseCanvas && _vm.imageType === "image"
                      ? _c(
                          "div",
                          {
                            staticClass: "ai-common-image-panel__canvas",
                            style: _vm.imageStyle,
                          },
                          [
                            _c("ai-common-image-loader", {
                              key: _vm.currentIndex,
                              attrs: {
                                item: _vm.imagePanelData.list[_vm.currentIndex]
                                  .item,
                                size: _vm.panelSize,
                              },
                              on: { imageLoaded: _vm.imageLoaded },
                            }),
                            _vm.isShowLoading
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ai-common-image-panel__canvas-loading",
                                  },
                                  [
                                    _c("PtIcon", {
                                      staticStyle: { "font-size": "30px" },
                                      attrs: {
                                        spin: "true",
                                        icon: "SolidLoadingCircle",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isUseCanvas || _vm.imageType !== "image"
                      ? _c("PtLazy", {
                          key: _vm.currentIndex,
                          staticClass: "ai-common-image-panel__lazy",
                          attrs: {
                            options: {
                              root: _vm.$refs.imagePanelLazy,
                              delay: 0,
                            },
                            "lazy-task": _vm.asyncload(
                              _vm.imagePanelData.list[_vm.currentIndex]
                            ),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return _c("img", {
                                    staticClass: "ai-common-image-panel__image",
                                    style: _vm.imageStyle,
                                    attrs: {
                                      src: props.data,
                                      draggable: "false",
                                    },
                                  })
                                },
                              },
                            ],
                            null,
                            false,
                            2377495970
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ai-common-image-panel__ctrl" },
                  [
                    _c("PtIcon", {
                      staticClass: "ai-common-image-panel__btn",
                      attrs: { icon: "SolidAddMedium" },
                      on: { click: _vm.enlarge },
                    }),
                    _c("PtIcon", {
                      staticClass: "ai-common-image-panel__btn",
                      attrs: { icon: "SolidMinusMedium" },
                      on: { click: _vm.narrow },
                    }),
                    _c("PtIcon", {
                      staticClass: "ai-common-image-panel__btn",
                      attrs: { icon: "SolidRotateClockwise" },
                      on: { click: _vm.scroll },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "ai-common-image-panel__info" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.currentIndex + 1 + "/" + _vm.imageCount) +
                  " images "
              ),
            ]),
            _vm.imagePanelData.type != "image"
              ? _c("div", { staticClass: "ai-common-image-panel__tab" }, [
                  _c(
                    "label",
                    {
                      staticClass: "ai-common-image-panel__thumb-tab",
                      on: {
                        click: function ($event) {
                          return _vm.updateImgTab("image")
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "check-btn",
                        class: {
                          "check-btn-active": _vm.imageType === "image",
                        },
                      }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("PtImagePanel.image"))),
                      ]),
                    ]
                  ),
                  _c(
                    "label",
                    {
                      staticClass: "ai-common-image-panel__thumb-tab",
                      on: {
                        click: function ($event) {
                          return _vm.updateImgTab("pdfImage")
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "check-btn",
                        class: {
                          "check-btn-active": _vm.imageType === "pdfImage",
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("PtImagePanel.page")))]),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.isStart
            ? _c("div", { staticClass: "ai-common-image-panel__banner" }, [
                _c(
                  "div",
                  {
                    ref: "imagePanelLazyBanner",
                    staticClass: "ai-common-image-panel__banner-content",
                    style: _vm.bannerStyle,
                  },
                  _vm._l(_vm.imagePanelData.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "ai-common-image-panel__image-box",
                        class: {
                          "ai-common-image-panel__active":
                            index === _vm.currentIndex,
                        },
                        attrs: { "data-id": index },
                        on: {
                          click: function ($event) {
                            return _vm.selectImage(index)
                          },
                        },
                      },
                      [
                        _vm.isUseCanvas
                          ? _c("ai-common-image-loader", {
                              attrs: {
                                item: item.item,
                                size: { width: 70, height: 70 },
                              },
                            })
                          : _vm._e(),
                        !_vm.isUseCanvas
                          ? _c("PtLazy", {
                              key: "v_" + index,
                              staticClass: "ai-common-image-panel__lazy",
                              attrs: {
                                options: {
                                  root: _vm.$refs.imagePanelLazyBanner,
                                  delay: 0,
                                },
                                "lazy-task": _vm.asyncload(item),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (props) {
                                      return _c("img", {
                                        attrs: { src: props.data },
                                      })
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentIndex > 0,
                  expression: "currentIndex>0",
                },
              ],
              staticClass: "ai-common-image-panel__left",
              on: { click: _vm.toPrev },
            },
            [
              _c("PtIcon", {
                staticStyle: { "font-size": "20px" },
                attrs: { icon: "SolidArrowLeft" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentIndex < _vm.imagePanelData.list.length - 1,
                  expression: "currentIndex<imagePanelData.list.length-1",
                },
              ],
              staticClass: "ai-common-image-panel__right",
              on: { click: _vm.toNext },
            },
            [
              _c("PtIcon", {
                staticStyle: { "font-size": "20px" },
                attrs: { icon: "SolidArrowRight" },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "ai-common-image-panel__close",
              on: { click: _vm.close },
            },
            [
              _c("PtIcon", {
                staticStyle: { "font-size": "20px" },
                attrs: { icon: "SolidCloseBig" },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }