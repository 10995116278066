import { Node } from '../../../Values/Node';
import { Token } from '../../../Values/Token';

export class LogicalOr extends Node {
  constructor(
    public leftOperand: Node,
    public rightOperand: Node,
    public token: Token,
  ) {
    super();
  }

  public getNodes() {
    return [this.leftOperand, this.rightOperand];
  }

  public getReductionGroup() {
    return 'logicalOr';
  }
}
