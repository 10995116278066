var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "s2w-folder-tree-item" },
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            effect: "light",
            placement: "top",
            "popper-class": "pt-ui-tooltip",
            "visible-arrow": false,
            content: _vm.service.folderDisabledMsg({
              workspace: _vm.workspace,
              folder: _vm.folder,
            }),
            disabled: !_vm.service.folderDisabledMsg({
              workspace: _vm.workspace,
              folder: _vm.folder,
            }),
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "s2w-folder-tree-item-folder-info",
              class: {
                "is-current":
                  _vm.service.folderSelector.selectedFolder === _vm.folder,
                "is-editing": _vm.folder.isEditing,
                "is-last-saved": _vm.isLastSaved,
                disabled: _vm.service.isFolderDisabled({
                  workspace: _vm.workspace,
                  folder: _vm.folder,
                }),
              },
              style: _vm.paddingStyle,
              attrs: { "data-folder-id": _vm.folder.folder_id },
              on: { click: _vm.selectFolder },
            },
            [
              _c("PtIcon", {
                staticClass: "folder-expand-icon",
                class: {
                  "has-children":
                    _vm.folder.children && _vm.folder.children.length,
                },
                staticStyle: { "font-size": "24px" },
                attrs: {
                  icon: _vm.folder.expand
                    ? "SolidDropdownOpen"
                    : "SolidDropdownRight",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.service.toggleFolderExpand({
                      workspace: _vm.workspace,
                      folder: _vm.folder,
                    })
                  },
                },
              }),
              _c("PtIcon", {
                staticClass: "folder-icon",
                staticStyle: { "font-size": "24px" },
                attrs: {
                  icon: _vm.service.getFolderIcon({
                    workspace: _vm.workspace,
                    folder: _vm.folder,
                  }),
                },
              }),
              _c(
                "div",
                { staticClass: "folder-name-container" },
                [
                  _vm.folder.isEditing
                    ? _c(
                        "div",
                        {
                          staticClass: "folder-name-editor",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.folder.folder_name,
                                expression: "folder.folder_name",
                              },
                            ],
                            ref: "folderNameInputRef",
                            class: {
                              error: !_vm.service.isFolderNameValid({
                                workspace: _vm.workspace,
                                folder: _vm.folder,
                              }),
                            },
                            attrs: {
                              type: "text",
                              maxlength: "40",
                              disabled: _vm.folder.isUpdating,
                            },
                            domProps: { value: _vm.folder.folder_name },
                            on: {
                              keypress: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onKeypressEnter.apply(
                                  null,
                                  arguments
                                )
                              },
                              blur: function ($event) {
                                return _vm.service.finishEditFolder({
                                  workspace: _vm.workspace,
                                  folder: _vm.folder,
                                  parentFolder: _vm.parentFolder,
                                })
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.folder,
                                  "folder_name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      )
                    : [
                        _c("div", {
                          staticClass: "folder-name",
                          attrs: { title: _vm.titleText },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.service.renderFolderName(_vm.folder)
                            ),
                          },
                          on: {
                            dblclick: function ($event) {
                              return _vm.service.editFolder({
                                workspace: _vm.workspace,
                                folder: _vm.folder,
                              })
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "folder-elements-count",
                            attrs: { title: _vm.titleText },
                          },
                          [_vm._v(" " + _vm._s(_vm.countText) + " ")]
                        ),
                      ],
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "folder-item-action-pane" },
                [
                  _vm.service.hasEditFolderPermission({
                    workspace: _vm.workspace,
                    folder: _vm.folder,
                  })
                    ? _c("PtIcon", {
                        staticClass: "folder-item-action-item",
                        staticStyle: { "font-size": "24px" },
                        attrs: { icon: "SolidEdit" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.service.editFolder({
                              workspace: _vm.workspace,
                              folder: _vm.folder,
                            })
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.service.hasAddSubFolderPermission({
                    workspace: _vm.workspace,
                    folder: _vm.folder,
                    level: _vm.level,
                  })
                    ? _c("PtIcon", {
                        staticClass: "folder-item-action-item",
                        staticStyle: { "font-size": "24px" },
                        attrs: { icon: "SolidFolderAdd" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.service.addFolder({
                              workspace: _vm.workspace,
                              parentFolder: _vm.folder,
                            })
                          },
                        },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "folder-item-status-tag" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "BaseSaveToWorkspace.FolderSelector.lastFlag." +
                              _vm.service.folderSelector.latestType
                          )
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.level <= _vm.service.folderSelector.folderLoopProtectionFlag &&
      _vm.folder.expand &&
      _vm.folder.children
        ? _c(
            "div",
            _vm._l(_vm.folder.children, function (child) {
              return _c("FolderTreeItem", {
                key: child.folder_id,
                attrs: {
                  folder: child,
                  "parent-folder": _vm.folder,
                  level: _vm.level + 1,
                },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }