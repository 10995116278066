<template>
    <div class="patsnap-biz-sequence-viewer">
        <div class="sequence-viewer">
            <div
                class="sequence-area"
                :style="{ 'max-height': maxHeight }"
            >
                <ul class="l-scale">
                    <li class="scale-baffle" />
                    <li
                        v-for="(data, index) of sequencesProcessed"
                        :key="index"
                    >
                        {{ data.start }}
                    </li>
                </ul>
                <div class="con-box">
                    <div
                        class="sequences"
                        @mouseup.stop="getSelectionEnd"
                        @mousedown="getSelectionStart"
                    >
                        <!--上刻度尺-->
                        <ul
                            v-if="sequencesProcessed.length"
                            class="up-scale"
                        >
                            <li
                                v-for="(s, index) in upScales"
                                :key="index"
                                :style="{left : s.pos+'%'}"
                            >
                                {{ s.val }}
                            </li>
                        </ul>
                        <div
                            class="sequences-container"
                            @click="handleSearchDomain"
                            @mouseenter.capture="handleMouseEnter"
                        >
                            <template v-for="(item, index) in segmentsAfterProcessed">
                                <!-- eslint-disable -->
                                <VNode
                                    v-if="!enableHover || !item.highlightNos.length"
                                    :key="index"
                                    :style="{ color: item.useHighlightColor }"
                                    :nodes="cachedSequences[index]"
                                />
                                <!-- eslint-disable -->
                                <span
                                    v-else
                                    :key="index"
                                    class="highlight-area"
                                >
                                    <span class="highlight-hover-tooltip">
                                        <span
                                            class="highlight-hover-tooltip__inner"
                                            :data-no="item.useHighLight"
                                        >
                                            {{ hoverTooltip ? hoverTooltip(item.useHighLight) : '' }}：{{ searchButtonText }}</span>
                                    </span>
                                    <VNode
                                        :style="{ color: item.useHighlightColor }"
                                        :nodes="cachedSequences[index]"
                                    />
                                </span>
                            </template>
                            <!-- eslint-disable -->
                            <span
                                v-if="singleRowPadding"
                                v-html="singleRowPadding"
                            />
                            <!-- eslint-disable -->
                        </div>
                        <ul class="r-scale">
                            <li class="scale-baffle" />
                            <li
                                v-for="(data, index) of sequencesProcessed"
                                :key="index"
                            >
                                {{ data.end }}
                            </li>
                        </ul>
                    </div>
                </div>

                <!--查询按钮 浮动-->
                <ElButton
                    v-if="searchBtnVisible && shouldShowSearchBtn"
                    :style="{top:searchTop+'px',left:searchLeft+'px'}"
                    class="search-btn"
                    @click="search"
                >
                    {{ $t('seqViews.searchBtn') }}
                </ElButton>
            </div>
            <div
                v-if="!sequence"
                class="no-sequence"
            >
                {{ $t('seqViews.noSequence') }}
            </div>
        </div>
        <!-- 对齐到元素共享统一弹窗实例的典型 -->
        <!-- 出现popover需满足： -->
        <!-- 有data-no包裹，并且内部定义了data-highlight-target -->
        <ElPopover
            v-if="hoverOptions && hoverOptions.highlightTarget"
            ref="popover"
            trigger="hover"
            v-model="popoverVisible"
            placement="top-start"
            :visible-arrow="false"
            :reference="popoverReference"
            :key="hoverKey"
            popper-class="patsnap-biz-sequence_viewer__popover"
        >
            <div class="patsnap-biz-sequence_viewer__popover-spacer">
                <slot name="popover" v-bind="computedHoverOptions">
                    <VNode :nodes="computedHoverOptions.node"/>
                </slot>
            </div>
        </ElPopover>
    </div>
</template>

<script>
import { padEnd, get, toNumber } from 'lodash';
import Button from 'element-ui/lib/button';
import Popover from 'element-ui/lib/popover';
import { SimilarityColor, SIMILARITY_CONFIG_TEXT } from '@patsnap-utils/sequence';
import DegenerateInfo from '@patsnap-biz/degenerate-info';
import { calculate } from './SegmentProcess';
import { slotRender } from './slotRender';

const similarityInstance = new SimilarityColor(SIMILARITY_CONFIG_TEXT);
const SEQ_PER_LINE = 60;

let cancelFnTemp = null;
let reCenterSeqFn = null;

const BLANK = '&nbsp;';

const transferHitToHighlight = (hits) => hits.map((hit) => {
    let { subjectStart = 0, subjectEnd = 0 } = hit;
    // 检查是否反向
    if (subjectStart > subjectEnd) {
        const temp = subjectStart;
        subjectStart = subjectEnd;
        subjectEnd = temp;
    }

    return {
        start: subjectStart,
        end: subjectEnd,
        color: get(hit.similarityValue ? similarityInstance.similarity(hit.similarityValue) : {}, 'color'),
    };
});

const invalidWordReg = /[^a-zA-Z]/g;
/**
 * 拆分长序列多段 方便用于多行展示
 */
export const splitIntoSegments = (sequence, length) => {
    const segments = [];
    let preEnd = 0;
    let startIndex = 0;
    let endIndex = 0;
    for (let i = 0, size = sequence.length; i < size;) {
        const end = i + length;
        const segment = sequence.slice(i, end);
        // 计算有效序列字符的数量
        const validLength = segment.replace(invalidWordReg, '').length;
        if (validLength > 0) {
            startIndex = preEnd + 1;
            endIndex = startIndex + validLength - 1;
        } else {
            startIndex = preEnd;
            endIndex = preEnd;
        }
        segments.push({
            start: startIndex, // 有效序列的起始位置
            segment,
            end: endIndex, // 有效序列的结束位置
        });
        preEnd = endIndex;
        i = end;
    }
    return segments;
};

const VNode = {
    name: 'VNode',
    functional: true,
    props: ['nodes'],
    render(h, context) {
        return h('span', context.data, [context.props.nodes]);
    },
};

export default {
    name: 'PtSequenceViewer',
    slotRender,
    components: {
        ElButton: Button,
        ElPopover: Popover,
        VNode,
    },
    props: {
        sequence: {
            type: String,
            required: true,
        },
        sequenceType: {
            type: String,
            required: true,
        },
        segmentLength: {
            type: Number,
            default: SEQ_PER_LINE,
        },
        disabledSelectSearch: {
            type: Boolean,
            default: false,
        },
        hits: {
            type: Array,
            default: () => [],
        },
        /*
        * 示例：[{
            start: 10,
            end: 25,
            color: '#f00',
            no: 1
        }]
        */
        highlights: {
            type: Array,
            default: () => ([]),
        },
        // 计算hover的tooltip里要展示的内容
        hoverTooltip: {
            type: Function,
            default: () => {},
        },
        disabledNumberTooltip: {
            type: Boolean,
            default: true,
        },
        maxHeight: {
            type: String,
            default: '500px',
        },
        enableHover: {
            type: Boolean,
            default: false,
        },
        degenerateCodes: {
            type: Array,
            default() {
                return [];
            },
        },
        helpCenterUrl: {
            type: String,
            default: '',
        },
        shouldShowSearchBtn: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            ml: 0,
            startPos: 0, // 选中位置提示 起点和终点
            endPos: 0,
            startTop: 0,
            startLeft: 0,
            endTop: 0,
            endLeft: 0,
            searchBtnVisible: 0, // 查询按钮
            searchTop: 0,
            searchLeft: 0,
            seqWidth: 0, // 字符数少于一行时限定宽度
            popoverReference: null, // popover对齐的元素
            popoverVisible: false,
            hoverOptions: null,
        };
    },
    computed: {
        activeHighlight() {
            if (this.hits && this.hits.length) {
                const hitHighlights = transferHitToHighlight(this.hits);
                return hitHighlights;
            }
            return this.highlights;
        },
        sequencesProcessed() {
            // 如果是一行补齐空格
            const { sequence } = this;
            return splitIntoSegments(sequence, this.segmentLength);
        },
        segmentsAfterProcessed() {
            return calculate(
                this,
                this.sequence,
                this.activeHighlight,
                this.segmentLength,
                this.degenerateNos,
            );
        },
        cachedSequences() {
            return this.segmentsAfterProcessed.map((i) => i.formattedSequence);
        },
        singleRowPadding() {
            const isSingleRow = this.sequence.length < this.segmentLength;
            if (isSingleRow) {
                const num = this.segmentLength - this.sequence.length;
                const splitSpace = parseInt(num / 10, 10);
                return padEnd('', num + splitSpace, '^').replaceAll('^', BLANK);
            }
            return '';
        },
        searchButtonText() {
            return this.$t('seqViews.searchBtn');
        },
        upScales() {
            const scales = [];
            let length = this.segmentLength;
            const spaceInterval = 10;
            const count = parseInt(this.segmentLength / spaceInterval, 10) - 1;
            let index = 1;
            while (length > 10) {
                const val = (scales.length + 1) * 10;
                scales.push({ val, pos: (((val + index - 0.5) / (this.segmentLength + count)) * 100).toFixed(1) });
                length -= 10;
                // eslint-disable-next-line
                index++;
            }
            return scales;
        },
        degenerateNos() {
            // 后端从0开始计数
            return this.degenerateCodes.map((x) => x.location);
        },
        hoverDegenerate() {
            if (!this.hoverOptions) return null;
            return this.degenerateCodes.find((x) => x.location === this.hoverOptions.location);
        },
        popoverTargets() {
            if (!(this.hoverOptions && this.hoverOptions.highlightTarget)) return [];
            return this.hoverOptions.highlightTarget.split(',');
        },
        computedHoverOptions() {
            return {
                ...this.hoverOptions,
                highlightTarget: this.popoverTargets,
                node: this.renderDegenerateInfo(),
            };
        },
        hoverKey() {
            return this.hoverOptions ? this.hoverOptions.location : Math.random();
        },
    },
    watch: {
        sequencesProcessed() {
            this.$nextTick(() => {
                this.centerSequence.bind(this)();
                this.oneRowReWidth.bind(this)();
            });
        },
    },
    mounted() {
        cancelFnTemp = this.cancelSelection.bind(this);
        document.querySelector('body').addEventListener('mouseup', cancelFnTemp);
        // 重新居中
        let isResize = 0;
        const delayCenter = function named() {
            if (!isResize) {
                isResize = 1;
                setTimeout(() => {
                    this.centerSequence();
                    isResize = 0;
                }, 500);
            }
        };
        reCenterSeqFn = delayCenter.bind(this);
        window.addEventListener('resize', reCenterSeqFn);
    // console.log(calculate(this.sequence, this.highlights), 'calculate');
    },
    beforeDestroy() {
        document.querySelector('body').removeEventListener('mouseup', cancelFnTemp);
        window.removeEventListener('resize', reCenterSeqFn);
    },
    methods: {
        renderDegenerateInfo() {
            const canRender = this.popoverTargets.includes('degenerate') && this.hoverOptions && this.hoverDegenerate;
            if (!canRender) return null;
            return this.$createElement(DegenerateInfo, {
                props: {
                    charInfo: {
                        location: this.hoverDegenerate.location,
                        degenerateCode: this.hoverDegenerate.degenerateCode,
                    },
                    replaces: this.hoverDegenerate.patentReplaces,
                    commonReplaces: this.hoverDegenerate.commonReplaces,
                    isCommon: this.hoverDegenerate.commonDegenerate,
                    helpCenterUrl: this.helpCenterUrl,
                },
            }, []);
        },
        prevent(/* e */) {
            // 取消双击选中
            window.getSelection().empty();
            this.searchBtnVisible = 0;
        },
        getSeqAreaDom() {
            this.seqAreaDom = this.seqAreaDom || this.$el.querySelector('.sequence-area');
            return this.seqAreaDom;
        },
        getSeqViewerDom() {
            this.seqViewerDom = this.seqViewerDom || this.$el.querySelector('.sequence-viewer');
            return this.seqViewerDom;
        },
        centerSequence() {
            const domSeq = this.getSeqAreaDom();
            const domViewer = this.getSeqViewerDom();
            if (domSeq && domViewer) {
                const conW = domViewer.clientWidth;
                this.ml = (conW - domSeq.clientWidth) * 0.5;
            }
        },
        getSelectionStart(e) {
            if (this.disabledSelectSearch) {
                return;
            }
            this.searchBtnVisible = 0;
            const domSeq = this.getSeqAreaDom();
            const pos = domSeq.getBoundingClientRect();
            this.startTop = e.clientY - pos.top - 45;
            this.startLeft = e.clientX - pos.left - 20;
        },
        getSelectionEnd(e) {
            if (this.disabledSelectSearch) {
                return;
            }
            const selection = window.getSelection();
            const selText = selection
                .toString()
                .split('\n')
                .join('').replace(/\s/g, '');
            if (!selText.length) {
                return;
            }
            const domSeq = this.getSeqAreaDom();
            const pos = domSeq.getBoundingClientRect();
            this.endTop = e.clientY - pos.top - 45;
            this.endLeft = e.clientX - pos.left - 20;

            // mousedown和up在同一个点 判断为未选择文本
            if (this.endTop === this.startTop && this.startLeft === this.endLeft) {
                return;
            }
            const row = selection.anchorNode.parentNode.getAttribute('data-index');
            // 计算选择方向 比如反选
            const dir = this.calSelectDirection(this.startTop, this.startLeft, this.endTop, this.endLeft);
            const start = row * this.segmentLength + this.calStartWord(selection, dir) + (dir ? 1 : 0);
            const end = dir ? start + selText.length - 1 : start - selText.length + 1;

            this.startPos = start.toString();
            this.endPos = end.toString();
            // 考虑双击选中的情况
            // 距离过近如何处理 重叠重新计算
            // 查询按钮
            this.searchTop = e.clientY - pos.top + 5;
            this.searchLeft = e.clientX - pos.left + 5;
            // 按钮与提示 重叠的时候 下移按钮
            const maxTipTop = Math.max(this.startTop, this.endTop) + 40;
            this.searchTop = this.searchTop > maxTipTop ? this.searchTop : maxTipTop + 10;
            // 判断是否触底
            this.searchTop = this.searchTop > (domSeq.clientHeight - 35) ? this.searchTop - 20 : this.searchTop;

            this.searchBtnVisible = 1;

            // 只有一个字符只显示一个位置提示
            if (selText.length === 1) {
                this.endTipVisible = 0;
            }
        },
        // 计算划选的起始字符位置 排除空格
        calStartWord(selection, dir) {
            let { anchorOffset } = selection;
            // 以空格开头的 +1 就当没有划到空格
            if (selection.toString().substr(0, 1) === ' ') {
                anchorOffset += 1;
            }
            let spaceNum;
            let isRight;
            let anchorOffsetExcludeSpace;
            do {
                spaceNum = spaceNum ? spaceNum - 1 : parseInt((anchorOffset - 1) / 10, 10);
                anchorOffsetExcludeSpace = anchorOffset - spaceNum;
                isRight = dir
                    ? spaceNum === parseInt(anchorOffsetExcludeSpace / 10, 10) // 正选 70-79 是7个
                    : spaceNum === parseInt((anchorOffsetExcludeSpace - 1) / 10, 10); // 反选的时候 71-80 是7个
            } while (!isRight && spaceNum > 0);
            return anchorOffsetExcludeSpace;
        },
        cancelSelection(e) {
            // 点击选择区域外的地方 取消选中
            const domSearch = this.$el.querySelector('.search-btn');
            if (domSearch && domSearch.contains(e.target)) {
                return;
            }
            this.searchBtnVisible = 0;
        },
        calSelectDirection(startTop, startLeft, endTop, endLeft) {
            // 计算选择方向 1-正选 0-反选
            if (Math.abs(endTop - startTop) > 16) {
                // 行高
                if (endTop > startTop) {
                    return 1;
                }
                return 0;
            }
            if (endLeft > startLeft) {
                return 1;
            }
            return 0;
        },
        async search() {
            const selection = window.getSelection();
            let selText = selection.toString();
            if (!selText.length) {
                return;
            }
            selText = selText.replace(/\s/g, '');
            this.$emit('search-sequence', {
                selText,
                sequenceType: this.sequenceType,
            });
        },
        handleSearchDomain(e) {
            if (e.target.className === 'highlight-hover-tooltip__inner') {
                const light = this.activeHighlight[parseInt(e.target.getAttribute('data-no'), 10)];
                const selText = this.sequence.substr(light.start - 1, light.end - light.start + 1);
                this.$emit('search-sequence', {
                    selText,
                    sequenceType: this.sequenceType,
                });
            }
        },
        findDataNode(el) {
            let current = el;
            try {
                do {
                    if (current.hasAttribute && current.hasAttribute('data-no')) {
                        return current;
                    }
                    if (current.classList.contains('sequences-container')) {
                        return null;
                    }
                    current = current.parentElement;
                } while (current);
            } catch (e) {
                // noop
            }
            return null;
        },
        getDataNodeOptions(target) {
            const opt = {};
            const getOption = (el) => {
                try {
                    const keys = Object.keys(el.dataset);
                    keys.forEach((k) => {
                        if (k.startsWith('highlight')) {
                            if (k in opt) {
                                opt[k] += `,${el.dataset[k]}`;
                            } else {
                                opt[k] = el.dataset[k];
                            }
                        } else if (k === 'no') {
                            opt.location = toNumber(el.dataset[k]);
                            opt.no = opt.location;
                        }
                    });
                    const kids = el.children ? [...el.children] : [];
                    if (kids.length > 0) {
                        kids.forEach((kid) => getOption(kid));
                    }
                } catch (e) {
                    // skip
                }
            };
            getOption(target);
            return opt;
        },
        handleMouseEnter(e) {
            const bindDom = this.findDataNode(e.target);
            if (bindDom) {
                const options = this.getDataNodeOptions(bindDom);
                if (options && options.highlightTarget) {
                    this.hoverOptions = options;
                    this.popoverReference = bindDom;
                    this.$nextTick(() => {
                        this.$refs.popover.updatePopper();
                        this.popoverVisible = true;
                    });
                }
            }
        },
    },
};
</script>
