import { ILang } from '../interface/lang';
import { ERROR_TOKEN_TYPE, IErrorMap } from '../interface/error';

/**
 * Represents the whitespace in the input string.
 */
export const TOKEN_WHITESPACE = 1;

/**
 * Combines two adjoining elements with logical AND.
 */
export const TOKEN_LOGICAL_AND = 2;

/**
 * Combines two adjoining elements with logical OR.
 */
export const TOKEN_LOGICAL_OR = 4;

/**
 * Applies logical NOT to the next (right-side) element.
 */
export const TOKEN_LOGICAL_NOT = 8;

/**
 * Applies logical NOT to the next (right-side) element.
 *
 * This is an alternative to the TOKEN_LOGICAL_NOT, with the difference that
 * parser will expect it's placed next (left) to the element it applies to,
 * without the whitespace in between.
 */
export const TOKEN_LOGICAL_NOT_2 = 16;

/**
 * Mandatory operator applies to the next (right-side) element and means
 * that the element must be present. There must be no whitespace between it
 * and the element it applies to.
 */
export const TOKEN_MANDATORY = 32;

/**
 * Prohibited operator applies to the next (right-side) element and means
 * that the element must not be present. There must be no whitespace between
 * it and the element it applies to.
 */
export const TOKEN_PROHIBITED = 64;

/**
 * Left side delimiter of a group.
 *
 * Group is used to group elements in order to form a sub-query.
 *
 */
export const TOKEN_GROUP_BEGIN = 128;

/**
 * Right side delimiter of a group.
 *
 * Group is used to group elements in order to form a sub-query.
 */
export const TOKEN_GROUP_END = 256;

/**
 * Term token type represents a category of term type tokens.
 *
 * This type is intended to be used as an extension point through subtyping.
 *
 */
export const TOKEN_TERM = 512;

/**
 * Bailout token.
 *
 * If token could not be recognized, next character is extracted into a
 * token of this type. Ignored by parser.
 */
export const TOKEN_BAILOUT = 1024;

/**
 * Parser ignored adjacent unary operator preceding another operator.
 */
export const CORRECTION_ADJACENT_UNARY_OPERATOR_PRECEDING_OPERATOR_IGNORED = 0;

/**
 * Parser ignored unary operator missing an operand.
 */
export const CORRECTION_UNARY_OPERATOR_MISSING_OPERAND_IGNORED = 1;

/**
 * Parser ignored binary operator missing left side operand.
 */
export const CORRECTION_BINARY_OPERATOR_MISSING_LEFT_OPERAND_IGNORED = 2;

/**
 * Parser ignored binary operator missing right side operand.
 */
export const CORRECTION_BINARY_OPERATOR_MISSING_RIGHT_OPERAND_IGNORED = 3;

/**
 * Parser ignored binary operator following another operator and connecting operators.
 */
export const CORRECTION_BINARY_OPERATOR_FOLLOWING_OPERATOR_IGNORED = 4;

/**
 * Parser ignored logical not operators preceding mandatory or prohibited operator.
 */
export const CORRECTION_LOGICAL_NOT_OPERATORS_PRECEDING_PREFERENCE_IGNORED = 5;

/**
 * Parser ignored empty group and connecting operators.
 */
export const CORRECTION_EMPTY_GROUP_IGNORED = 6;

/**
 * Parser ignored unmatched left side group delimiter.
 */
export const CORRECTION_UNMATCHED_GROUP_LEFT_DELIMITER_IGNORED = 7;

/**
 * Parser ignored unmatched right side group delimiter.
 */
export const CORRECTION_UNMATCHED_GROUP_RIGHT_DELIMITER_IGNORED = 8;

/**
 * Parser ignored bailout type token.
 *
 */
export const CORRECTION_BAILOUT_TOKEN_IGNORED = 9;

/********************add by fanjiarong */

export const CORRECTION_UN_SUPPORT_DOMAIN = 10;

export const CORRECTION_UN_SUPPORT_EMPTY_PHRASE = 11;

export const CORRECTION_UN_SUPPORT_LOGIC_NOT = 12;

export const CORRECTION_UN_SUPPORT_WORD_LENGTH = 13;

export const CORRECTION_UN_SUPPORT_FIRST_NOT_LOGICAL = 14;

export const CORRECTION_UN_SUPPORT_NESTED_GROUP = 15;

export const CORRECTION_UN_SUPPORT_WORDS_WITHOUT_PHRASE = 16;
/********************错误文案 */
export const CN_ERROR_MAP: IErrorMap = {
  [ERROR_TOKEN_TYPE.ADJACENT_UNARY_OPERATOR_PRECEDING_OPERATOR_IGNORED]:
    '不能连续出现多个运算符',
  [ERROR_TOKEN_TYPE.UNARY_OPERATOR_MISSING_OPERAND_IGNORED]: '运算符缺少操作数',
  [ERROR_TOKEN_TYPE.BINARY_OPERATOR_MISSING_LEFT_OPERAND_IGNORED]:
    '逻辑词缺少操作数',
  [ERROR_TOKEN_TYPE.BINARY_OPERATOR_MISSING_RIGHT_OPERAND_IGNORED]:
    '逻辑词缺少操作数',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_CONSECUTIVE_LOGICAL_WORDS]:
    '不能连续出现多个逻辑词',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_END_WITH_LOGICAL_WORDS]: '不能以逻辑词结尾',
  [ERROR_TOKEN_TYPE.BAILOUT_TOKEN_IGNORED]: '缺少"',
  [ERROR_TOKEN_TYPE.LOGICAL_NOT_OPERATORS_PRECEDING_PREFERENCE_IGNORED]:
    '暂不支持',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_USELESS_LOGICAL_WORDS]: '无效的逻辑词',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_EXTRA_PARENTHESES]: '多余的符号:()',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_MISSING_CLOSING_PARENTHESIS]: '缺少符号:)',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_EXTRA_CLOSING_PARENTHESIS]: '多余符号:)',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_DOMAIN]: '暂不支持domain',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_EMPTY_PHRASE]: '字段值为空',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_LOGIC_NOT]: '暂不支持NOT',
  [ERROR_TOKEN_TYPE.UN_SUPPORT]: '暂不支持',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_WORD_LENGTH]: '搜索词至少两个字符',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_FIRST_NOT_LOGICAL]: '首个逻辑词不合法',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_NESTED_GROUP]: '暂不支持嵌套',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_WORDS_WITHOUT_PHRASE]: '缺少""'
};

export const EN_ERROR_MAP: IErrorMap = {
  [ERROR_TOKEN_TYPE.ADJACENT_UNARY_OPERATOR_PRECEDING_OPERATOR_IGNORED]:
    'Multiple operators cannot occur consecutively',
  [ERROR_TOKEN_TYPE.UNARY_OPERATOR_MISSING_OPERAND_IGNORED]:
    'Operator missing operands',
  [ERROR_TOKEN_TYPE.BINARY_OPERATOR_MISSING_LEFT_OPERAND_IGNORED]:
    'Operator missing left operands',
  [ERROR_TOKEN_TYPE.BINARY_OPERATOR_MISSING_RIGHT_OPERAND_IGNORED]:
    'Operator missing right operands',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_CONSECUTIVE_LOGICAL_WORDS]:
    'Multiple logical words cannot occur consecutively',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_END_WITH_LOGICAL_WORDS]:
    'Cannot end in a logical word',
  [ERROR_TOKEN_TYPE.BAILOUT_TOKEN_IGNORED]: 'Missing:"',
  [ERROR_TOKEN_TYPE.LOGICAL_NOT_OPERATORS_PRECEDING_PREFERENCE_IGNORED]:
    'Temporarily not support',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_USELESS_LOGICAL_WORDS]: 'Invalid logical words',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_EXTRA_PARENTHESES]: 'Extra parentheses',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_MISSING_CLOSING_PARENTHESIS]:
    'Missing parentheses:)',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_EXTRA_CLOSING_PARENTHESIS]:
    'Extra parentheses:)',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_DOMAIN]: 'Domain is not supported',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_EMPTY_PHRASE]: 'Empty phrase',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_LOGIC_NOT]: 'NOT is not supported',
  [ERROR_TOKEN_TYPE.UN_SUPPORT]: 'Temporarily not support',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_WORD_LENGTH]:
    'At least two characters for the search term',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_FIRST_NOT_LOGICAL]: 'Invalid first logical word',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_NESTED_GROUP]: 'Nested is not supported',
  [ERROR_TOKEN_TYPE.UN_SUPPORT_WORDS_WITHOUT_PHRASE]: 'Missing:""',
};

export const ERROR_MAP: Record<ILang, IErrorMap> = {
  cn: CN_ERROR_MAP,
  en: EN_ERROR_MAP,
};
