interface ICountryFlagItem {
    display_name: string
    alpha_2_code: string
}
export const COUNTRY_FLAG_ARY: ICountryFlagItem[] = [
    { display_name: 'Afghanistan', alpha_2_code: 'AF' },
    { display_name: 'Åland Islands', alpha_2_code: 'AX' },
    { display_name: 'Albania', alpha_2_code: 'AL' },
    { display_name: 'Algeria', alpha_2_code: 'DZ' },
    { display_name: 'American Samoa', alpha_2_code: 'AS' },
    { display_name: 'Andorra', alpha_2_code: 'AD' },
    { display_name: 'Angola', alpha_2_code: 'AO' },
    { display_name: 'Anguilla', alpha_2_code: 'AI' },
    { display_name: 'Antarctica', alpha_2_code: 'AQ' },
    { display_name: 'Antigua and Barbuda', alpha_2_code: 'AG' },
    { display_name: 'Argentina', alpha_2_code: 'AR' },
    { display_name: 'Armenia', alpha_2_code: 'AM' },
    { display_name: 'Aruba', alpha_2_code: 'AW' },
    { display_name: 'Australia', alpha_2_code: 'AU' },
    { display_name: 'Austria', alpha_2_code: 'AT' },
    { display_name: 'Azerbaijan', alpha_2_code: 'AZ' },
    { display_name: 'The Bahamas', alpha_2_code: 'BS' },
    { display_name: 'Bahrain', alpha_2_code: 'BH' },
    { display_name: 'Bangladesh', alpha_2_code: 'BD' },
    { display_name: 'Barbados', alpha_2_code: 'BB' },
    { display_name: 'Belarus', alpha_2_code: 'BY' },
    { display_name: 'Belgium', alpha_2_code: 'BE' },
    { display_name: 'Belize', alpha_2_code: 'BZ' },
    { display_name: 'Benin', alpha_2_code: 'BJ' },
    { display_name: 'Bermuda', alpha_2_code: 'BM' },
    { display_name: 'Bhutan', alpha_2_code: 'BT' },
    { display_name: 'Bolivia', alpha_2_code: 'BO' },
    { display_name: 'Caribbean Netherlands', alpha_2_code: 'BQ' },
    { display_name: 'Bosnia and Herzegovina', alpha_2_code: 'BA' },
    { display_name: 'Botswana', alpha_2_code: 'BW' },
    { display_name: 'Bouvet Island', alpha_2_code: 'BV' },
    { display_name: 'Brazil', alpha_2_code: 'BR' },
    { display_name: 'British Indian Ocean Territory', alpha_2_code: 'IO' },
    { display_name: 'Brunei', alpha_2_code: 'BN' },
    { display_name: 'Bulgaria', alpha_2_code: 'BG' },
    { display_name: 'Burkina Faso', alpha_2_code: 'BF' },
    { display_name: 'Burundi', alpha_2_code: 'BI' },
    { display_name: 'Cape Verde', alpha_2_code: 'CV' },
    { display_name: 'Cambodia', alpha_2_code: 'KH' },
    { display_name: 'Cameroon', alpha_2_code: 'CM' },
    { display_name: 'Canada', alpha_2_code: 'CA' },
    { display_name: 'Cayman Islands', alpha_2_code: 'KY' },
    { display_name: 'Central African Republic', alpha_2_code: 'CF' },
    { display_name: 'Chad', alpha_2_code: 'TD' },
    { display_name: 'Chile', alpha_2_code: 'CL' },
    { display_name: 'China', alpha_2_code: 'CN' },
    { display_name: 'Christmas Island', alpha_2_code: 'CX' },
    { display_name: 'Cocos (Keeling) Islands', alpha_2_code: 'CC' },
    { display_name: 'Colombia', alpha_2_code: 'CO' },
    { display_name: 'Comoros', alpha_2_code: 'KM' },
    { display_name: 'Republic of the Congo', alpha_2_code: 'CG' },
    { display_name: 'Cook Islands', alpha_2_code: 'CK' },
    { display_name: 'Costa Rica', alpha_2_code: 'CR' },
    { display_name: 'Côte d\'Ivoire', alpha_2_code: 'CI' },
    { display_name: 'Croatia', alpha_2_code: 'HR' },
    { display_name: 'Cuba', alpha_2_code: 'CU' },
    { display_name: 'Curaçao', alpha_2_code: 'CW' },
    { display_name: 'Cyprus', alpha_2_code: 'CY' },
    { display_name: 'Czechia', alpha_2_code: 'CZ' },
    { display_name: 'North Korea', alpha_2_code: 'KP' },
    { display_name: 'Democratic Republic of the Congo', alpha_2_code: 'CD' },
    { display_name: 'Denmark', alpha_2_code: 'DK' },
    { display_name: 'Djibouti', alpha_2_code: 'DJ' },
    { display_name: 'Dominica', alpha_2_code: 'DM' },
    { display_name: 'Dominican Republic', alpha_2_code: 'DO' },
    { display_name: 'Ecuador', alpha_2_code: 'EC' },
    { display_name: 'Egypt', alpha_2_code: 'EG' },
    { display_name: 'El Salvador', alpha_2_code: 'SV' },
    { display_name: 'Equatorial Guinea', alpha_2_code: 'GQ' },
    { display_name: 'Eritrea', alpha_2_code: 'ER' },
    { display_name: 'Estonia', alpha_2_code: 'EE' },
    { display_name: 'Ethiopia', alpha_2_code: 'ET' },
    { display_name: 'European Union', alpha_2_code: 'EU' },
    { display_name: 'Falkland Islands', alpha_2_code: 'FK' },
    { display_name: 'Faroe Islands', alpha_2_code: 'FO' },
    { display_name: 'Fiji', alpha_2_code: 'FJ' },
    { display_name: 'Finland', alpha_2_code: 'FI' },
    { display_name: 'France', alpha_2_code: 'FR' },
    { display_name: 'French Guiana', alpha_2_code: 'GF' },
    { display_name: 'French Polynesia', alpha_2_code: 'PF' },
    { display_name: 'French Southern and Antarctic Lands', alpha_2_code: 'TF' },
    { display_name: 'Gabon', alpha_2_code: 'GA' },
    { display_name: 'Gambia', alpha_2_code: 'GM' },
    { display_name: 'Georgia', alpha_2_code: 'GE' },
    { display_name: 'Germany', alpha_2_code: 'DE' },
    { display_name: 'Ghana', alpha_2_code: 'GH' },
    { display_name: 'Gibraltar', alpha_2_code: 'GI' },
    { display_name: 'Greece', alpha_2_code: 'GR' },
    { display_name: 'Greenland', alpha_2_code: 'GL' },
    { display_name: 'Grenada', alpha_2_code: 'GD' },
    { display_name: 'Guadeloupe', alpha_2_code: 'GP' },
    { display_name: 'Guam', alpha_2_code: 'GU' },
    { display_name: 'Guatemala', alpha_2_code: 'GT' },
    { display_name: 'Guernsey', alpha_2_code: 'GG' },
    { display_name: 'Guinea', alpha_2_code: 'GN' },
    { display_name: 'Guinea-Bissau', alpha_2_code: 'GW' },
    { display_name: 'Guyana', alpha_2_code: 'GY' },
    { display_name: 'Haiti', alpha_2_code: 'HT' },
    { display_name: 'Heard Island and McDonald Islands', alpha_2_code: 'HM' },
    { display_name: 'Holy See', alpha_2_code: 'VA' },
    { display_name: 'Honduras', alpha_2_code: 'HN' },
    { display_name: 'Hong Kong', alpha_2_code: 'HK' },
    { display_name: 'Hungary', alpha_2_code: 'HU' },
    { display_name: 'Iceland', alpha_2_code: 'IS' },
    { display_name: 'India', alpha_2_code: 'IN' },
    { display_name: 'Indonesia', alpha_2_code: 'ID' },
    { display_name: 'Iran', alpha_2_code: 'IR' },
    { display_name: 'Iraq', alpha_2_code: 'IQ' },
    { display_name: 'Ireland', alpha_2_code: 'IE' },
    { display_name: 'Isle of Man', alpha_2_code: 'IM' },
    { display_name: 'Israel', alpha_2_code: 'IL' },
    { display_name: 'Italy', alpha_2_code: 'IT' },
    { display_name: 'Jamaica', alpha_2_code: 'JM' },
    { display_name: 'Japan', alpha_2_code: 'JP' },
    { display_name: 'Jersey', alpha_2_code: 'JE' },
    { display_name: 'Jordan', alpha_2_code: 'JO' },
    { display_name: 'Kazakhstan', alpha_2_code: 'KZ' },
    { display_name: 'Kenya', alpha_2_code: 'KE' },
    { display_name: 'Kiribati', alpha_2_code: 'KI' },
    { display_name: 'Kuwait', alpha_2_code: 'KW' },
    { display_name: 'Kyrgyzstan', alpha_2_code: 'KG' },
    { display_name: 'Laos', alpha_2_code: 'LA' },
    { display_name: 'Latvia', alpha_2_code: 'LV' },
    { display_name: 'Lebanon', alpha_2_code: 'LB' },
    { display_name: 'Lesotho', alpha_2_code: 'LS' },
    { display_name: 'Liberia', alpha_2_code: 'LR' },
    { display_name: 'Libya', alpha_2_code: 'LY' },
    { display_name: 'Liechtenstein', alpha_2_code: 'LI' },
    { display_name: 'Lithuania', alpha_2_code: 'LT' },
    { display_name: 'Luxembourg', alpha_2_code: 'LU' },
    { display_name: 'Macao', alpha_2_code: 'MO' },
    { display_name: 'Madagascar', alpha_2_code: 'MG' },
    { display_name: 'Malawi', alpha_2_code: 'MW' },
    { display_name: 'Malaysia', alpha_2_code: 'MY' },
    { display_name: 'Maldives', alpha_2_code: 'MV' },
    { display_name: 'Mali', alpha_2_code: 'ML' },
    { display_name: 'Malta', alpha_2_code: 'MT' },
    { display_name: 'Marshall Islands', alpha_2_code: 'MH' },
    { display_name: 'Martinique', alpha_2_code: 'MQ' },
    { display_name: 'Mauritania', alpha_2_code: 'MR' },
    { display_name: 'Mauritius', alpha_2_code: 'MU' },
    { display_name: 'Mayotte', alpha_2_code: 'YT' },
    { display_name: 'Mexico', alpha_2_code: 'MX' },
    { display_name: 'Federated States of Micronesia', alpha_2_code: 'FM' },
    { display_name: 'Monaco', alpha_2_code: 'MC' },
    { display_name: 'Mongolia', alpha_2_code: 'MN' },
    { display_name: 'Montenegro', alpha_2_code: 'ME' },
    { display_name: 'Montserrat', alpha_2_code: 'MS' },
    { display_name: 'Morocco', alpha_2_code: 'MA' },
    { display_name: 'Mozambique', alpha_2_code: 'MZ' },
    { display_name: 'Myanmar (Burma)', alpha_2_code: 'MM' },
    { display_name: 'Namibia', alpha_2_code: 'NA' },
    { display_name: 'Nauru', alpha_2_code: 'NR' },
    { display_name: 'Nepal', alpha_2_code: 'NP' },
    { display_name: 'Netherlands', alpha_2_code: 'NL' },
    { display_name: 'New Caledonia', alpha_2_code: 'NC' },
    { display_name: 'New Zealand', alpha_2_code: 'NZ' },
    { display_name: 'Nicaragua', alpha_2_code: 'NI' },
    { display_name: 'Niger', alpha_2_code: 'NE' },
    { display_name: 'Nigeria', alpha_2_code: 'NG' },
    { display_name: 'Niue', alpha_2_code: 'NU' },
    { display_name: 'Norfolk Island', alpha_2_code: 'NF' },
    { display_name: 'Northern Mariana Islands', alpha_2_code: 'MP' },
    { display_name: 'Norway', alpha_2_code: 'NO' },
    { display_name: 'Oman', alpha_2_code: 'OM' },
    { display_name: 'Pakistan', alpha_2_code: 'PK' },
    { display_name: 'Palau', alpha_2_code: 'PW' },
    { display_name: 'Palestine', alpha_2_code: 'PS' },
    { display_name: 'Panama', alpha_2_code: 'PA' },
    { display_name: 'Papua New Guinea', alpha_2_code: 'PG' },
    { display_name: 'Paraguay', alpha_2_code: 'PY' },
    { display_name: 'Peru', alpha_2_code: 'PE' },
    { display_name: 'Philippines', alpha_2_code: 'PH' },
    { display_name: 'Pitcairn Islands', alpha_2_code: 'PN' },
    { display_name: 'Poland', alpha_2_code: 'PL' },
    { display_name: 'Portugal', alpha_2_code: 'PT' },
    { display_name: 'Puerto Rico', alpha_2_code: 'PR' },
    { display_name: 'Qatar', alpha_2_code: 'QA' },
    { display_name: 'South Korea', alpha_2_code: 'KR' },
    { display_name: 'Moldova', alpha_2_code: 'MD' },
    { display_name: 'Réunion', alpha_2_code: 'RE' },
    { display_name: 'Romania', alpha_2_code: 'RO' },
    { display_name: 'Russia', alpha_2_code: 'RU' },
    { display_name: 'Rwanda', alpha_2_code: 'RW' },
    { display_name: 'Saint Barthélemy', alpha_2_code: 'BL' },
    { display_name: 'Saint Helena, Ascension and Tristan da Cunha', alpha_2_code: 'SH' },
    { display_name: 'Saint Kitts and Nevis', alpha_2_code: 'KN' },
    { display_name: 'Saint Lucia', alpha_2_code: 'LC' },
    { display_name: 'Saint Martin', alpha_2_code: 'MF' },
    { display_name: 'Saint Pierre and Miquelon', alpha_2_code: 'PM' },
    { display_name: 'Saint Vincent and the Grenadines', alpha_2_code: 'VC' },
    { display_name: 'Samoa', alpha_2_code: 'WS' },
    { display_name: 'San Marino', alpha_2_code: 'SM' },
    { display_name: 'São Tomé and Príncipe', alpha_2_code: 'ST' },
    { display_name: 'Saudi Arabia', alpha_2_code: 'SA' },
    { display_name: 'Senegal', alpha_2_code: 'SN' },
    { display_name: 'Serbia', alpha_2_code: 'RS' },
    { display_name: 'Seychelles', alpha_2_code: 'SC' },
    { display_name: 'Sierra Leone', alpha_2_code: 'SL' },
    { display_name: 'Singapore', alpha_2_code: 'SG' },
    { display_name: 'Sint Maarten', alpha_2_code: 'SX' },
    { display_name: 'Slovakia', alpha_2_code: 'SK' },
    { display_name: 'Slovenia', alpha_2_code: 'SI' },
    { display_name: 'Solomon Islands', alpha_2_code: 'SB' },
    { display_name: 'Somalia', alpha_2_code: 'SO' },
    { display_name: 'South Africa', alpha_2_code: 'ZA' },
    { display_name: 'South Georgia and the South Sandwich Islands', alpha_2_code: 'GS' },
    { display_name: 'South Sudan', alpha_2_code: 'SS' },
    { display_name: 'Spain', alpha_2_code: 'ES' },
    { display_name: 'Sri Lanka', alpha_2_code: 'LK' },
    { display_name: 'Sudan', alpha_2_code: 'SD' },
    { display_name: 'Suriname', alpha_2_code: 'SR' },
    { display_name: 'Svalbard and Jan Mayen', alpha_2_code: 'SJ' },
    { display_name: 'Eswatini', alpha_2_code: 'SZ' },
    { display_name: 'Sweden', alpha_2_code: 'SE' },
    { display_name: 'Switzerland', alpha_2_code: 'CH' },
    { display_name: 'Syria', alpha_2_code: 'SY' },
    { display_name: 'Taiwan Province', alpha_2_code: 'TW' },
    { display_name: 'Tajikistan', alpha_2_code: 'TJ' },
    { display_name: 'Thailand', alpha_2_code: 'TH' },
    { display_name: 'North Macedonia', alpha_2_code: 'MK' },
    { display_name: 'Timor-Leste', alpha_2_code: 'TL' },
    { display_name: 'Togo', alpha_2_code: 'TG' },
    { display_name: 'Tokelau', alpha_2_code: 'TK' },
    { display_name: 'Tonga', alpha_2_code: 'TO' },
    { display_name: 'Trinidad and Tobago', alpha_2_code: 'TT' },
    { display_name: 'Tunisia', alpha_2_code: 'TN' },
    { display_name: 'Turkey', alpha_2_code: 'TR' },
    { display_name: 'Turkmenistan', alpha_2_code: 'TM' },
    { display_name: 'Turks and Caicos Islands', alpha_2_code: 'TC' },
    { display_name: 'Tuvalu', alpha_2_code: 'TV' },
    { display_name: 'Uganda', alpha_2_code: 'UG' },
    { display_name: 'Ukraine', alpha_2_code: 'UA' },
    { display_name: 'United Arab Emirates', alpha_2_code: 'AE' },
    { display_name: 'United Kingdom', alpha_2_code: 'GB' },
    { display_name: 'Tanzania', alpha_2_code: 'TZ' },
    { display_name: 'United States Minor Outlying Islands', alpha_2_code: 'UM' },
    { display_name: 'United States', alpha_2_code: 'US' },
    { display_name: 'U.S. Virgin Islands', alpha_2_code: 'VI' },
    { display_name: 'Uruguay', alpha_2_code: 'UY' },
    { display_name: 'Uzbekistan', alpha_2_code: 'UZ' },
    { display_name: 'Vanuatu', alpha_2_code: 'VU' },
    { display_name: 'Venezuela', alpha_2_code: 'VE' },
    { display_name: 'Vietnam', alpha_2_code: 'VN' },
    { display_name: 'British Virgin Islands', alpha_2_code: 'VG' },
    { display_name: 'Wallis and Futuna', alpha_2_code: 'WF' },
    { display_name: 'Western Sahara', alpha_2_code: 'EH' },
    { display_name: 'Yemen', alpha_2_code: 'YE' },
    { display_name: 'Zambia', alpha_2_code: 'ZM' },
    { display_name: 'Zimbabwe', alpha_2_code: 'ZW' },
    { display_name: 'ARIPO', alpha_2_code: 'AP' },
    { display_name: 'OAPI', alpha_2_code: 'OA' },
    { display_name: 'Soviet Union', alpha_2_code: 'SU' },
    { display_name: 'Czech Slovak Rep.', alpha_2_code: 'CS' },
    { display_name: 'East Germany', alpha_2_code: 'DD' },
    { display_name: 'EAPO', alpha_2_code: 'EA' },
    { display_name: 'WIPO', alpha_2_code: 'WO' },
    { display_name: 'EPO', alpha_2_code: 'EP' },
    { display_name: 'Yugoslavia', alpha_2_code: 'YU' },
    { display_name: 'GCC', alpha_2_code: 'GC' },
    { display_name: 'Benelux', alpha_2_code: 'BX' },
    { display_name: 'EM', alpha_2_code: 'EM' },
];
export function transformCountryNameToClass(name: string): string {
    const formatName = name.replace(/[\s|(|)|\-|'|,|.]/g, '_');
    return `patsnap-biz-country_flag__icon-${formatName}`;
};

export function normalizeCountryName(name: string): string {
    let countryName = name;
    if (name === 'HK,CN') countryName = 'HK';
    if (name === 'TW,CN') countryName = 'TW';

    return countryName;
}

export function getCountryAlpha2code(name: string): string {
    const countryName = normalizeCountryName(name);
    const found = COUNTRY_FLAG_ARY.find(
        (i) => i.alpha_2_code === countryName || i.display_name === countryName,
    );
    return found?.alpha_2_code;
}
