var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details-card-rander patsnap-biz-details_card" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "details-card-rander__loading" }, [
            _c("div", { staticClass: "loading__header" }, [
              _c("div", {
                class: [
                  "loading__header-left loading-block-animation ",
                  {
                    "author-image": _vm.isAuthor,
                  },
                ],
              }),
              _vm._m(0),
            ]),
            _vm.isAuthor
              ? _c("div", { staticClass: "loading__info" }, [
                  _vm._m(1),
                  _vm._m(2),
                ])
              : _c("div", { staticClass: "loading__abst" }, [
                  _c("div", {
                    staticClass:
                      "loading-block-animation loading-block--small loading__abst-content",
                  }),
                  _c("div", {
                    staticClass:
                      "loading-block-animation loading-block--small loading__abst-content",
                  }),
                  _c("div", {
                    staticClass:
                      "loading-block-animation loading-block--small loading__abst-content",
                    staticStyle: { width: "164px" },
                  }),
                ]),
          ])
        : [
            !_vm.isNoData
              ? [
                  _c("div", { staticClass: "details-card-rander__header" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "details-card-rander__header-left",
                          {
                            "card-avatar": _vm.detailsData.avatar,
                            "author-image": _vm.isAuthor,
                            "other-iamge": !_vm.isAuthor,
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "details-card-rander__header-left__inner",
                          },
                          [
                            _vm.detailsData.avatar && !_vm.avatarError
                              ? _c("div", {
                                  staticClass:
                                    "details-card-rander__header-left__avatar",
                                  style: _vm.avatarStyle,
                                })
                              : _vm._e(),
                            !_vm.detailsData.avatar || _vm.avatarError
                              ? _c("PtIcon", {
                                  staticClass:
                                    "details-card-rander__header-left__icon",
                                  attrs: {
                                    icon:
                                      _vm.type === "organization"
                                        ? "SolidCompany"
                                        : "SolidProfile",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.type === "organization" && _vm.tag
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "details-card-rander__header-left__tag",
                              },
                              [_vm._v(" " + _vm._s(_vm.tag) + " ")]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "details-card-rander__header-right" },
                      [
                        _vm._t("title", function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "details-card-rander__header-right__link",
                                style: _vm.titleMultiLineStyle,
                              },
                              [
                                _vm.detailsData.href
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.detailsData.href,
                                          target: "_blank",
                                          title: _vm.detailsData.title,
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.detailsData.title))]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        attrs: { title: _vm.detailsData.title },
                                      },
                                      [_vm._v(_vm._s(_vm.detailsData.title))]
                                    ),
                              ]
                            ),
                          ]
                        }),
                        _vm.showFollow
                          ? _vm._t("follow", function () {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "pt-ui-btn details-card-rander__header-right__btn small",
                                    attrs: {
                                      type: _vm.detailsData.follow
                                        ? "primary"
                                        : "stroke",
                                      disabled: _vm.followLoading,
                                      icon: _vm.detailsData.follow
                                        ? "SolidLove"
                                        : "SolidLoveHollow",
                                      size: "small",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        _vm.followHover = true
                                      },
                                      mouseleave: function ($event) {
                                        _vm.followHover = false
                                      },
                                      click: _vm.toggleFollow,
                                    },
                                  },
                                  [
                                    _c("PtIcon", {
                                      class: {
                                        "primary-color": _vm.detailsData.follow,
                                        "disabled-color":
                                          _vm.followLoading &&
                                          !_vm.detailsData.follow,
                                      },
                                      attrs: {
                                        icon: _vm.detailsData.follow
                                          ? "SolidLove"
                                          : "SolidLoveHollow",
                                      },
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.followBtnLabel) + " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm.detailsData.contentText
                    ? _c("div", { staticClass: "details-card-rander__abst" }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.detailsData.contentText),
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.detailsData.rowsList &&
                  _vm.detailsData.rowsList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "details-card-rander__info" },
                        [
                          _vm._l(
                            _vm.detailsData.rowsList,
                            function (item, index) {
                              return [
                                item.value || item.href
                                  ? _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass:
                                          "details-card-rander__info-item",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "details-card-rander__info-item__label",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.label) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "details-card-rander__info-item__content",
                                          },
                                          [
                                            item.href
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: item.href,
                                                      target: item.linkTarget
                                                        ? item.linkTarget
                                                        : "_blank",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.href))]
                                                )
                                              : _vm._e(),
                                            _vm._t(
                                              "rowListValue",
                                              function () {
                                                return [
                                                  item.value
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.value)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                              { rowListValue: item }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.$slots.topic
                    ? _c(
                        "div",
                        { staticClass: "details-card-rander__topic" },
                        [_vm._t("topic")],
                        2
                      )
                    : _vm._e(),
                ]
              : _c(
                  "div",
                  { staticClass: "details-card-rander__nodata" },
                  [
                    _c("PtIcon", {
                      staticClass: "details-card-rander__nodata-icon",
                      attrs: { icon: "SolidNoDataTip" },
                    }),
                    _c(
                      "span",
                      { staticClass: "details-card-rander__nodata-tips" },
                      [_vm._v(_vm._s(_vm.$t("PtDetailsCard.noData")))]
                    ),
                  ],
                  1
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading__header-right" }, [
      _c("div", {
        staticClass: "loading-block-animation loading-block--small",
      }),
      _c("div", {
        staticClass: "loading-block-animation loading-block--small",
        staticStyle: { "margin-top": "8px", width: "122px" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading__info-item" }, [
      _c("div", {
        staticClass:
          "loading-block-animation loading-block--small loading__info-label",
      }),
      _c("div", {
        staticClass:
          "loading-block-animation loading-block--small loading__info-content",
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading__info-item" }, [
      _c("div", {
        staticClass:
          "loading-block-animation loading-block--small loading__info-label",
      }),
      _c("div", {
        staticClass:
          "loading-block-animation loading-block--small loading__info-content",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }