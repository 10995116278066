import { TOKEN_TERM } from '../../../constants';
import { Token } from '../../../Values/Token';

/**
 * Tag term token.
 *
 */
export class Tag extends Token {
  constructor(
    lexeme: string,
    position: number,
    public marker: string,
    public tag: string,
  ) {
    super(TOKEN_TERM, lexeme, position);
  }
}
