<template>
    <div class="representative-item-container">
        <ElSelect
            v-model="ruleData.groupBy"
            popper-class="popper__no-arrow"
        >
            <ElOption
                v-for="(item, idx) in firstOptions"
                :key="idx"
                :label="$t(`app.userSetting.${item}`)"
                :value="item"
            />
        </ElSelect>
        <ElSelect
            v-if="secondOptions"
            v-model="ruleData.groupSort"
            popper-class="popper__no-arrow"
        >
            <ElOption
                v-for="(item, idx) in secondOptions"
                :key="idx"
                :label="$t(`app.userSetting.${item}`)"
                :value="item"
            />
        </ElSelect>
        <div
            v-if="ruleData.groupBy === 'AUTHORITY'"
            class="priority-selector-box"
        >
            <!-- 国家选择器：下拉框区域 -->
            <ElPopover
                transition="setting-country-list__popper_transition"
                :popper-class="['setting-country-list__popper', countryPopperClass ].join(' ')"
                trigger="manual"
                :value="isOpen"
                :visible-arrow="false"
                placement="bottom-start"
            >
                <CountryList
                    v-clickoutside="closeCountryList"
                    :country-list-config="countryListConfig"
                    :group-family="ruleData.groupFamily"
                    @updateSelectCountry="updateSelectCountry"
                />
                <span
                    slot="reference"
                    class="setting-country-list__popper-reference"
                />
            </ElPopover>
            <span
                v-for="i in countryLimit"
                :key="i"
                class="priority-selector-item"
            >
                <CoutrySelectedName
                    v-model="ruleData.groupFamily[i-1]"
                    :index="i"
                    :current-key="countryListConfig.currentKey"
                    @updateCountryListStatus="updateCountryListStatus"
                />
                <PtIcon
                    v-if="i !== countryLimit"
                    :width="20"
                    style="font-size: 20px; fill: #777;"
                    icon="SolidArrowRight"
                />
            </span>

            <ElPopover
                placement="top-end"
                :visible-arrow="false"
                width="388"
                trigger="hover"
                popper-class="user-setting__popover user-setting__popover-country"
            >
                <div class="single-panel">
                    {{ $t('app.userSetting.familyMsg') }}
                </div>
                <PtIcon
                    slot="reference"
                    icon="SolidInfo"
                    style="font-size: 24px; fill: #B3BAC5;"
                    class="hover-icon"
                />
            </ElPopover>
        </div>
    </div>
</template>
<script>

import Popover from 'element-ui/lib/popover';
import Select from 'element-ui/lib/select';
import Option from 'element-ui/lib/option';
import PtIcon from '@patsnap-biz/icon';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import get from 'lodash/get';
import slice from 'lodash/slice';
import clickoutside from 'element-ui/src/utils/clickoutside';
import {
    settingConfig,
    apnoTypeMap,
    searchCollapseTypeMap,
    usFamilyList,
    cnFamilyList,
    getLocalGroupFamily,
    setLocalGroupFamily,
} from './settingConfig';
import CoutrySelectedName from './Country/CoutrySelectedName';
import CountryList from './Country/CountryList';

const hooks = {
    settingConfig: (_) => _,
    searchCollapseTypeMap: (_) => _,
    formatCollapse: (_) => _,
    apnoTypeMap: (_) => _,
};

export default {
    components: {
        CountryList,
        PtIcon,
        ElPopover: Popover,
        ElSelect: Select,
        ElOption: Option,
        CoutrySelectedName,
    },
    directives: {
        clickoutside,
    },
    inject: {
        hooks: {
            from: 'normalizeHooks',
        },
    },
    props: {
        modelType: {
            type: String,
            default: '',
        },
        excludeField: {
            type: String,
            default: '',
        },
        groupData: {
            type: Object,
            default: () => {},
        },
        serviceArea: {
            type: String,
            default: 'cn',
        },
    },
    data() {
        this.normalizeHooks = { ...hooks, ...this.hooks };
        const localGroupFamily = getLocalGroupFamily();

        const groupBy = get(this.groupData, 'groupBy', '');
        const groupFamily = get(this.groupData, 'groupFamily', localGroupFamily);
        const groupSort = get(this.groupData, 'groupSort', '');
        const config = this.normalizeHooks.settingConfig(settingConfig);
        return {
            countryLimit: 5,
            firstOptions: [],
            secondOptions: [],
            settingConfig: config,
            apnoTypeMap: this.normalizeHooks.apnoTypeMap(apnoTypeMap),
            searchCollapseTypeMap: this.normalizeHooks.searchCollapseTypeMap(searchCollapseTypeMap),
            ruleData: {
                groupBy,
                groupFamily,
                groupSort,
            },
            countryList: config.authorityType,

            countryListCtrlConfig: {
                instance: null,
                name: '',
            },
            countryListConfig: {},
            countryPopperClass: '',
        };
    },
    computed: {
        isOpen() {
            return this.countryListConfig.currentKey > -1;
        },
    },
    watch: {
        'ruleData.groupBy': {
            immediate: true,
            handler(newVal) {
                this.initSecondOptions();
                if (newVal === 'AUTHORITY') {
                    this.initSearchFamily();
                }
            },
        },
        ruleData: {
            handler() {
                this.$emit('updateData', this.ruleData);
            },
            deep: true,
        },
        modelType() {
            this.initOptions();
        },
        excludeField() {
            this.initOptions();
        },
        'countryListConfig.currentKey': {
            immediate: true,
            handler() {
                if (this.countryListConfig.currentKey > -1) {
                    this.countryPopperClass = `setting-country-list__popper_${this.countryListConfig.currentKey - 1}`;
                }
            },
        },
    },
    created() {
        this.initOptions();
    },
    methods: {
        updateSelectCountry(country) {
            const index = this.countryListConfig.currentKey;
            if (country) {
                this.ruleData.groupFamily.splice(index - 1, 1, country);
            } else {
                this.ruleData.groupFamily = slice(this.ruleData.groupFamily, 0, index - 1);
            }
            this.closeCountryList();
            setLocalGroupFamily(this.ruleData.groupFamily.join(','));
        },
        initOptions() {
            this.initFirstOptions();
            this.initSecondOptions();
        },
        initValue() {
            switch (this.ruleData.groupBy) {
            case 'APD':
            case 'PBD':

                break;
            case 'AUTHORITY':

                break;

            default:
                break;
            }
        },
        initFirstOptions() {
            let options = [];
            if (includes(this.apnoTypeMap, this.modelType)) {
                options = this.settingConfig.apnoType;
            } else if (includes(this.searchCollapseTypeMap, this.modelType)) {
                options = filter(this.settingConfig.searchCollapseType, (item) => item !== this.excludeField);
            }
            if (!includes(options, this.ruleData.groupBy)) {
                const [groupBy] = options;
                this.ruleData.groupBy = groupBy;
            }
            this.firstOptions = options;
        },
        initSecondOptions() {
            let options = [];
            switch (this.ruleData.groupBy) {
            case 'PATENT_TYPE':
                options = this.settingConfig.patentType;
                break;
            case 'APD':
            case 'PBD':
                options = this.settingConfig.dateType;
                break;
            default:
                options = null;
                break;
            }
            this.secondOptions = options;
            if (options && !includes(options, this.ruleData.groupSort)) {
                const [groupSort] = options;
                this.ruleData.groupSort = groupSort;
            }
        },
        initSearchFamily() {
            let defaultGroupFamily = [];
            if (this.serviceArea === 'cn') {
                defaultGroupFamily = cnFamilyList;
            } else {
                defaultGroupFamily = usFamilyList;
            }
            const groupLen = get(this.ruleData, 'groupFamily.length');
            if (!groupLen) {
                this.ruleData.groupFamily = defaultGroupFamily;
            }
        },
        updateCountryListStatus(data) {
            this.countryListConfig = data;
        },
        closeCountryList() {
            this.countryListConfig.currentKey = -1;
        },

    },
};
</script>
