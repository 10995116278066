var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    _vm._b(
      {
        class: _vm.data.staticClass || "",
        attrs: {
          viewBox: "0 0 24 24",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      "svg",
      _vm.data.attrs,
      false
    ),
    [
      _vm.props.value
        ? _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M10.2679 6.16667C10.2679 5.52267 10.5964 5 11.0012 5L21.2667 5C21.6715 5 22 5.52267 22 6.16667C22 6.81067 21.6715 7.33333 21.2667 7.33333L11.0012 7.33334C10.5964 7.33334 10.2679 6.81067 10.2679 6.16667ZM4.81836 8.71467L4.70807 8.81267L2.33937 11.1903C1.92652 11.6051 1.88542 12.2595 2.24316 12.7222L2.33937 12.8318L4.70807 15.2095C5.02518 15.5282 5.49786 15.6379 5.9241 15.4919C6.35034 15.3459 6.65475 14.9699 6.70722 14.5247L6.71426 14.3882L6.71544 13.1667L20.8268 13.1667C21.4747 13.1667 22 12.6443 22 12C22 11.3557 21.4747 10.8333 20.8268 10.8333L6.71544 10.8333L6.71543 9.63167C6.71489 9.37349 6.62824 9.12279 6.46906 8.91884L6.36699 8.80217C5.94687 8.38906 5.28338 8.35107 4.81836 8.7135L4.81836 8.71467ZM11.0012 16.6667C10.5964 16.6667 10.2679 17.1893 10.2679 17.8333C10.2679 18.4773 10.5964 19 11.0012 19L21.2667 19C21.6715 19 22 18.4773 22 17.8333C22 17.1893 21.6715 16.6667 21.2667 16.6667L11.0012 16.6667Z",
            },
          })
        : _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M13.7321 6.16667C13.7321 5.52267 13.4036 5 12.9988 5L2.73326 5C2.3285 5 2 5.52267 2 6.16667C2 6.81067 2.3285 7.33333 2.73326 7.33333L12.9988 7.33334C13.4036 7.33334 13.7321 6.81067 13.7321 6.16667ZM19.1816 8.71467L19.2919 8.81267L21.6606 11.1903C22.0735 11.6051 22.1146 12.2595 21.7568 12.7222L21.6606 12.8318L19.2919 15.2095C18.9748 15.5282 18.5021 15.6379 18.0759 15.4919C17.6497 15.3459 17.3453 14.9699 17.2928 14.5247L17.2857 14.3882L17.2846 13.1667L3.17321 13.1667C2.52526 13.1667 2 12.6443 2 12C2 11.3557 2.52526 10.8333 3.17321 10.8333L17.2846 10.8333L17.2846 9.63167C17.2851 9.37349 17.3718 9.12279 17.5309 8.91884L17.633 8.80217C18.0531 8.38906 18.7166 8.35107 19.1816 8.7135L19.1816 8.71467ZM12.9988 16.6667C13.4036 16.6667 13.7321 17.1893 13.7321 17.8333C13.7321 18.4773 13.4036 19 12.9988 19L2.73325 19C2.3285 19 2 18.4773 2 17.8333C2 17.1893 2.3285 16.6667 2.73325 16.6667L12.9988 16.6667Z",
            },
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }