// service
import EmailAlertService from './common/service/EmailAlertService';

// components
import Checkbox from './common/components/Checkbox';
import AlertFrequency from './common/components/AlertFrequency';
import EmailAutoComplete from './common/components/EmailAutoComplete';
import EmailAlertDialog from './common/components/EmailAlertDialog';
import AlertCommonModule from './common/components/AlertCommonModule';

EmailAlertDialog.EmailAlertService = EmailAlertService;
EmailAlertDialog.Checkbox = Checkbox;
EmailAlertDialog.AlertFrequency = AlertFrequency;
EmailAlertDialog.EmailAutoComplete = EmailAutoComplete;
EmailAlertDialog.AlertCommonModule = AlertCommonModule;

export default EmailAlertDialog;
