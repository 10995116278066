import UserSetting from './UserSetting/UserSetting.vue';
import {
    formatUserSetting,
    formatUserSettingToReq,
    ruleToGroupData,
    settingConfig,
    formatUserSettingFromWorkspace,
} from './UserSetting/settingConfig';

UserSetting.settingConfig = {
    formatUserSetting,
    formatUserSettingToReq,
    ruleToGroupData,
    settingConfig,
    formatUserSettingFromWorkspace,
};

export default UserSetting;
