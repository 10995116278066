/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import { toLower } from 'lodash';
import BasicService from '../Basic/BasicService';

class SequenceSubjectService extends BasicService {
    // 已勾选序列
    selectedObj = {
        isExclude: false,
        excludeSeqs: [],
        slectedSeqs: [],
        selectedCount: 0,
        seqName: '',
    }

    constructor() {
        super();
        this.type = 'BIO';
        this.type_alias = 'bio';
    }

    getFolderIcon() {
        return 'SolidFolderClosed2';
    }

    updateSeqName(val) {
        this.selectedObj.seqName = val;
    }

    getSubmitApiConfig() {
        const { selectedWorkspace: { space_id }, selectedFolder: { folder_id } } = this.folderSelector;
        return {
            url: `${this.baseUrl}/space/${space_id}/folder/${folder_id}/save-common-data`,
            method: 'POST',
        };
    }

    getFolderElementsCountString({ folder }) {
        const { bio_count = 0 } = folder;
        return `(${this.formatNumber(bio_count)})`;
    }

    getWorkspaceElementsCountString(workspace) {
        const { total_bio_count = 0 } = workspace;
        const count = this.formatNumber(total_bio_count);
        return `(${count})`;
    }

    handleJumpToWorkspaceLink({
        folder_id,
        // folder_name,
        space_id,
        source_type,
        data_type,
    }) {
        const defaultWorkspaceViewType = this.type_alias || data_type || this.type || 'PATENT';
        const query = `?spaceId=${space_id}&folderId=${folder_id}&page=1&from=${source_type || ''}`;
        const base = `${this.workspaceProductDomain}/detail/${toLower(defaultWorkspaceViewType)}`;

        return base + query;
    }

    getSearchParams() {
        const params = super.getSearchParams();
        params.need_public_space = false;
        return params;
    }
}

export default SequenceSubjectService;
