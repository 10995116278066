<template>
    <div
        class="patsnap-biz-slider_range ai-common-slider-range"
        :class="[
            layoutIsHorizontal ? 'layout-is-horizontal':'layout-is-vertical',
            minInputErrorMsg || maxInputErrorMsg ? 'has-error': ''
        ]"
    >
        <!-- min一定为空0， 这里0和max是mapList的index映射， 真正的值可以从map中获取 -->
        <ElSlider
            ref="ElSlider"
            :value="value"
            :max="max"
            :min="0"
            :range="true"
            :disabled="disabled"
            :show-tooltip="showTooltip"
            @input="inputChangeRange"
        />

        <div
            class="range-input min"
            :class="{'is-error':minInputErrorMsg, 'show-unit': showUnit(typeIsMin)}"
        >
            <div
                v-if="disabled"
                class="disable-bg"
            />
            <div
                v-if="minInputErrorMsg"
                class="error-msg"
            >
                <p>{{ minInputErrorMsg }}</p>
            </div>
            <input
                ref="inputMin"
                v-model="minInput"
                type="text"
                @input="limitInput(typeIsMin)"
                @keyup.enter="updateRange(typeIsMin)"
                @blur="updateRange(typeIsMin)"
            >
            <div
                v-if="showUnit(typeIsMin)"
                class="number-unit"
            >
                {{ unit }}
            </div>
        </div>
        <div
            v-if="!layoutIsHorizontal"
            class="range-input-line"
            :class="{'disabled':disabled}"
        >
            -
        </div>
        <div
            class="range-input max"
            :class="{'is-error':maxInputErrorMsg, 'show-unit': showUnit(typeIsMax)}"
        >
            <div
                v-if="disabled"
                class="disable-bg"
            />
            <div
                v-if="maxInputErrorMsg"
                class="error-msg"
            >
                <p>{{ maxInputErrorMsg }}</p>
            </div>
            <input
                ref="inputMax"
                v-model="maxInput"
                type="text"
                :placeholder="maxPlaceholder"
                @input="limitInput(typeIsMax)"
                @keyup.enter="updateRange(typeIsMax)"
                @blur="updateRange(typeIsMax)"
            >
            <div
                v-if="showUnit(typeIsMax)"
                class="number-unit"
            >
                {{ unit }}
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-mixed-operators */
import Slider from 'element-ui/lib/slider';
import get from 'lodash/get';
import cn from '../locales/cn';
import en from '../locales/en';
import jp from '../locales/jp';
import tw from '../locales/tw';

const maxNumLength = 9;
const typeIsMin = 'min';
const typeIsMax = 'max';

export default {
    name: 'PtSliderRange',
    i18n: {
        messages: {
            cn, en, jp, tw,
        },
    },
    components: {
        ElSlider: Slider,
    },
    model: {
        prop: 'rangeModel',
        event: 'updateModelEvent',
    },
    props: {
        /**
         * 真实的数字范围
         */
        rangeModel: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        /**
         * 节点和真实值的映射关系
         */
        mapList: {
            type: Array,
            required: true,
            default: () => ([
            ]),
        },
        /**
         * 在水平或者在垂直方向显示输入框
         */
        layoutIsHorizontal: {
            type: Boolean,
            default: false,
        },
        /**
         * 数量单位
         */
        unit: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showTooltip: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            typeIsMin,
            typeIsMax,
            value: [],
            minInput: '',
            minInputErrorMsg: '',
            maxInput: '',
            maxInputErrorMsg: '',
        };
    },
    computed: {
        invalidText() {
            return this.$t('PtSliderRange.invalidTip');
        },
        max() {
            return this.mapList.length - 1;
        },
        maxPlaceholder() {
            const lastMapData = this.mapList[this.mapList.length - 1];
            const maxLabel = lastMapData.label || lastMapData.value;
            return maxLabel;
        },
    },
    watch: {
        rangeModel(newVal) {
            this.init(newVal);
        },
        disabled() {
            this.init(this.rangeModel);
        },
    },
    created() {
        this.init(this.rangeModel);
    },
    methods: {
        init(newRange) {
            const {
                range = [],
                minInput = '',
                maxInput = '',
            } = this.getConfig(newRange);
            this.value = range;
            this.minInput = minInput;
            this.maxInput = maxInput;
            this.minInputErrorMsg = '';
            this.maxInputErrorMsg = '';
        },
        // eslint-disable-next-line consistent-return
        showUnit(type) {
            if (!this.unit) return false;
            if (type === typeIsMin) {
                return String(Number(this.minInput)) !== '0';
            }
            if (type === typeIsMax) {
                return this.maxInput !== '' && String(Number(this.maxInput)) !== '0';
            }
        },
        getInputValue(index) {
            const inputVal = get(this.mapList, `[${index}].value`);
            return inputVal === '*' ? '' : inputVal;
        },
        limitInput(type) {
            let inputName;
            let errorName;
            if (type === typeIsMin) {
                inputName = 'minInput';
                errorName = 'minInputErrorMsg';
            } else {
                inputName = 'maxInput';
                errorName = 'maxInputErrorMsg';
            }
            let val = String(this[inputName]);
            val = val.replace(/[\D]/g, '');
            if (val.length > maxNumLength) {
                val = val.slice(0, maxNumLength);
            }
            this[inputName] = val;
            this[errorName] = '';
        },
        updateRange(type) {
            this.blur();

            const maxValue = this.mapList[this.mapList.length - 1].value;
            const validMinNum = parseInt(this.mapList[0].value, 10);
            const validMaxNum = maxValue === '*' ? Infinity : parseInt(maxValue, 10);

            const inputMinNum = this.minInput === '' ? -1 : parseInt(this.minInput, 10);
            const inputMaxNum = this.maxInput === '' ? Infinity : parseInt(this.maxInput, 10);

            let isValid = true;

            if (inputMinNum === -1
                || inputMinNum < validMinNum
                || inputMinNum >= inputMaxNum
                || inputMinNum >= validMaxNum) {
                isValid = false;
                if (type === typeIsMin) {
                    this.minInputErrorMsg = this.invalidText;
                }
            }
            if (
                validMaxNum !== Infinity
                && (inputMaxNum < validMinNum
                    || inputMaxNum <= inputMinNum
                    || inputMaxNum > validMaxNum
                )
                || validMaxNum === Infinity
                && (inputMaxNum < validMinNum || inputMaxNum <= inputMinNum)
            ) {
                isValid = false;
                if (type === typeIsMax) {
                    this.maxInputErrorMsg = this.invalidText;
                }
            }

            if (!isValid) return;
            this.minInputErrorMsg = '';
            this.maxInputErrorMsg = '';
            // 更新合法的数据范围， 否则报错
            const newRangeValList = [
                String(Number(this.minInput)),
                this.maxInput === '' ? '*' : String(Number(this.maxInput)),
            ];
            this.$emit('updateModelEvent', newRangeValList);
        },
        // defaultRange: 真实数值
        getConfig(defaultRange) {
            let minIndex;
            let maxIndex;
            let minInput;
            let maxInput;
            if (typeof defaultRange === 'undefined' || defaultRange.length === 0) {
                minIndex = 0;
                maxIndex = this.mapList.length - 1;

                minInput = this.mapList[minIndex].value;
                maxInput = this.mapList[maxIndex].value;
            } else {
                minInput = parseInt(defaultRange[0], 10);
                maxInput = defaultRange[1] === '*' ? '*' : parseInt(defaultRange[1], 10);
                const valueList = (this.mapList || [])
                    .map((item) => (item.value === '*' ? '*' : parseInt(item.value, 10)));
                const secondBigNum = parseInt(get(valueList, valueList.length - 2), 10);

                // 有些来源字段的数字不存在于范围节点内，就寻找数值最近的一个node节点， 只有'*'才能匹配最大值
                let minNumTarget;
                if (minInput > secondBigNum && valueList[valueList.length - 1] === '*') {
                    minNumTarget = secondBigNum;
                } else {
                    minNumTarget = valueList.includes(minInput)
                        ? minInput
                        : [...valueList]
                            .sort((a, b) => Math.abs(a - minInput) - Math.abs(b - minInput))[0];
                }
                minIndex = valueList.indexOf(minNumTarget);

                let maxNumTarget;
                if (maxInput === '*') {
                    maxNumTarget = maxInput;
                } else if (valueList.length === 2) {
                    [, maxNumTarget] = valueList;
                } else if (maxInput > secondBigNum && valueList[valueList.length - 1] === '*') {
                    maxNumTarget = secondBigNum;
                } else {
                    maxNumTarget = valueList.includes(maxInput)
                        ? maxInput
                        : [...valueList]
                            .sort((a, b) => Math.abs(a - maxInput) - Math.abs(b - maxInput))[0];
                }
                maxIndex = valueList.indexOf(maxNumTarget);
            }
            return {
                range: [minIndex, maxIndex],
                minInput: String(minInput),
                maxInput: maxInput === '*' ? '' : String(maxInput),
            };
        },
        blur() {
            this.$refs.inputMax.blur();
            this.$refs.inputMin.blur();
        },
        inputChangeRange(newRange) {
            this.blur();

            // 只有不同的节点或者不同于旧数据才会更新
            const minIndex = get(newRange, '[0]', 0);
            const maxIndex = get(newRange, '[1]', 0);
            const newMinVal = String(get(this.mapList, `[${minIndex}].value`));
            const newMaxVal = String(get(this.mapList, `[${maxIndex}].value`));

            const oldMinIndex = get(this.value, '[0]', 0);
            const oldMaxIndex = get(this.value, '[1]', 0);
            const oldMinVal = String(get(this.mapList, `[${oldMinIndex}].value`));
            const oldMaxVal = String(get(this.mapList, `[${oldMaxIndex}].value`));

            if (newMinVal !== newMaxVal && (newMinVal !== oldMinVal || newMaxVal !== oldMaxVal)) {
                this.$emit('updateModelEvent', [newMinVal, newMaxVal]);
            }
        },
    },
};
</script>
