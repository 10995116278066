interface Item  {
    key:string,
    label?: string,
    icon?:string,
}
interface Product extends Item {
    icon:string,
    link?:string
}
interface FunctionLink extends Item {
    children?: FunctionLink[]
}

export default class NavService {
    public products: Product[];
    private _isWide: boolean = false;
    public currentActiveKey: Item['key'];
    // 子被展开的项
    public lv2ExpandedList: Item["key"][] = [];
    public functionLinks: FunctionLink[];
    public partitions: (Product | FunctionLink | string)[];
    public allowMultiExpand: boolean = true;
    public onlyLv2Link: boolean = true;
    public tooltipTransition:string = 'el-fade-in-linear';
    // 是否在非wide模式下的图标下方显示label
    public showLabelUnderIcon = false
    static getDefProducts():Product[] {
        return [
        ];
    }

    static getDefFunctionLinks():FunctionLink[] {
        return [
            {
                key: 'workspace',
                icon: 'SolidWorkspace',
                children: [
                    {key: 'my_workspace'},
                    {key: 'shared_workspace'},
                    {key: 'public_workspace'}
                ]
            },
            { key: 'dashboard', icon: 'SolidChartDonut2' },
            { key: 'alert', icon: 'SolidEmailAlerts' },
        ];
    }

    constructor() {
        this.products = NavService.getDefProducts();
        this.functionLinks = NavService.getDefFunctionLinks();
        this.currentActiveKey = 'home'
        this.partitions = [...this.functionLinks, 'Tools', ...this.products]
    }

    public $t(str:string):string {
        return str
    }

    // @ts-ignore
    public toggleWideHook(bol:boolean) {}
    get isWide():boolean {
        return this._isWide
    }
    set isWide(bol) {
        if(bol !== this._isWide) {
            this.toggleWideHook(bol);
            this._isWide = bol
        }
    }

    public onClickedLinkHook(key:Item['key'], product:Product) {
        console.log(`Clicked: `, key, product)
    }

    public toggleExpandLv2(key:Item['key'], bol?:boolean) {
        const targetItemModel = getFlattedItemModels(this.partitions)
            .filter(itemModel => itemModel.key === key)
        const parentKey = targetItemModel[0]?.parent?.key || key

        const _bol = bol === undefined ? !this.isExpanded(parentKey) : bol;

        if(_bol) {
            if(!this.allowMultiExpand) {
                this.lv2ExpandedList = [];
            }
            this.lv2ExpandedList.push(parentKey)
        } else {
            this.lv2ExpandedList = this.lv2ExpandedList.filter(str => str !== parentKey)
        }
    }

    public openTipLv2Hook(isTooltipShow:boolean){
        console.log(`Open:  master_nav-item__2nd_lv_wrap__popover`, isTooltipShow)
    }

    protected getIsChildActive(lv1key:Item['key']) {
        const targetFunctionLink = this.partitions
            .filter(partition => typeof partition !== "string")
            // @ts-ignore
            .filter(functionLinkLv1 => functionLinkLv1.key === lv1key)[0] as FunctionLink;

        if(!targetFunctionLink || !targetFunctionLink.children){
            return false
        }
        return targetFunctionLink.children.some(child => child.key === this.currentActiveKey)
    }

    protected isExpanded(key:Item['key']) {
        return this.lv2ExpandedList.includes(key)
    }
}

function getFlattedItemModels (partitions: (Product | FunctionLink | string)[]) {
    class ItemModel {
        public allSubs: ItemModel[];
        constructor(readonly o:FunctionLink, readonly parent?:FunctionLink) {
            this.allSubs = [
                this,
                ...this.children
            ]
        }
        get key() {
            return this.o.key;
        }
        get children():ItemModel[] {
            const children = this.o.children || []
            return children.map(child => new ItemModel(child, this))
        }
    }

    const flattedItemModels: ItemModel[] = partitions
        .reduce((acc: ItemModel[], current: FunctionLink | string) => {
            if (typeof current !== "string") {
                const itemModel = new ItemModel(current)
                return acc.concat(itemModel.allSubs)
            } else {
                return acc
            }
        }, [])
    return flattedItemModels
}
