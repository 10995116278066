var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "pz-email_alert__container",
        "pz-email_alert__container__" + _vm.mode,
      ],
    },
    [
      _c(
        "div",
        { staticClass: "pz-email_alert__container-title" },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        { staticClass: "pz-email_alert__container-content" },
        [_vm._t("content")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }