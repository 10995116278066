export enum ERROR_TOKEN_TYPE {
  ADJACENT_UNARY_OPERATOR_PRECEDING_OPERATOR_IGNORED,
  UNARY_OPERATOR_MISSING_OPERAND_IGNORED,
  BINARY_OPERATOR_MISSING_LEFT_OPERAND_IGNORED,
  BINARY_OPERATOR_MISSING_RIGHT_OPERAND_IGNORED,
  UN_SUPPORT_CONSECUTIVE_LOGICAL_WORDS,
  UN_SUPPORT_END_WITH_LOGICAL_WORDS,
  BAILOUT_TOKEN_IGNORED,
  LOGICAL_NOT_OPERATORS_PRECEDING_PREFERENCE_IGNORED,
  UN_SUPPORT_USELESS_LOGICAL_WORDS,
  UN_SUPPORT_EXTRA_PARENTHESES,
  UN_SUPPORT_MISSING_CLOSING_PARENTHESIS,
  UN_SUPPORT_EXTRA_CLOSING_PARENTHESIS,
  UN_SUPPORT_DOMAIN,
  UN_SUPPORT_EMPTY_PHRASE,
  UN_SUPPORT_LOGIC_NOT,
  UN_SUPPORT,
  UN_SUPPORT_WORD_LENGTH,
  UN_SUPPORT_FIRST_NOT_LOGICAL,
  UN_SUPPORT_NESTED_GROUP,
  UN_SUPPORT_WORDS_WITHOUT_PHRASE,
}

export type IErrorMap = Record<ERROR_TOKEN_TYPE, string>;
