var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-country-list" }, [
    _c(
      "div",
      { staticClass: "setting-country-list__search-bar" },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filterQuery,
              expression: "filterQuery",
            },
          ],
          attrs: {
            placeholder: _vm.$t(
              "app.userSetting.countryFilterInputPlaceholder"
            ),
            type: "text",
          },
          domProps: { value: _vm.filterQuery },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.filterQuery = $event.target.value
            },
          },
        }),
        _c("PtIcon", {
          staticStyle: { "font-size": "24px" },
          attrs: { icon: "SolidSearchLeft" },
        }),
      ],
      1
    ),
    _vm.countryList.length
      ? _c(
          "div",
          { staticClass: "setting-country-list__content" },
          _vm._l(_vm.countryList, function (ref, index) {
            var country = ref.country
            var label = ref.label
            return _c(
              "div",
              {
                key: index,
                class: [
                  "setting-country-list__item",
                  country,
                  country === _vm.currentSelectedCountry ? "selected" : "",
                ],
                on: {
                  click: function ($event) {
                    return _vm.changeSelect(country)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(label))])]
            )
          }),
          0
        )
      : _c("div", { staticClass: "setting-country-list__no-data" }, [
          _vm._v(" " + _vm._s(_vm.$t("app.userSetting.nodata")) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }