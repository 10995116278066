var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "master_nav-item__wrap",
      attrs: { "data-item-key": _vm.info.key },
    },
    [
      _c(
        "a",
        {
          staticClass: "master_nav-item",
          class: _vm.isTooltipShow ? "force-hover-state" : "",
          attrs: {
            "aria-expanded":
              _vm.service.isWide && _vm.isExpand ? "true" : "false",
            "data-level": "1",
            "aria-current": _vm.topNeedShowActive ? "true" : "false",
            flex: "-",
          },
          on: { click: _vm.onTopClick },
        },
        [
          _c(
            "ElTooltip",
            {
              ref: "mnTooltip",
              attrs: {
                transition: _vm.service.tooltipTransition,
                "popper-class":
                  "master_nav__tip " +
                  (_vm.hasChildren ? "master_nav__tip_lv2" : ""),
                offset: _vm.hasChildren ? -36 : 0,
                effect: _vm.hasChildren ? "light" : "dark",
                placement: _vm.hasChildren ? "right-start" : "right",
                "visible-arrow": false,
                disabled: _vm.service.isWide,
              },
              model: {
                value: _vm.isTooltipShow,
                callback: function ($$v) {
                  _vm.isTooltipShow = $$v
                },
                expression: "isTooltipShow",
              },
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.showLabelUnderIcon
                      ? "master_nav-item__icon-wrap-under-label-mode"
                      : "master_nav-item__icon-wrap",
                  ],
                  attrs: { "flex-item": ")0(", flex: "|center" },
                },
                [
                  _c("PtIcon", {
                    attrs: {
                      "flex-item": "center",
                      "font-size": 24,
                      icon: _vm.info.icon,
                    },
                  }),
                  _vm.hasChildren && !_vm.service.isWide
                    ? _c("Triangle", {
                        staticClass: "master_nav-item__triangle",
                      })
                    : _vm._e(),
                  _vm.showLabelUnderIcon
                    ? _c("div", { staticClass: "master_nav-item__label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.service.$t(_vm.info.label || _vm.info.key)
                          ) + " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("template", { slot: "content" }, [
                _vm.hasChildren
                  ? _c(
                      "section",
                      {
                        staticClass:
                          "master_nav-item__2nd_lv_wrap master_nav-item__2nd_lv_wrap__popover",
                        staticStyle: { "min-width": "200px" },
                      },
                      [
                        _c("DivLine", {
                          attrs: {
                            label: _vm.service.$t(
                              _vm.info.label || _vm.info.key
                            ),
                          },
                        }),
                        _vm._l(_vm.info.children, function (child) {
                          return _c(
                            "a",
                            {
                              key: child.key,
                              staticClass: "master_nav-item__2nd_lv",
                              attrs: {
                                "aria-current":
                                  _vm.service.currentActiveKey === child.key
                                    ? "true"
                                    : "false",
                                "data-level": "2",
                                "data-item-key": child.key,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.$emit("onClick", child)
                                  _vm.service.toggleExpandLv2(child.key, true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.service.$t(child.label || child.key))
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.service.$t(_vm.info.label || _vm.info.key)
                        ),
                      },
                    }),
              ]),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "master_nav-item__text",
              attrs: { "flex-item": "()" },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.service.$t(_vm.info.label || _vm.info.key)) +
                  " "
              ),
            ]
          ),
          _vm.hasChildren
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.service.isWide,
                      expression: "service.isWide",
                    },
                  ],
                  staticClass: "master_nav-item__2nd_lv_btn",
                  class: {
                    "master_nav-item__2nd_lv_btn_no_hover":
                      _vm.service.onlyLv2Link,
                  },
                  attrs: { "flex-item": ")0(start", type: "button" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("onClickExpand", _vm.info.key)
                    },
                  },
                },
                [
                  _c("PtIcon", {
                    attrs: {
                      icon: _vm.isExpand
                        ? "SolidArrowSmallUp"
                        : "SolidArrowsmallDown",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.service.isWide && _vm.hasChildren && _vm.isExpand,
              expression: "service.isWide && hasChildren && isExpand",
            },
          ],
          staticClass:
            "master_nav-item__2nd_lv_wrap master_nav-item__2nd_lv_wrap__default",
        },
        _vm._l(_vm.info.children, function (child) {
          return _c(
            "a",
            {
              key: child.key,
              staticClass: "master_nav-item__2nd_lv",
              attrs: {
                "aria-current":
                  _vm.service.currentActiveKey === child.key ? "true" : "false",
                "data-level": "2",
                "data-indent": "1",
                "data-item-key": child.key,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("onClick", child)
                },
              },
            },
            [_vm._v(_vm._s(_vm.service.$t(child.label || child.key)))]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }