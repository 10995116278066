var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s2w-range-selector-container" }, [
    _vm.service.rangeSelector.warning
      ? _c("div", {
          staticClass: "message-tip",
          domProps: { innerHTML: _vm._s(_vm.service.rangeSelector.warning) },
        })
      : _vm._e(),
    _c("div", { staticClass: "s2w-range-selector" }, [
      _c("div", { staticClass: "selector-label" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("BaseSaveToWorkspace.RangeSelector.title")) + " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "range-function-pane" },
        [
          _vm.service.enableRange
            ? [
                _c("div", { staticClass: "use-select" }, [
                  _c(
                    "div",
                    _vm._b(
                      {
                        staticClass: "s2w-range-radio",
                        class: {
                          disabled: _vm.service.disabledAllType,
                          active:
                            _vm.service.rangeSelector.selectedType === "ALL",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeRangeType(
                              "ALL",
                              _vm.service.disabledAllType
                            )
                          },
                        },
                      },
                      "div",
                      _vm.disabledTooltipOption,
                      false
                    ),
                    [
                      _c("div", { staticClass: "s2w-range-radio__dot" }),
                      _c(
                        "div",
                        { staticClass: "s2w-range-radio__content" },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.selectAllLabel) },
                          }),
                          _vm.allGroupCountLoading
                            ? [
                                _vm.allGroupCountLoading
                                  ? _c("PtIcon", {
                                      staticStyle: {
                                        "font-size": "24px",
                                        "margin-left": "4px",
                                      },
                                      attrs: { icon: "SolidLoadingBars" },
                                    })
                                  : _vm._e(),
                              ]
                            : [
                                _vm.familyMode &&
                                _vm.groupMode &&
                                _vm.allGroupCounts
                                  ? _c("span", {
                                      class: _vm.service.disabledAllType
                                        ? "s2w-range-radio__content__disabled"
                                        : "s2w-range-radio__content__counts",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "BaseSaveToWorkspace.RangeSelector.selectAllFamilyPatentCountLabel",
                                            {
                                              count: _vm.service.formatNumber(
                                                _vm.allGroupCounts
                                              ),
                                            }
                                          )
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                        ],
                        2
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "range-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "s2w-range-radio no-margin",
                        class: {
                          disabled: _vm.service.disabledRangeType,
                          active:
                            _vm.service.rangeSelector.selectedType === "RANGE",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeRangeType(
                              "RANGE",
                              _vm.service.disabledAllType
                            )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "s2w-range-radio__dot" }),
                        _c("div", { staticClass: "s2w-range-radio__content" }, [
                          _c(
                            "div",
                            {
                              staticClass: "range-selector",
                              class: {
                                "is-error": _vm.service.rangeSelector.error,
                              },
                            },
                            [
                              _c("span", { staticClass: "range-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "BaseSaveToWorkspace.RangeSelector.from"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("input", {
                                staticClass: "range-input",
                                attrs: {
                                  disabled: _vm.service.disabledRangeType,
                                },
                                domProps: {
                                  value: _vm.service.rangeSelector.from,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.onRangeInputBlur($event, "from")
                                  },
                                },
                              }),
                              _c("span", { staticClass: "range-label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "BaseSaveToWorkspace.RangeSelector.to"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("input", {
                                staticClass: "range-input",
                                attrs: {
                                  disabled: _vm.service.disabledRangeType,
                                },
                                domProps: {
                                  value: _vm.service.rangeSelector.to,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.onRangeInputBlur($event, "to")
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm.familyMode && _vm.groupMode
                            ? _c(
                                "div",
                                { staticClass: "range-count" },
                                [
                                  _vm.familyRangeCountLoading
                                    ? [
                                        _vm.familyRangeCountLoading
                                          ? _c("PtIcon", {
                                              staticStyle: {
                                                "font-size": "24px",
                                              },
                                              attrs: {
                                                icon: "SolidLoadingBars",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "BaseSaveToWorkspace.RangeSelector.familyLabel",
                                                {
                                                  count:
                                                    _vm.service.formatNumber(
                                                      _vm.familyRangeCount
                                                    ),
                                                }
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              )
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "range-error" }, [
                      _vm._v(
                        " " + _vm._s(_vm.service.rangeSelector.error) + " "
                      ),
                    ]),
                  ]),
                  _vm.showRangeTip
                    ? _c(
                        "div",
                        {
                          staticClass: "range-info",
                          attrs: {
                            "aria-label": _vm.$t(
                              "BaseSaveToWorkspace.RangeSelector.tip"
                            ),
                            "data-balloon-pos": "up",
                            "data-balloon-white": "",
                          },
                        },
                        [
                          _c("PtIcon", {
                            staticStyle: { "font-size": "24px" },
                            attrs: { icon: "SolidInfo" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "use-simple" },
                  [
                    _vm._t(
                      "simple",
                      function () {
                        return [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(_vm.selectLabel) },
                          }),
                        ]
                      },
                      { allCounts: _vm.allCounts }
                    ),
                  ],
                  2
                ),
              ],
          _vm.enableFamily ? _c("FamilySelector") : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }