import { TOKEN_BAILOUT, TOKEN_GROUP_BEGIN, TOKEN_TERM } from '../constants';
import { Token } from '../Values/Token';
import { GroupBegin } from './Values/Token/GroupBegin';

export abstract class TokenExtractor {
  public extract(str: string, position: number) {
    const byteOffset = this._getByteOffset(str, position);

    const expressionTypeMap = this.getExpressionTypeMap();

    for (const item of expressionTypeMap) {
      const { express, type } = item;

      const res = this.preg_match(str, express, byteOffset);

      if (!res) {
        // throw new Error('PCRE regex error code: ');
        continue;
      }

      if (res?.length === 0) {
        continue;
      }

      return this.createToken(type, position, res.groups);
    }
    return new Token(
      TOKEN_BAILOUT,
      str.substring(position, position + 1),
      position,
    );
  }

  private preg_match(data: string, exp: RegExp, offset: number) {
    exp.lastIndex = offset;
    const Matches = data.match(exp);
    return Matches;
  }

  /**
   * Create a token object from the given parameters.
   *
   * @param int $type Token type
   * @param int $position Position of the token in the input string
   * @param array $data Regex match data, depends on the type of the token
   *
   */
  private createToken(type: number, position: number, data: any): Token {
    if (type === TOKEN_GROUP_BEGIN) {
      return this.createGroupBeginToken(position, data);
    }

    if (type === TOKEN_TERM) {
      return this.createTermToken(position, data);
    }
    return new Token(type, data['lexeme'], position);
  }

  /**
   * Create an instance of Group token by the given parameters.
   *
   * @param $position
   * @param array $data
   *
   */
  protected createGroupBeginToken(position: number, data: any) {
    return new GroupBegin(
      data['lexeme'],
      position,
      data['delimiter'],
      data['domain'],
    );
  }

  /**
   * Create a term type token by the given parameters.
   *
   * @throw \RuntimeException If token could not be created from the given $matches data
   *
   * @param int $position Position of the token in the input string
   * @param array $data Regex match data, depends on the matched term token
   *
   */
  protected abstract createTermToken(position: number, data: any): Token;

  /**
   * Return a map of regular expressions to token types.
   *
   * The returned map must be an array where key is a regular expression
   * and value is a corresponding token type. Regular expression must define
   * named capturing group 'lexeme' that identifies part of the input string
   * recognized as token.
   */
  protected abstract getExpressionTypeMap(): {
    express: RegExp;
    type: number;
  }[];

  /**
   * Return the offset of the given $position in the input $string, in bytes.
   *
   * Offset in bytes is needed for preg_match $offset parameter.
   * @param str
   * @param position
   * @returns
   */
  private _getByteOffset(str: string, position: number) {
    return str.substring(0, position).length;
  }
}
