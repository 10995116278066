var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class:
        ((_obj = {
          "patsnap-biz-tag_legal": true,
          "patsnap-biz-tag_legal--simple": _vm.simple,
          "patsnap-biz-tag_legal--plain": _vm.isPlain,
        }),
        (_obj["patsnap-biz-tag_legal--code_" + _vm.code] = true),
        _obj),
    },
    [_vm._v(" " + _vm._s(_vm.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }