<template>
    <div
        class="patsnap-biz-degenerate_info"
        :class="{ 'is-common': isCommon }"
    >
        <div v-if="isCommon">
            <span>{{ charInfo.location }} |
                <!-- eslint-disable-next-line -->
                <span v-html="commonReplaces&&commonReplaces.length
                    ? lightMatch(commonReplaces) : '-' "
                /></span>
        </div>
        <template v-else>
            <div class="degenerate-info__title-container">
                <div
                    v-if="queryInfo"
                    class="degenerate-info__title"
                >
                    {{ $t('degenerateInfo.query') }}:
                    <span class="degenerate-info__title-num">
                        {{ queryInfo.qChar }}({{ queryInfo.qNo }})
                    </span>
                </div>
                <div
                    class="degenerate-info__title"
                >
                    {{ $t('degenerateInfo.subject') }}:
                    <span class="degenerate-info__title-num">
                        {{ charInfo.degenerateCode }}({{ charInfo.location }})
                    </span>
                </div>
            </div>
            <table
                v-if="!isDegenerate"
                class="degenerate-info__pn-table"
            >
                <tr>
                    <th>
                        {{ $t('degenerateInfo.description') }}
                        <!-- <PtIcon
                            v-if="isCn"
                            icon="SolidHelpCentre"
                            @click="handleHelp"
                        /> -->
                    </th>
                    <th>{{ $t('degenerateInfo.pn') }}</th>
                </tr>
                <tr v-if="!replaces || !replaces.length">
                    <td>-</td>
                    <td>-</td>
                </tr>
                <template v-else>
                    <tr
                        v-for="(item, index) in replaces"
                        :key="index"
                    >
                        <!-- eslint-disable-next-line -->
                        <td v-html="lightMatch(item.replaces,item.group)"></td>
                        <td>
                            <a
                                class="degenerate-info__pn-table-link"
                                :href="item.url ? item.url : 'javascript:void(0)'"
                                target="_blank"
                            >{{ item.pn }}</a>
                        </td>
                    </tr>
                </template>
            </table>
            <table
                v-if="isDegenerate"
                class="degenerate-info__pn-table degenerate-info__pn-table-degenerate"
            >
                <tr>
                    <th>
                        {{ $t('degenerateInfo.description') }}
                    </th>
                    <th>{{ $t('degenerateInfo.pn') }}</th>
                </tr>
                <tr v-if="!bestGroup.length">
                    <td>-</td>
                    <td>-</td>
                </tr>
                <template v-else>
                    <tr
                        v-for="(item, index) in bestGroup"
                        :key="index"
                    >
                        <!-- eslint-disable-next-line -->
                        <td v-html="lightMatch(item[0].replaces,item[0].group)"></td>
                        <td>
                            <div class="degenerate-info__pn-container">
                                <div>
                                    <a
                                        class="degenerate-info__pn-table-link"
                                        :href="item[0].url ? item[0].url : 'javascript:void(0)'"
                                        target="_blank"
                                    >{{ item[0].pn }}</a>
                                    <span
                                        v-show="item.length > 1 && !isShowMore"
                                        class="degenerate-info__more-text"
                                        @click="isShowMore = true"
                                    >
                                        [+{{ item.length - 1 }}]
                                    </span>
                                </div>
                                <div v-if="isShowMore">
                                    <div
                                        v-for="(subItem, subIndex) in item.slice(1)"
                                        :key="subIndex"
                                    >
                                        <a
                                            class="degenerate-info__pn-table-link"
                                            :href="subItem.url ? subItem.url : 'javascript:void(0)'"
                                            target="_blank"
                                        >{{ subItem.pn }}</a>
                                    </div>
                                </div>
                                <span
                                    v-show="isShowMore"
                                    class="degenerate-info__more-text"
                                    @click="isShowMore = false"
                                >
                                    [<PtIcon icon="SolidArrowSmallUp" />]
                                </span>
                            </div>
                            <span
                                v-if="bestGroup.length"
                                class="degenerate-info__best-match"
                            >
                                {{ $t('degenerateInfo.bestMatch') }}
                            </span>
                        </td>
                    </tr>
                </template>
            </table>
        </template>
    </div>
</template>

<script>
import PtIcon from '@patsnap-biz/icon';
import { groupBy, size } from 'lodash';
import i18n from './locals';
import '@patsnap-ui/icon/assets/solid/ArrowSmallUp.svg';
// import '@patsnap-ui/icon/assets/solid/HelpCentre.svg';

// eslint-disable-next-line
const isCn = window.location.host.indexOf('zhihuiya.com') > -1;

export default {
    i18n,
    components: {
        PtIcon,
    },
    props: {
        charInfo: {
            type: Object,
            required: true,
        },
        replaces: {
            type: Array,
            default: () => ([]),
        },
        commonReplaces: {
            type: Array,
            default: () => ([]),
        },
        isCommon: {
            type: Boolean,
            default: false,
        },
        queryInfo: {
            type: Object,
            default: () => (null),
        },
        helpCenterUrl: {
            type: String,
            default: '',
        },
        /**
         * 是否通式
         */
        isDegenerate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isCn,
            isShowMore: false,
        };
    },
    computed: {
        defaultHelpUrl() {
            return this.isCn ? 'https://help.zhihuiya.com/help/bio' : '';
        },
        groupData() {
            if (!this.isDegenerate || !this.replaces) return [];
            return groupBy(this.replaces, 'group');
        },
        groupDataSize() {
            return size(this.groupData);
        },
        bestGroup() {
            return (Object.values(this.groupData) || []).filter((g) => g.some((p) => p.best_match));
        },
    },
    methods: {
        lightMatch(replaces, group) {
            if (!replaces || !replaces.length) return '-';
            if (this.queryInfo && this.queryInfo.qChar) {
                const lowCaseQChar = this.queryInfo.qChar.toLowerCase();
                const arr = replaces.map((x) => {
                    if (this.isDegenerate && group !== undefined) {
                        return x.toLowerCase() === lowCaseQChar && group === 0 ? `<span class="light">${x}</span>` : x;
                    }
                    return x.toLowerCase() === lowCaseQChar
                        ? `<span class="light">${x}</span>` : x;
                });
                return arr.join(',');
            }
            return replaces.join(',');
        },
        handleHelp() {
            const url = this.helpCenterUrl ? this.helpCenterUrl : this.defaultHelpUrl;
            window.open(url);
        },
    },
};
</script>
