/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */

import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import flatMap from 'lodash/flatMap';
import assign from 'lodash/assign';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import groupBy from 'lodash/groupBy';

import { listToTree, treeToListByDFS } from './utils';

export const SearchType = {
    WORKSPACE: 'workspace',
    FOLDER: 'folder',
};

// eslint-disable-next-line no-control-regex,no-useless-escape
const specialExt = /^([a-zA-Z0-9_\[\]\{\}'"\-\.\,\?\&\/\(\)\s]|[^\u0000-\u007F])*$/;
class BasicService {
    constructor() {
        this.isSubmitting = false;

        this.type = 'PAPER'; // PATENT, PAPER
        this.baseUrl = '';
        this.axiosInstance = null;
        this.successSaveTipClassName = 'save-to-workspace-success-toast';
        this.errorCodeI18nPrefix = 'commonStatusCode';
        this.workspaceProductDomain = '';
        this.vm = '';
        this.searchGroupMode = {
            searchMode: '', // 同族库(family)  公开库(publication)
            groupMode: '', // 分组类型 默认为 ALL
            selectedGroupCounts: 0, // 选中同族背后实际专利数量
        };

        this.enableRange = false;

        // folder-selector
        this.folderSelector = {
            searchType: SearchType.FOLDER,
            enableFoldersSearch: true,
            maxFolderLevel: 10,
            folderLoopProtectionFlag: 10,
            highlightClassName: 'highlight-key',

            promiseUID: uniqueId(),
            loading: false,
            selectedWorkspace: null,
            selectedFolder: null,
            latestType: 'ADD', // 标识是最新保存/复制/移动   ADD,COPY,MOVE
            latestWorkspaceId: null,
            latestFolderId: null,
            searchKeywords: '',
            lastSearchKeywords: '',
            response: null,
            workspaceGroupList: [],
            get selfWorkspaceList() {
                return get(this.workspaceGroupList, '[0].workspaces', []);
            },
            get workspaceList() {
                const workspaceList = [];
                forEach(this.workspaceGroupList, ({ workspaces }) => {
                    workspaceList.push(...workspaces);
                });
                return workspaceList;
            },
            initWorkspaceScroll: false, // 是否进行过初始滚动;
            initFolderScroll: false,
        };

        // range selector
        this.rangeSelector = {
            disabledTypes: ['ALL', 'RANGE'],
            selectedType: 'ALL',
            error: undefined,
            warning: '',
            from: 1,
            to: 1,
            min: 1, // 输入最小值
            max: -1, // 可预期的最大输入值
            maxInputLimit: 10000, // 开始值最大值不能超过这个数
            allCounts: 0, // 用于范围选择验证中，如果普通就是allCounts, 如果是同族分组下,则就是groupCounts
            addLimit: 0, // 最大选择范围
            countMax: 0,
        };
    }

    tErrorCode(code, params) {
        const key = `${this.errorCodeI18nPrefix}.${code}`;
        const defaultKey = `${this.errorCodeI18nPrefix}.400`;
        return this.vm.$te(key) ? this.vm.$t(key, params) : this.vm.$t(defaultKey);
    }

    http(params) {
        const fullParams = {
            ...params,
        };
        fullParams.headers = {
            'X-API-Version': '2.0',
            ...fullParams.headers || {},
        };

        return this.axiosInstance(fullParams)
            .then((response) => {
                if (get(response, 'data.status')) {
                    return response.data.data;
                }

                const error_code = get(response, 'data.error_code', 400);
                const error_params = get(response, 'data.error_params') || {};
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    _error_code: error_code,
                    _error_msg: this.tErrorCode(error_code, error_params),
                });
            })
            .catch((error) => {
                if (error && error._error_code) {
                    return Promise.reject(error);
                }

                let error_code = 400;
                try {
                    const { code, message } = error.toJSON();
                    const httpStatusCodes = {
                        'Network Error': 440,
                    };
                    error_code = code || httpStatusCodes[message] || 400;
                } catch (e) {
                    error_code = 400;
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    _error_code: error_code,
                    _error_msg: this.tErrorCode(error_code),
                });
            });
    }

    isSubmitButtonDisabled() {
        if (!this.verifyRange()) return true; // 未通过范围表单验证

        if (this.isSubmitting) return true; // 正在执行提交

        if (this.folderSelector.loading) return true; // 正在查询工作空间/文件夹列表

        if (!this.folderSelector.selectedFolder) return true; // 未选择保存的文件夹

        if (get(this.folderSelector.selectedWorkspace, 'isLoading')) return true; // 正在获取文件夹列表

        return false;
    }

    formatNumber(value) {
        if (parseInt(`0${value}`, 10) <= 0) {
            return 0;
        }
        const str = new Intl.NumberFormat('en-US', { style: 'decimal' }).format(value);
        return `${str}`;
    }

    init() {
        this.initFolderSelector();
    }

    onToastError({ message }) {
        return message;
    }

    onError(error, { module }) {
        if (module !== 'FolderSelector' || !error || !error._error_msg) {
            // eslint-disable-next-line no-console
            return console.log('[SaveToWorkspace Error]: ', error);
        }
        return this.onToastError({ message: error._error_msg });
    }

    handleJumpToWorkspaceLink({
        folder_id,
        // folder_name,
        space_id,
        source_type,
        data_type,
    }) {
        const defaultWorkspaceViewType = this.type_alias || data_type || this.type || 'PATENT';
        const query = `?spaceId=${space_id}&folderId=${folder_id}&page=1&from=${source_type || ''}`;
        const base = `${this.workspaceProductDomain}/detail/${toLower(defaultWorkspaceViewType)}/folders`;

        return base + query;
    }

    generateToWorkspaceLink({
        folder_id,
        folder_name,
        space_id,
        source_type,
        data_type,
    }) {
        const link = this.handleJumpToWorkspaceLink({
            folder_id,
            folder_name,
            space_id,
            source_type,
            data_type,
        });
        // eslint-disable-next-line max-len
        // const link = `${this.workspaceProductDomain}/detail/${toLower(defaultWorkspaceViewType)}/folders?spaceId=${space_id}&folderId=${folder_id}&page=1&from=${source_type || ''}`;
        // eslint-disable-next-line max-len
        const linkTpl = `<a href="${link}" class="${this.successSaveTipClassName}__link" target="_blank">${folder_name}</a>`;

        return {
            link,
            linkTpl,
            template: `
                <div class="${this.successSaveTipClassName}">
                    <p>${this.vm.$t('BaseSaveToWorkspace.Main.saveToWorkspaceLink', { link: linkTpl })}</p>
                </div>
            `,
        };
    }

    getCurrentParams() {
        const {
            enableRange,
            folderSelector,
            rangeSelector,
        } = this;
        const { from, to, allCounts } = rangeSelector;

        const params = {
            folderId: undefined,
            workspaceId: undefined,
            counts: 0,
            isRange: false,
            isAll: false,
            from,
            to,
        };

        if (enableRange && rangeSelector.selectedType === 'RANGE') {
            params.isRange = true;
            params.counts = to - from + 1;
        } else {
            params.isAll = true;
            params.counts = allCounts;
            params.from = 1;
            params.to = allCounts;
        }

        const { folder_id } = folderSelector.selectedFolder || {};
        const { space_id } = folderSelector.selectedWorkspace || {};
        if (folder_id) {
            params.folderId = folder_id;
        }
        if (space_id) {
            params.workspaceId = space_id;
        }

        return params;
    }

    getSubmitApiConfig() {
        throw new Error({
            _error_msg: 'Please implementation method <getSubmitApiConfig>',
        });
    }

    async onSubmit() {
        this.isSubmitting = true;
        const { folder_name, folder_id } = this.folderSelector.selectedFolder;
        const { space_name, space_id } = this.folderSelector.selectedWorkspace;

        let data = {};

        try {
            data = await this.generateSubmitSaveToWorkspaceParams(this.getCurrentParams());
        } catch (error) {
            this.onError(error, { module: 'Submit' });
            return;
        }

        const linkTemplateParams = {
            ...data,
            folder_name,
            space_name,
            folder_id,
            space_id,
        };

        this.http({
            ...this.getSubmitApiConfig(),
            data,
        })
            .then(() => {
                this.isSubmitting = false;
                this.onClose(
                    this.generateToWorkspaceLink(linkTemplateParams),
                );
            })
            .catch((error) => {
                this.isSubmitting = false;
                if (error && error._error_msg) {
                    this.rangeSelector.warning = error._error_msg;
                } else {
                    this.onError(error, { module: 'Submit' });
                }
            });
    }

    // folder-selector
    generateSubmitSaveToWorkspaceParams() {
        return Promise.reject(new Error({
            _error_msg: 'Please implementation method <generateSubmitSaveToWorkspaceParams>',
        }));
    }

    initFolderSelector() {
        this
            .fetchWorkspaces()
            .then(() => {
                if (!this.folderSelector.response) return;
                const addLimit = get(this.folderSelector, 'response.addLimit');
                this.rangeSelector.addLimit = addLimit;
                this.rangeSelector.disabledTypes = [];

                const { maxInputLimit, allCounts } = this.rangeSelector;
                this.rangeSelector.max = Math.min(allCounts, maxInputLimit, addLimit);
                this.rangeSelector.to = this.rangeSelector.max;
                this.rangeSelector.countMax = allCounts;
                if (allCounts > this.rangeSelector.max) {
                    this.rangeSelector.selectedType = 'RANGE';
                    this.rangeSelector.disabledTypes = ['ALL'];
                }
                this.verifyRange();
            });
    }

    getWorkspaceGroupType({ is_public_space, is_shared_space }) {
        if (is_public_space) {
            return 'public';
        }
        if (is_shared_space) {
            return 'shared';
        }
        return 'self';
    }

    getSearchParams() {
        const { searchKeywords, latestType, searchType } = this.folderSelector;
        const searchParams = {
            need_share_space: true,
            need_public_space: true,
            folder_search_name: searchType === SearchType.FOLDER ? searchKeywords : '',
            data_type: this.type,
            operate_type: latestType,
        };
        return searchParams;
    }

    fetchWorkspaces() {
        const { searchKeywords, searchType } = this.folderSelector;

        const searchParams = this.getSearchParams();

        this.folderSelector.loading = true;
        this.folderSelector.workspaceGroupList = [];
        this.folderSelector.latestWorkspaceId = null;
        this.folderSelector.latestFolderId = null;
        this.folderSelector.initWorkspaceScroll = false;
        this.folderSelector.initFolderScroll = false;

        this.selectWorkspace(null).then(() => {
            this.selectFolder(null);
        }).catch(() => {});

        this.folderSelector.promiseUID = uniqueId();
        const { promiseUID } = this.folderSelector;

        this.folderSelector.lastSearchKeywords = searchKeywords;

        return this.http({
            url: `${this.baseUrl}/space/self-share-public`,
            method: 'POST',
            data: searchParams,
        })
            .then((response) => {
                if (this.folderSelector.promiseUID !== promiseUID) return;
                this.folderSelector.response = cloneDeep(response);

                const spaces = flatMap(
                    [
                        ...sortBy(get(response, 'self') || [], ({ update_ts }) => -new Date(update_ts)),
                        ...sortBy(get(response, 'shared') || [], ({ update_ts }) => -new Date(update_ts)),
                        ...sortBy(get(response, 'public') || [], ({ update_ts }) => -new Date(update_ts)),
                    ],
                    (workspace) => {
                        if (searchType === SearchType.WORKSPACE && searchKeywords) {
                            const reg = this.createRegExpFromString(searchKeywords);
                            if (!reg.test(workspace.space_name)) {
                                return [];
                            }
                        }
                        return [workspace];
                    }
                    ,
                );

                const groups = groupBy(spaces, (space) => this.getWorkspaceGroupType(space));

                this.folderSelector.workspaceGroupList = [
                    { type: 'self', expand: false, workspaces: groups.self || [] },
                    { type: 'shared', expand: false, workspaces: groups.shared || [] },
                    { type: 'public', expand: false, workspaces: groups.public || [] },
                ];

                if (!spaces || !spaces.length) {
                    this.folderSelector.loading = false;
                    return;
                }

                let defaultWorkspace = spaces[0];

                if (!searchKeywords) {
                    const {
                        folder_id: latestFolderId, space_id: latestWorkspaceId,
                    } = get(response, 'goal_workspace_folder') || {};

                    if (latestWorkspaceId && latestFolderId) {
                        this.folderSelector.latestWorkspaceId = latestWorkspaceId;
                        this.folderSelector.latestFolderId = latestFolderId;

                        const latestWorkspace = find(spaces, { space_id: latestWorkspaceId });
                        if (latestWorkspace) {
                            defaultWorkspace = latestWorkspace;
                        }
                    }
                }
                this.selectWorkspace(defaultWorkspace).then(() => {
                    const { latestFolderId, selectedWorkspace } = this.folderSelector;
                    let defaultFolder = get(selectedWorkspace, 'folderTreeData[0]');
                    if (!searchKeywords) {
                        defaultFolder = find(
                            selectedWorkspace.folderTreeList, { folder_id: latestFolderId },
                        ) || defaultFolder;
                    }

                    this.selectFolder(defaultFolder);

                    if (selectedWorkspace && (defaultFolder !== selectedWorkspace.folderTreeData[0])) {
                        this.expandFolderParent({
                            workspace: selectedWorkspace,
                            folder: defaultFolder,
                        });
                    }
                }).catch(() => {});

                // 如果是搜索, 则默认展开全部工作空间，否则只展开默认选中工作空间的类型
                const workspaceGroupType = this.getWorkspaceGroupType(defaultWorkspace || {});
                forEach(this.folderSelector.workspaceGroupList, (group) => {
                    if (searchKeywords) {
                        group.expand = true;
                    }
                    if (group.type === workspaceGroupType) {
                        group.expand = true;
                    }
                });

                this.folderSelector.loading = false;
            })
            .catch((error) => {
                this.folderSelector.loading = false;
                this.onError(error, { module: 'FolderSelector' });
            });
    }

    fetchFolders({ workspace, folder_search_name }) {
        const { space_id } = workspace;
        this.vm.$set(workspace, 'isLoading', true);
        return this.http({
            url: `${this.baseUrl}/space/search-folder`,
            method: 'POST',
            data: { space_id, folder_search_name },
        }).then((response) => {
            const folders = uniqBy(response, 'folder_id');

            // 在QA环境发现有一个workspace里folder列表会返回好几个相同的key的folder，应该是测试数据,做一下兜底;
            if (folders.length !== response.length) {
                // eslint-disable-next-line no-console
                console.warn(
                    '[Folder Selector] 存在相同key的folder',
                    space_id,
                    [folders.length, response.length],
                );
            }

            if (folder_search_name) {
                forEach(folders, (folder) => {
                    folder.expand = true;
                });
            }
            this.vm.$set(workspace, 'folder_info_simples', folders);
            this.vm.$set(workspace, 'folderTreeData', listToTree(
                workspace.folder_info_simples,
                '-root',
                'folder_id',
                'parent_id',
            ));
            this.vm.$set(workspace, 'folderTreeList', treeToListByDFS(workspace.folderTreeData));
            this.vm.$set(workspace, 'isLoading', false);
            return folders;
        }).catch((error) => {
            this.vm.$set(workspace, 'isLoading', false);
            this.vm.$set(workspace, 'folder_info_simples', []);
            this.vm.$set(workspace, 'folderTreeData', []);
            this.vm.$set(workspace, 'folderTreeList', []);
            this.onError(error, { module: 'FolderSelector' });
        });
    }

    getWorkspaceIcon(workspace) {
        const { is_shared_space, is_public_space } = workspace || {};

        if (is_shared_space) {
            return 'SolidSpaceShared';
        }
        if (is_public_space) {
            return 'SolidCompany';
        }

        return 'Solid3D';
    }

    getFolderIcon() {
        return 'SolidFolderClosed2';
    }

    getWorkspaceElementsCountString() {}

    getFolderElementsCountString() {}

    hasEditWorkspacePermission(workspace) {
        const { edit_definition = false } = workspace || {};
        return edit_definition;
    }

    hasAddWorkspaceFolderPermission(workspace) {
        const { permission_detail: permission } = workspace || {};
        if (!permission) {
            return true;
        }
        return !!get(permission, 'folder_create', false);
    }

    hasEditFolderPermission({ folder }) {
        return folder.can_edit;
    }

    hasAddSubFolderPermission({ folder, level }) {
        if (level + 1 >= this.folderSelector.maxFolderLevel) {
            return false;
        }
        return folder.can_add;
    }

    toggleFolderExpand({ folder }) {
        const expand = !folder.expand;
        this.vm.$set(folder, 'expand', expand);
    }

    expandFolderParent({ workspace, folder }) {
        const folders = treeToListByDFS(workspace.folderTreeData) || [];

        let folder$ = folder;
        while (folder$) {
            folder$ = find(folders, { folder_id: folder$.parent_id });
            if (folder$) {
                this.vm.$set(folder$, 'expand', true);
            }
        }
    }

    finishEditWorkspace(workspace) {
        if (!this.isWorkspaceNameValid(workspace)) {
            assign(workspace, { space_name: workspace.originSpaceName });
            workspace.isEditing = false;
            return;
        }

        if (!workspace.isNew && workspace.space_name === workspace.originSpaceName) {
            workspace.isEditing = false;
            return;
        }

        this.vm.$set(workspace, 'isUpdating', true);

        const {
            space_id, space_name, space_desc, space_icon,
        } = workspace;
        // eslint-disable-next-line consistent-return
        return this.http({
            url: `${this.baseUrl}/space/${space_id}`,
            method: 'PUT',
            data: {
                space_name,
                space_desc,
                space_icon,
            },
        })
            .then((data) => {
                assign(workspace, data);
                workspace.isUpdating = false;
                workspace.isEditing = false;
                if (workspace.isNew && workspace.folderTreeData && workspace.folderTreeData.length) {
                    delete workspace.isNew;
                    this.editFolder({
                        workspace,
                        folder: workspace.folderTreeData[0],
                    });
                }
                return data;
            })
            .catch((error) => {
                workspace.isUpdating = false;
                this.onError(error, { module: 'FolderSelector' });
            });
    }

    editWorkspace(workspace) {
        if (!this.hasEditWorkspacePermission(workspace)) return false;
        this.vm.$set(workspace, 'isEditing', true);
        this.vm.$set(workspace, 'originSpaceName', workspace.space_name);
        return true;
    }

    isWorkspaceNameValid(workspace) {
        if (!workspace.space_name) {
            return false;
        }
        return specialExt.test(workspace.space_name);
    }

    finishEditFolder({ workspace, folder, parentFolder }) {
        if (!this.isFolderNameValid({ workspace, folder })) {
            if (folder.isNew) {
                const parent = parentFolder ? parentFolder.children : workspace.folderTreeData;
                const index = findIndex(parent, { folder_id: folder.folder_id });
                if (index > -1) {
                    parent.splice(index, 1);
                }
                return;
            }
            assign(folder, { folder_name: folder.originFolderName });
            folder.isEditing = false;
            return;
        }

        if (!folder.isNew && folder.folder_name === folder.originFolderName) {
            folder.isEditing = false;
            return;
        }

        this.vm.$set(folder, 'isUpdating', true);

        const params = {
            ...folder,
            space_id: workspace.space_id,
        };

        let promise = null;

        if (params.isNew) {
            delete params.folder_id;
            params.parent_id = parentFolder ? parentFolder.folder_id : '-root';
            promise = this.http({
                url: `${this.baseUrl}/space/${params.space_id}/folder`,
                method: 'PUT',
                data: params,
            });
        } else {
            promise = this.http({
                url: `${this.baseUrl}/${params.space_id}/folder/${params.folder_id}`,
                method: 'POST',
                data: params,
            });
        }

        // eslint-disable-next-line consistent-return
        return promise
            .then((data) => {
                assign(folder, data);
                delete folder.isNew;
                folder.isUpdating = false;
                folder.isEditing = false;
                if (!this.isFolderDisabled({ workspace, folder })) {
                    if (workspace === this.folderSelector.selectedWorkspace) {
                        this.selectFolder(folder);
                    }
                }
                return data;
            })
            .catch((error) => {
                folder.isUpdating = false;
                this.onError(error, { module: 'FolderSelector' });
            });
    }

    editFolder({ workspace, folder }) {
        if (!this.hasEditFolderPermission({ workspace, folder })) return false;
        this.vm.$set(folder, 'isEditing', true);
        this.vm.$set(folder, 'originFolderName', folder.folder_name);
        return true;
    }

    isFolderNameValid({ folder }) {
        if (!folder.folder_name) {
            return false;
        }
        return specialExt.test(folder.folder_name);
    }

    isFolderDisabled() {
        return false;
    }

    folderDisabledMsg() {
        return '';
    }

    createRegExpFromString(str) {
        // eslint-disable-next-line no-useless-escape
        return new RegExp(str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'ig');
    }

    renderFolderName(folder) {
        const { lastSearchKeywords, searchType } = this.folderSelector;
        return this.getHighlightHtmlStr({
            text: folder.folder_name,
            highlight: searchType === SearchType.FOLDER ? lastSearchKeywords : '',
        });
    }

    renderSpaceName(workspace) {
        const { lastSearchKeywords, searchType } = this.folderSelector;
        return this.getHighlightHtmlStr({
            text: workspace.space_name,
            highlight: searchType === SearchType.WORKSPACE ? lastSearchKeywords : '',
        });
    }

    getHighlightHtmlStr({ text, highlight }) {
        if (!highlight) return text;
        const { highlightClassName } = this.folderSelector;
        const reg = this.createRegExpFromString(highlight);
        return text.replace(reg, (i) => `<span class='${highlightClassName}'>${i}</span>`);
    }

    addFolder({ workspace, parentFolder }) {
        let parent = [];

        if (parentFolder) {
            if (!parentFolder.expand) {
                this.vm.$set(parentFolder, 'expand', true);
            }
            if (!parentFolder.children) {
                this.vm.$set(parentFolder, 'children', []);
            }
            parent = parentFolder.children;
        } else {
            parent = workspace.folderTreeData;
        }

        // 防止workspace没加载好，添加文件夹的时候，报错
        if (!parent) return;

        const defaultFolderName = this.vm.$t('BaseSaveToWorkspace.FolderSelector.newFolderName');
        let folderName = defaultFolderName;

        for (let i = 1; i < 2000; i += 1) {
            // 阀值设置为2000，防止移除;
            if (!find(parent, { folder_name: folderName })) {
                break;
            }
            folderName = `${defaultFolderName} ${i}`;
        }

        const newFolder = {
            isNew: true, // 接口创建完成后，会删除此字段;
            isNewCreate: true, // 接口创建完成后前端将会保留此字段;
            folder_id: uniqueId(),
            folder_name: folderName,
            patent_count: 0,
            folder_auto_update: false,
            can_edit: true,
            folder_desc: '',
            parent_id: parentFolder ? parentFolder.folder_id : '-root',
        };

        parent.push(newFolder);
        this.editFolder({
            workspace,
            folder: newFolder,
        });
    }

    addWorkspace() {
        if (this.folderSelector.loading) {
            return;
        }

        const defaultWorkspaceName = this.vm.$t('BaseSaveToWorkspace.FolderSelector.newWorkspaceName');
        let workspaceName = defaultWorkspaceName;

        for (let i = 1; i < 2000; i += 1) {
            // 阀值设置为2000，防止移除;
            if (!find(this.folderSelector.selfWorkspaceList, { space_name: workspaceName })) {
                break;
            }
            workspaceName = `${defaultWorkspaceName} ${i}`;
        }

        this.http({
            url: `${this.baseUrl}/space`,
            method: 'POST',
            data: {
                space_name: workspaceName,
                space_desc: '',
                space_icon: '',
            },
            params: {
                init_folder: false,
            },
        })
            .then((workspace) => {
                this.http({
                    url: `${this.baseUrl}/space/${workspace.space_id}/folder`,
                    method: 'PUT',
                    data: {
                        space_id: workspace.space_id,
                        parent_id: '-root',
                        folder_name: this.vm.$t('BaseSaveToWorkspace.FolderSelector.newFolderName'),
                        folder_desc: '',
                    },
                })
                    .then((folder) => {
                        assign(folder, {
                            isNewCreate: true,
                        });
                        assign(workspace, {
                            isNew: true,
                            isNewCreate: true,
                            expand: true,
                            folderTreeData: [folder],
                        });

                        this.folderSelector.workspaceGroupList[0].workspaces.unshift(workspace);

                        if (!this.isFolderDisabled({ workspace, folder })) {
                            this.selectWorkspace(workspace).then(() => {
                                this.selectFolder(folder);
                            }).catch(() => {});
                        }

                        this.editWorkspace(workspace);
                    })
                    .catch((error) => {
                        this.onError(error, { module: 'FolderSelector' });
                    });
            })
            .catch((error) => {
                this.onError(error, { module: 'FolderSelector' });
            });
    }

    async selectWorkspace(workspace) {
        this.folderSelector.initFolderScroll = false;
        this.folderSelector.selectedWorkspace = workspace;

        // 如果选中了某个工作空间，则其所在的组自动展开
        if (workspace) {
            const group = find(this.folderSelector.workspaceGroupList, { type: this.getWorkspaceGroupType(workspace) });
            if (!group.expand) {
                group.expand = true;
            }

            // 判断是否需要远程加载
            if (!workspace.folder_info_simples && !workspace.isLoading) {
                const { lastSearchKeywords, searchType } = this.folderSelector;
                await this.fetchFolders({
                    workspace,
                    folder_search_name: searchType === SearchType.FOLDER ? lastSearchKeywords : '',
                });
                if (this.folderSelector.selectedWorkspace !== workspace) {
                    throw new Error();
                }
            }
        }
    }

    selectFolder(folder) {
        this.folderSelector.selectedFolder = folder;
    }

    // RangeSelector
    get disabledAllType() {
        return includes(this.rangeSelector.disabledTypes, 'ALL');
    }

    getDisabledAllTypeTooltip() {
        if (!this.disabledAllType || this.folderSelector.loading) return '';
        const {
            type, formatNumber, rangeSelector: { addLimit }, vm,
        } = this;
        return vm.$t(`BaseSaveToWorkspace.${type}.RangeSelector.error.rangeError`, { limit: formatNumber(addLimit) });
    }

    get disabledRangeType() {
        return includes(this.rangeSelector.disabledTypes, 'RANGE');
    }

    verifyRange() {
        const { enableRange } = this;
        const {
            from, to, addLimit, maxInputLimit, selectedType,
        } = this.rangeSelector;
        this.rangeSelector.error = null;

        if (!enableRange || selectedType === 'ALL') {
            return true;
        }

        if (!from || !to || to < from) {
            this.rangeSelector.error = this.vm.$t(`BaseSaveToWorkspace.${this.type}.RangeSelector.error.invalidError`);
            return false;
        }

        if (from > maxInputLimit || to > maxInputLimit) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.overMaxCountError`,
                { limit: this.formatNumber(maxInputLimit) },
            );
            return false;
        }

        const maxCounts = this.rangeSelector.countMax;
        if (from > maxCounts || to > maxCounts) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.invalidError`,
            );
            return false;
        }

        if ((to - from + 1) > maxCounts) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.invalidError`,
            );
            return false;
        }

        if ((to - from + 1) > addLimit) {
            this.rangeSelector.error = this.vm.$t(
                `BaseSaveToWorkspace.${this.type}.RangeSelector.error.rangeError`,
                { limit: this.formatNumber(addLimit) },
            );
            return false;
        }

        return true;
    }
}

export default BasicService;
