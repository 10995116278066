import { Node } from '../../../Values/Node';
import { Token } from '../../../Values/Token';

export class Term extends Node {
  constructor(public token: Token) {
    super();
  }

  public getNodes() {
    return [];
  }

  public getReductionGroup() {
    return 'term';
  }
}
