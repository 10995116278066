// fetch方式：任何不是以网络异常引起的问题，包括200，400，500等状态码 response = {status, statusText, body}

import inflight from "./inflight";

export function extractData(response) {
	return response.data || response;
}
// fetch方式：任何以网络异常引起的问题，以及非200状态码 err = {status, statusText, body}
// 非fetch方式： 任何非200状态码 err = requestObj
export function exceptionReduce(resOrErr) {
	// production环境下： 统一拦截 403 接口无权限错误，需要重新跳转到登陆页
	if (process.env.NODE_ENV === 'production') {
		if (resOrErr.status && resOrErr.status === 403) {
			try {
				if (resOrErr.response && JSON.parse(resOrErr.response).redirectUrl) {
					window.location.href = JSON.parse(resOrErr.response).redirectUrl;
				} else {
					window.location.href = '/';
				}
			} catch (e) {
				window.location.href = '/';
			}
		}
	}
	throw resOrErr;
	// 返回一个流，防止rx 坏掉。流信息包含error
	// return Rxjs.Observable.of(resOrErr);
}

export function inflightCall (uuid, usedConfig, callFn) {
    return inflight(uuid, () => callFn(usedConfig))
}


export function sleep(mstime) {
    return new Promise(function (resolve) {
        setTimeout(() => resolve(mstime), mstime)
	})
}

export function fastReadAuthToken (type = 'token') {
	const storeKeyPrefix = 'patsnap';
	const storeItemKey = `${storeKeyPrefix}-authorizations`
	const tokenParis = JSON.parse(localStorage.getItem(storeItemKey) || '{}')
	if (!tokenParis[type]) {
		return null
	}
	return tokenParis[type]
}
