var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "svg",
    _vm._b(
      {
        class: _vm.data.staticClass || "",
        attrs: {
          viewBox: "0 0 7 7",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg",
        },
      },
      "svg",
      _vm.data.attrs,
      false
    ),
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.99998 1.93135V5.99998C6.99998 6.55226 6.55226 6.99998 5.99998 6.99998H1.93135C1.48952 6.99998 1.13135 6.64181 1.13135 6.19998C1.13135 5.98781 1.21563 5.78432 1.36566 5.63429L5.63429 1.36566C5.94671 1.05324 6.45324 1.05324 6.76566 1.36566C6.91569 1.51569 6.99998 1.71918 6.99998 1.93135Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }