import { Node } from '../../../Values/Node';

export class Query extends Node {
  constructor(public nodes: Node[]) {
    super();
  }

  public getNodes() {
    return this.nodes;
  }

  public getReductionGroup() {
    return '';
  }
}
