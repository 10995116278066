const BLANK = '\u00A0';

function between(min: number, max: number, val: number) {
    return val >= min && val <= max;
}

function one2Three(start: number, end: number, splitStart: number, splitEnd: number, highlightNos: any, ): any[] {
    return [
        { start: start, end: splitStart - 1, highlightNos: highlightNos.slice()},
        { start: splitStart, end: splitEnd, highlightNos: highlightNos.slice()},
        { start: splitEnd + 1 , end: end, highlightNos: highlightNos.slice()},
    ]
}

function one2Two(start: number, end: number, splitIndex: number, highlightNos: any): any[] {
    return [
        { start: start, end: splitIndex - 1, highlightNos: highlightNos.slice()},
        { start: splitIndex, end: end, highlightNos: highlightNos.slice()},
    ]
}

function wrapByHtmlTag(text: string, tag: string, attributes: Object) {
    // @ts-ignore
    return `<${tag} ${Object.keys(attributes).map(x => `${x}="${attributes[x]}"`).join(' ')}>${text}</${tag}>`;
}

// 计算高亮片段
export function calculate(ctx: any, sequence: string, lights: any[], segmentLength: number, degenerateNos: number[]) {
    let segments: SubSequence[] = [];
    // 遍历高亮数组，计算出有重叠的高亮
    let segmentsAfterLight: any[] = [];
    if (!lights.length){
        const subSequence = new SubSequence(ctx, sequence, 0, sequence.length - 1, [], lights, segmentLength, degenerateNos);
        return [subSequence];
    }
    lights.forEach((x: any, index: number) => {
        if (segmentsAfterLight.length === 0) {
            const temArr = one2Three(1, sequence.length, x.start, x.end, []);
            temArr[1].highlightNos.push(index);
            segmentsAfterLight.push(...temArr);
        } else {
            let tempArr = segmentsAfterLight.map(y => {
                // 整段都命中
                if (x.start <= y.start && x.end >= y.end) {
                    y.highlightNos.push(index)
                    return y;
                } else if (x.start > y.end || x.end < y.start) {
                    // 整段都不命中
                    return y;
                } else if (between(y.start, y.end, x.start) && between(y.start, y.end, x.end)) {
                    // 有交叉的三种情况
                    const tempArr1 = one2Three(y.start, y.end, x.start, x.end, y.highlightNos)
                    tempArr1[1].highlightNos.push(index);
                    return tempArr1;
                } else if (between(y.start, y.end, x.start)) {
                    const tempArr1 = one2Two(y.start, y.end, x.start, y.highlightNos)
                    tempArr1[1].highlightNos.push(index);
                    return tempArr1;
                } else if (between(y.start, y.end, x.end)) {
                    const tempArr1 = one2Two(y.start, y.end, x.end + 1, y.highlightNos)
                    tempArr1[0].highlightNos.push(index);
                    return tempArr1;
                }
            })
            segmentsAfterLight = [];
            tempArr.forEach(f => {
                Array.isArray(f)
                    ? segmentsAfterLight.push(...f)
                    : segmentsAfterLight.push(f);
            });
        }

        segmentsAfterLight = segmentsAfterLight.filter(x => x.end - x.start >= 0)
    })
    segments = segmentsAfterLight.map(x => {
        return new SubSequence(ctx, sequence.substring(x.start - 1, x.end), x.start - 1, x.end - 1, x.highlightNos, lights , segmentLength, degenerateNos)
    })
    return segments
}
// 高亮片段重叠，拆分成重叠片段和其他片段
// 索引从0开始？ 感觉从1开始更方便
export class SubSequence {
    sequence: string = '';
    start: number = -1;
    end: number = -1;
    highlightStyle: string = '';
    highlightNos: number[] = [];
    highlights: any[] = [];
    enableHover: boolean  = false;
    spaces: any[] = [];
    wraps: any[] = [];
    boundaries: any[] = [];
    degenerateNos: number[] = [];
    spaceLength = 10;
    rowLength = 60;
    ctx: any;

    constructor(ctx: any, sequence: string, start: number, end: number, highlightNos: any, highlights: any[] , segmentLength: number, degenerateNos: number[] ) {
        this.ctx = ctx;
        this.sequence = sequence;
        this.start = start;
        this.end = end;
        this.highlightNos = highlightNos;
        this.rowLength = segmentLength;
        this.highlights = highlights;
        this.setBoundary();
        this.setDegenerate(degenerateNos);
        this.appendSpace();
        this.appendWrap();
    }

    /**
     * degenerate是原有逻辑，保留不做修改
     * 除此之外的高亮皆为可自定义
     * 包裹结构：
     *
     * 1. 没有高亮, raw text
     * 没有degenerate高亮，经过作用域插槽后依然是string
     *
     * T
     *
     * 2. 有高亮
     * data-no：第几位
     * data-highlight-target：标记了是属于那种类型的高亮
     *
     * <span data-no="4">
     *     <span data-highlight-target="degenerate">T</span>
     * </span>
     *
     * 3. 多种高亮，每一种一层span
     *
     * <span data-no="3">
     *     <span data-highlight-target="degenerate">
     *         <span data-highlight-target="modification">T</span>
     *     </span>
     * </span>
     * */
    get formattedSequence(): any[] {
        if (!this.sequence) {
            return [];
        }

        const h = this.ctx.$createElement;

        let tempSeq: any[] = this.sequence.split('');

        const customSlotRender = typeof this.ctx.$scopedSlots.label === 'function' ? this.ctx.$scopedSlots.label : undefined

        const createHoverWrapper = (kids: any, no: number) => {
            return [h('span', { attrs: { 'data-no': no } }, kids)];
        }

        const createDegenerateWrapper = (kids: any) => {
            return [h('span', { attrs: { class: 'degenerate', 'data-highlight-target': 'degenerate' } }, kids)]
        };

        // 用自定义插槽包裹
        tempSeq = tempSeq.map((x, i) => {
            const no = i + this.start + 1
            const customRendered = customSlotRender ? customSlotRender({
                index: i + this.start,
                location: no,
                node: x,
                label: this.sequence[i],
            }) : null;

            // 自定义插槽没渲染，忽略
            let rendered = customRendered ? customRendered : x

            // 内置degenerate高亮不变
            if (this.degenerateNos.includes(i + this.start)) {
                rendered = createDegenerateWrapper(rendered)
            }

            // 是string，没有经过degenerate和自定义插槽
            // 直接输出string，等待后续合并
            return typeof rendered === 'string' ? rendered : createHoverWrapper(rendered, no);
        });

        this.boundaries.forEach(x => {
            const index = x - this.start;
            tempSeq[index] = h('b', { class: `arrow-sign ${ x === this.end ? 'end' : ''  }` }, tempSeq[index]);
            // tempSeq[index] = wrapByHtmlTag(tempSeq[index], 'b', { class: `arrow-sign ${ x === this.end ? 'end' : ''  }` })
        });

        this.spaces.forEach(x => {
            const index = x - this.start;
            const i = tempSeq[index];
            tempSeq[index] = typeof i !== 'string' ? [i, BLANK] : `${i}${BLANK}`;
        })

        this.wraps.forEach(x => {
            const index = x - this.start;
            tempSeq[index] = [tempSeq[index], h('br')];
        })

        tempSeq = tempSeq.reduce((prev, curr) => {
            if (typeof curr === 'string' && typeof prev[prev.length - 1] === 'string') {
                prev[prev.length - 1] = prev[prev.length - 1] + curr;
            } else {
                prev.push(curr);
            }
            return prev;
        }, [] as any[]);

        return tempSeq;
    }

    get useHighLight(): any {
        return this.highlightNos[this.highlightNos.length - 1];
    }

    get useHighlightColor() {
        const curHighlight = this.highlights[this.useHighLight];
        if(this.highlights[this.useHighLight]) {
            return curHighlight.color;
        }
        return '';
    }

    setBoundary() {
        if (this.highlights.find(x => x.start - 1 === this.start || x.end - 1 === this.start)) {
            this.boundaries.push(this.start);
        }
        if (this.highlights.find(x => x.start - 1 === this.end || x.end - 1 === this.end)) {
            this.boundaries.push(this.end);
        }
    }

    setDegenerate(degenerateNos: number[]) {
        this.degenerateNos = degenerateNos.filter(x => x - 1 >= this.start && x - 1 <= this.end).map(x => x - 1);
    }

    // 控制换行
    appendWrap() {
        const remainder = (this.end + 1) % this.rowLength;
        let num = this.end - remainder;
        while(num >= this.start) {
            if (num !== 0){
                this.wraps.push(num);
            }
            num -= this.rowLength;
        }
    }

    // 自己每10个补个空格
    appendSpace() {
        const remainder = (this.end + 1) % this.spaceLength;
        let num = this.end - remainder;
        while(num >= this.start) {
            if (num % this.rowLength !== this.rowLength - 1 && num !== 0) {
                this.spaces.push(num);
            }
            num -= this.spaceLength;
        }
    }
}
