interface RenderSlots {
    label: (options: {
        index: number;
        location: number;
        label: string;
        node: any;
    }) => any;
    popover: (options: {
        location: number;
        highlightTarget: string[];
        node?: any;
    } & Record<string, string>) => any;
}

export const slotRender = (targets: Record<string, RenderSlots>) => {
    const keys = Object.keys(targets);
    return {
        label: (options: any) => {
            if (keys.length <= 0) return null;
            return keys.reduce((prev, curr) => {
                const render = targets[curr].label;
                if (typeof render === 'function') {
                    return render({
                        ...options,
                        node: prev,
                    });
                }
                return null;
            }, options.node);
        },
        popover: (options: any) => {
            return keys.map((k) => {
                const render = targets[k].popover;
                if (options && typeof render === 'function' && options.highlightTarget.includes(k)) {
                    return render(options);
                }
                return options?.node || null;
            });
        }
    }
}
