var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s2w-sequence-selector-wrap" }, [
    _vm.service.rangeSelector.warning
      ? _c("div", {
          staticClass: "message-tip",
          domProps: { innerHTML: _vm._s(_vm.service.rangeSelector.warning) },
        })
      : _vm._e(),
    _c("div", { staticClass: "s2w-sequence-selector" }, [
      _c("div", { staticClass: "s2w-sequence-selector-label" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("BaseSaveToWorkspace.sequence.selected"))),
        ]),
      ]),
      _vm.sequenceName
        ? _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.innerSeqName,
                  expression: "innerSeqName",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "s2w-sequence-selector__input",
              attrs: { type: "text" },
              domProps: { value: _vm.innerSeqName },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.innerSeqName = $event.target.value.trim()
                  },
                  _vm.onNameChange,
                ],
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ])
        : _c("div", { staticClass: "s2w-sequence-selector-value" }, [
            _vm._v(
              " " +
                _vm._s(_vm.selectedCount) +
                " " +
                _vm._s(_vm.$t("BaseSaveToWorkspace.sequence.selected")) +
                " "
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }