
/**
 * 格式化字符串，将字符串中的特定词汇替换为大写，并将中文括号替换为英文括号。
 * @param originalStr 原始字符串
 * @returns 格式化后的字符串
 */
export function formatStr(originalStr: string) {
  return originalStr
    .replace(/(\bnot\b)/gi, 'NOT')
    .replace(/(\band\b)/gi, 'AND')
    .replace(/(\bor\b)/gi, 'OR')
    .replace(/\（/gi, '(')
    .replace(/\）/gi, ')');
}
