var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patsnap-biz-sequence-viewer" },
    [
      _c("div", { staticClass: "sequence-viewer" }, [
        _c(
          "div",
          {
            staticClass: "sequence-area",
            style: { "max-height": _vm.maxHeight },
          },
          [
            _c(
              "ul",
              { staticClass: "l-scale" },
              [
                _c("li", { staticClass: "scale-baffle" }),
                _vm._l(_vm.sequencesProcessed, function (data, index) {
                  return _c("li", { key: index }, [
                    _vm._v(" " + _vm._s(data.start) + " "),
                  ])
                }),
              ],
              2
            ),
            _c("div", { staticClass: "con-box" }, [
              _c(
                "div",
                {
                  staticClass: "sequences",
                  on: {
                    mouseup: function ($event) {
                      $event.stopPropagation()
                      return _vm.getSelectionEnd.apply(null, arguments)
                    },
                    mousedown: _vm.getSelectionStart,
                  },
                },
                [
                  _vm.sequencesProcessed.length
                    ? _c(
                        "ul",
                        { staticClass: "up-scale" },
                        _vm._l(_vm.upScales, function (s, index) {
                          return _c(
                            "li",
                            { key: index, style: { left: s.pos + "%" } },
                            [_vm._v(" " + _vm._s(s.val) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "sequences-container",
                      on: {
                        click: _vm.handleSearchDomain,
                        "!mouseenter": function ($event) {
                          return _vm.handleMouseEnter.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._l(
                        _vm.segmentsAfterProcessed,
                        function (item, index) {
                          return [
                            !_vm.enableHover || !item.highlightNos.length
                              ? _c("VNode", {
                                  key: index,
                                  style: { color: item.useHighlightColor },
                                  attrs: { nodes: _vm.cachedSequences[index] },
                                })
                              : _c(
                                  "span",
                                  { key: index, staticClass: "highlight-area" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "highlight-hover-tooltip",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "highlight-hover-tooltip__inner",
                                            attrs: {
                                              "data-no": item.useHighLight,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.hoverTooltip
                                                    ? _vm.hoverTooltip(
                                                        item.useHighLight
                                                      )
                                                    : ""
                                                ) +
                                                "：" +
                                                _vm._s(_vm.searchButtonText)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c("VNode", {
                                      style: { color: item.useHighlightColor },
                                      attrs: {
                                        nodes: _vm.cachedSequences[index],
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        }
                      ),
                      _vm.singleRowPadding
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.singleRowPadding),
                            },
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "ul",
                    { staticClass: "r-scale" },
                    [
                      _c("li", { staticClass: "scale-baffle" }),
                      _vm._l(_vm.sequencesProcessed, function (data, index) {
                        return _c("li", { key: index }, [
                          _vm._v(" " + _vm._s(data.end) + " "),
                        ])
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
            _vm.searchBtnVisible && _vm.shouldShowSearchBtn
              ? _c(
                  "ElButton",
                  {
                    staticClass: "search-btn",
                    style: {
                      top: _vm.searchTop + "px",
                      left: _vm.searchLeft + "px",
                    },
                    on: { click: _vm.search },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("seqViews.searchBtn")) + " ")]
                )
              : _vm._e(),
          ],
          1
        ),
        !_vm.sequence
          ? _c("div", { staticClass: "no-sequence" }, [
              _vm._v(" " + _vm._s(_vm.$t("seqViews.noSequence")) + " "),
            ])
          : _vm._e(),
      ]),
      _vm.hoverOptions && _vm.hoverOptions.highlightTarget
        ? _c(
            "ElPopover",
            {
              key: _vm.hoverKey,
              ref: "popover",
              attrs: {
                trigger: "hover",
                placement: "top-start",
                "visible-arrow": false,
                reference: _vm.popoverReference,
                "popper-class": "patsnap-biz-sequence_viewer__popover",
              },
              model: {
                value: _vm.popoverVisible,
                callback: function ($$v) {
                  _vm.popoverVisible = $$v
                },
                expression: "popoverVisible",
              },
            },
            [
              _c(
                "div",
                { staticClass: "patsnap-biz-sequence_viewer__popover-spacer" },
                [
                  _vm._t(
                    "popover",
                    function () {
                      return [
                        _c("VNode", {
                          attrs: { nodes: _vm.computedHoverOptions.node },
                        }),
                      ]
                    },
                    null,
                    _vm.computedHoverOptions
                  ),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }