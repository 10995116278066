/**
 * 这个文件只是为了测试转换到PHS的Query接口定义
 * 把PHS的Query相关的一些定义拿了过来，并做了一些修改
 *
 */

export interface IQuery {
  type: 'group';
  must?: IQueryItem[];
  any?: IQueryItem[];
  filter?: IQueryItem[];
  /**
   * 是否隐藏此检索条件
   */
  hidden?: boolean;
  value?: IQueryItem[];
}

export const enum E_QUERY_ITEM_CONDITION {
  /** 条件必须满足所有 */
  ALL = 'all',
  /** 满足任何一个就可以 */
  ANY = 'any',
  /** none of these items */
  NONE = 'none',
}

interface IQueryItemCommon {
  condition?: E_QUERY_ITEM_CONDITION;
  /**
   * 是否隐藏此检索条件
   */
  hidden?: boolean;
}

export type ISearchStrategy =
  | 'ID_ROLLUP'
  | 'ID'
  | 'KEYWORD'
  | 'ANS_ID'
  | 'ANS_ID_ROLLUP'
  | 'ANS_KEYWORD';

export interface IQueryValueLang {
  /**
   * 英文展示名字
   */
  display_name_en: string;
  /**
   * 中文展示名字
   */
  display_name_cn: string;
}
export interface IQueryValueHidden {
  /**
   * 此条件是否对用户可见
   */
  hidden?: boolean;
}

export interface IQueryValueText extends IQueryValueLang, IQueryValueHidden {
  /**
   * 类型: 文本
   */
  type: 'text';
  /**
   * 文本的值
   */
  value: string;
  /**
   * 检索策略
   */
  search_strategy?: ISearchStrategy;
}

export type IQueryValue = IQueryValueText;

export interface IQueryItemField extends IQueryItemCommon {
  type: 'field';
  fields: string[];
  value: Array<IQueryValue>;
}

export type IQueryItem = IQueryItemField | IQuery;
