var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patsnap-biz-user-setting-container" }, [
    _vm.getStatus("collapse")
      ? _c(
          "div",
          {
            staticClass: "setting-item clearfix",
            class: {
              "setting-item__disabled": _vm.getDisabledStatus("collapse"),
            },
          },
          [
            _c("div", { staticClass: "setting-item-left" }, [
              _c(
                "label",
                { staticClass: "setting-item-label" },
                [
                  _vm._v(" " + _vm._s(_vm.i18nData.resultDisplay) + " "),
                  _c(
                    "ElPopover",
                    {
                      attrs: {
                        placement: "bottom-start",
                        "visible-arrow": false,
                        width: "388",
                        trigger: "hover",
                        "popper-class":
                          "user-setting__popover user-setting__popover-search",
                      },
                    },
                    [
                      _vm._l(_vm.settingConfig.fieldsList, function (item) {
                        return _c("div", { key: item, staticClass: "panel" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("app.userSetting['" + item + "']")
                              ) + ":"
                            ),
                          ]),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("app.userSetting['" + item + "Tips']")
                              ) +
                              " "
                          ),
                        ])
                      }),
                      _c("PtIcon", {
                        staticClass: "hover-icon",
                        staticStyle: { "font-size": "24px", fill: "#B3BAC5" },
                        attrs: { slot: "reference", icon: "SolidInfo" },
                        slot: "reference",
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "setting-item-right" }, [
              _c(
                "ul",
                { staticClass: "search-model-list" },
                _vm._l(_vm.settingConfig.fieldsList, function (item) {
                  return _c(
                    "li",
                    { key: item },
                    [
                      _c(
                        "ElRadio",
                        {
                          attrs: { label: item },
                          model: {
                            value: _vm.settingData.collapse.field,
                            callback: function ($$v) {
                              _vm.$set(_vm.settingData.collapse, "field", $$v)
                            },
                            expression: "settingData.collapse.field",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.i18nData[item]) + " ")]
                      ),
                      _vm.settingData.collapse.field === item && item !== "ALL"
                        ? _c("Representative", {
                            attrs: {
                              collapse: _vm.getCurrentCollapse(item),
                              "service-area": _vm.serviceArea,
                              "model-type": item,
                            },
                            on: { updateCollapse: _vm.updateCollapse },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm.getStatus("family")
      ? _c("div", { staticClass: "setting-item clearfix" }, [
          _c("div", { staticClass: "setting-item-left" }, [
            _c("label", { staticClass: "setting-item-label" }, [
              _vm._v(_vm._s(_vm.$t("app.userSetting.tagFamily"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "setting-item-right" },
            [
              _c(
                "ElSelect",
                {
                  staticClass: "setting-item-selector",
                  attrs: { "popper-class": "popper__no-arrow" },
                  model: {
                    value: _vm.settingData.tagFamilyType,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingData, "tagFamilyType", $$v)
                    },
                    expression: "settingData.tagFamilyType",
                  },
                },
                _vm._l(
                  _vm.settingConfig.tagFamilyTypeList,
                  function (item, idx) {
                    return _c("ElOption", {
                      key: idx,
                      attrs: {
                        label: _vm.$t("app.userSetting." + item),
                        value: item,
                      },
                    })
                  }
                ),
                1
              ),
              _c(
                "ElSelect",
                {
                  staticClass: "setting-item-selector",
                  attrs: { "popper-class": "popper__no-arrow" },
                  model: {
                    value: _vm.settingData.tagFamily,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingData, "tagFamily", $$v)
                    },
                    expression: "settingData.tagFamily",
                  },
                },
                _vm._l(_vm.settingConfig.tagFamilyList, function (item, idx) {
                  return _c("ElOption", {
                    key: idx,
                    attrs: {
                      label: _vm.$t("app.userSetting." + item),
                      value: item,
                    },
                  })
                }),
                1
              ),
              _c(
                "ElPopover",
                {
                  attrs: {
                    placement: "bottom-end",
                    "visible-arrow": false,
                    width: "388",
                    trigger: "hover",
                    "popper-class":
                      "user-setting__popover user-setting__popover-country",
                  },
                },
                [
                  _c("div", { staticClass: "single-panel" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("app.userSetting.searchTagFamilyMsg")) +
                        " "
                    ),
                  ]),
                  _c("PtIcon", {
                    staticClass: "hover-icon",
                    staticStyle: { "font-size": "24px", fill: "#B3BAC5" },
                    attrs: { slot: "reference", icon: "SolidInfo" },
                    slot: "reference",
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.getStatus("language")
      ? _c("div", { staticClass: "setting-item clearfix" }, [
          _c("div", { staticClass: "setting-item-left" }, [
            _c("label", { staticClass: "setting-item-label" }, [
              _vm._v(_vm._s(_vm.$t("app.userSetting.languageDisplay"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "setting-item-right" },
            [
              _c(
                "ElSelect",
                {
                  staticClass: "setting-item-selector",
                  attrs: { "popper-class": "popper__no-arrow" },
                  model: {
                    value: _vm.settingData.language,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingData, "language", $$v)
                    },
                    expression: "settingData.language",
                  },
                },
                _vm._l(_vm.languageList, function (item, idx) {
                  return _c("ElOption", {
                    key: idx,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm.settingData.language !== "default" &&
              _vm.getStatus("originText")
                ? _c(
                    "label",
                    { staticClass: "language__original" },
                    [
                      _c(
                        "PtCheckbox",
                        {
                          model: {
                            value: _vm.settingData.originText,
                            callback: function ($$v) {
                              _vm.$set(_vm.settingData, "originText", $$v)
                            },
                            expression: "settingData.originText",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("app.userSetting.showOriginalText")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "ElPopover",
                        {
                          attrs: {
                            placement: "right",
                            "visible-arrow": false,
                            width: "388",
                            trigger: "hover",
                            "popper-class":
                              "user-setting__popover user-setting__popover-family",
                          },
                        },
                        [
                          _c("div", { staticClass: "single-panel" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("app.userSetting.showOriginalTextTip")
                              )
                            ),
                          ]),
                          _c("PtIcon", {
                            staticClass: "hover-icon",
                            staticStyle: {
                              "font-size": "24px",
                              fill: "#B3BAC5",
                            },
                            attrs: { slot: "reference", icon: "SolidInfo" },
                            slot: "reference",
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.getStatus("stem")
      ? _c(
          "div",
          {
            staticClass: "setting-item clearfix",
            class: { "setting-item__disabled": _vm.getDisabledStatus("stem") },
          },
          [
            _c("div", { staticClass: "setting-item-left" }, [
              _c("label", { staticClass: "setting-item-label" }, [
                _vm._v(_vm._s(_vm.$t("app.userSetting.stemming"))),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "setting-item-right" },
              [
                _vm._l(_vm.settingConfig.stemList, function (item, index) {
                  return _c(
                    "ElRadio",
                    {
                      key: index,
                      attrs: { label: item },
                      model: {
                        value: _vm.settingData.stem,
                        callback: function ($$v) {
                          _vm.$set(_vm.settingData, "stem", $$v)
                        },
                        expression: "settingData.stem",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("app.userSetting." + (item ? "on" : "off"))
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                _c(
                  "ElPopover",
                  {
                    attrs: {
                      placement: "bottom-start",
                      "visible-arrow": false,
                      width: "388",
                      trigger: "hover",
                      "popper-class":
                        "user-setting__popover user-setting__popover-stemming",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "single-panel" },
                      _vm._l(4, function (i) {
                        return _c("p", { key: i }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("app.userSetting.stemmingMsg" + i)
                              ) +
                              " "
                          ),
                        ])
                      }),
                      0
                    ),
                    _c("PtIcon", {
                      staticClass: "hover-icon",
                      staticStyle: { "font-size": "24px", fill: "#B3BAC5" },
                      attrs: { slot: "reference", icon: "SolidInfo" },
                      slot: "reference",
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }