var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s2w-workspace-container" }, [
    _c(
      "div",
      { staticClass: "s2w-workspace-group-list" },
      [
        _vm._l(_vm.groups, function (group) {
          return [
            group.workspaces.length
              ? _c(
                  "div",
                  {
                    key: group.type,
                    staticClass: "s2w-workspace-group-item",
                    on: {
                      click: function ($event) {
                        group.expand = !group.expand
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "s2w-workspace-group-item-pane" },
                      [
                        _c("PtIcon", {
                          staticClass: "s2w-workspace-group-expand-icon",
                          staticStyle: { "font-size": "24px" },
                          attrs: {
                            icon: group.expand
                              ? "SolidDropdownOpen"
                              : "SolidDropdownRight",
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "BaseSaveToWorkspace.FolderSelector.workspaceGroupType." +
                                  group.type
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    group.expand
                      ? _c(
                          "div",
                          {
                            staticClass: "s2w-workspace-group-workspace-item",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          _vm._l(group.workspaces, function (workspace) {
                            return _c("WorkspaceItem", {
                              key: workspace.space_id,
                              attrs: { workspace: workspace },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _vm.selectedWorkspace
      ? _c(
          "div",
          { staticClass: "s2w-workspace-folder-list" },
          [
            _vm.isFolderLoading
              ? _c(
                  "div",
                  { staticClass: "loading-pane" },
                  [
                    _c("PtIcon", {
                      staticStyle: { "font-size": "48px" },
                      attrs: { icon: "SolidLoadingBars" },
                    }),
                  ],
                  1
                )
              : [
                  _vm.selectedWorkspace.folderTreeData &&
                  _vm.selectedWorkspace.folderTreeData.length
                    ? _c("FolderTree", {
                        attrs: {
                          folders: _vm.selectedWorkspace.folderTreeData,
                        },
                      })
                    : _c(
                        "div",
                        { staticClass: "no-data-pane" },
                        [
                          _c("PtIcon", {
                            staticStyle: { "font-size": "40px" },
                            attrs: { icon: "SolidNoDataTip" },
                          }),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "BaseSaveToWorkspace.FolderSelector.noData.folder"
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }