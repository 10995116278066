/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
import { toLower } from 'lodash';
import BasicService from '../Basic/BasicService';

class DrugService extends BasicService {
    constructor() {
        super();
        this.type = 'DRUG';
        this.type_alias = 'drug';
    }

    handleJumpToWorkspaceLink({
        folder_id,
        // folder_name,
        space_id,
        source_type,
        data_type,
    }) {
        const defaultWorkspaceViewType = this.type_alias || data_type || this.type || 'PATENT';
        const query = `?spaceId=${space_id}&folderId=${folder_id}&page=1&from=${source_type || ''}`;
        const base = `${this.workspaceProductDomain}/detail/${toLower(defaultWorkspaceViewType)}`;

        return base + query;
    }

    getWorkspaceElementsCountString(workspace) {
        const { total_drug_count = 0 } = workspace;
        const count = this.formatNumber(total_drug_count);
        return `(${count})`;
    }

    getFolderElementsCountString({ folder }) {
        const { drug_count = 0 } = folder;
        return `(${this.formatNumber(drug_count)})`;
    }

    getSubmitApiConfig() {
        const { selectedWorkspace: { space_id }, selectedFolder: { folder_id } } = this.folderSelector;
        return {
            url: `${this.baseUrl}/space/${space_id}/folder/${folder_id}/save-common-data`,
            method: 'POST',
        };
    }
}

export default DrugService;
