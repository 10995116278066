import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

export function listToTree(list, rootValue, idKey, parentIdKey, childrenKeyString = 'children') {
    const cloneList = cloneDeep(list);
    const tree = cloneList.filter((father) => {
        const branchList = cloneList.filter((child) => father[idKey] === child[parentIdKey]);
        if (branchList.length > 0) {
            /* eslint-disable no-param-reassign */
            father[childrenKeyString] = branchList;
        }
        return father[parentIdKey] === rootValue;
    });
    return tree;
}

export function treeToListByDFS(
    tree = [],
    childrenKeyString = 'children',
    selfKeyString = 'id',
    parentKeyString = 'parent',
    rootValueString = '-root',
) {
    const list = [];
    function deepUnfold(nodes, parentKey) {
        forEach(nodes, (item) => {
            list.push(item);
            item[parentKeyString] = parentKey;
            if (item[childrenKeyString] && item[childrenKeyString].length) {
                deepUnfold(item[childrenKeyString], item[selfKeyString]);
            }
        });
        return list;
    }
    return deepUnfold(tree, rootValueString);
}

export function scrollIntoViewIfNeeded(el) {
    if (!el) return false;

    if (el.scrollIntoViewIfNeeded) {
        el.scrollIntoViewIfNeeded();
        return true;
    }

    el.scrollIntoView();
    return true;
}
