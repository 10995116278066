var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "s2w-workspace-item",
      class: {
        "is-current": _vm.isCurrentWorkspace,
        "is-editing": _vm.workspace.isEditing,
      },
    },
    [
      _c(
        "div",
        {
          ref: "workspaceInfoRef",
          staticClass: "s2w-workspace-item-info",
          attrs: { "data-space-id": _vm.workspace.space_id },
          on: {
            click: function ($event) {
              return _vm.selectWorkspace(_vm.workspace)
            },
          },
        },
        [
          _c("PtIcon", {
            staticClass: "workspace-icon",
            staticStyle: { "font-size": "24px" },
            attrs: { icon: _vm.service.getWorkspaceIcon(_vm.workspace) },
          }),
          _c(
            "div",
            { staticClass: "workspace-name-container" },
            [
              _vm.workspace.isEditing
                ? _c(
                    "div",
                    {
                      staticClass: "workspace-name-editor",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.workspace.space_name,
                            expression: "workspace.space_name",
                          },
                        ],
                        ref: "workspaceNameInputRef",
                        class: {
                          error: !_vm.service.isWorkspaceNameValid(
                            _vm.workspace
                          ),
                        },
                        attrs: {
                          type: "text",
                          maxlength: "40",
                          disabled: _vm.workspace.isUpdating,
                        },
                        domProps: { value: _vm.workspace.space_name },
                        on: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onKeypressEnter.apply(null, arguments)
                          },
                          blur: function ($event) {
                            return _vm.service.finishEditWorkspace(
                              _vm.workspace
                            )
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.workspace,
                              "space_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  )
                : [
                    _c("div", {
                      staticClass: "workspace-name",
                      attrs: { title: _vm.titleText },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.service.renderSpaceName(_vm.workspace)
                        ),
                      },
                      on: {
                        dblclick: function ($event) {
                          return _vm.service.editWorkspace(_vm.workspace)
                        },
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "workspace-elements-count",
                        attrs: { title: _vm.titleText },
                      },
                      [_vm._v(" " + _vm._s(_vm.countText) + " ")]
                    ),
                  ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "workspace-item-action-pane" },
            [
              _vm.service.hasEditWorkspacePermission(_vm.workspace)
                ? _c("PtIcon", {
                    staticClass: "workspace-item-action-item",
                    staticStyle: { "font-size": "24px" },
                    attrs: { icon: "SolidEdit" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.service.editWorkspace(_vm.workspace)
                      },
                    },
                  })
                : _vm._e(),
              _vm.service.hasAddWorkspaceFolderPermission(_vm.workspace)
                ? _c("PtIcon", {
                    staticClass: "workspace-item-action-item",
                    staticStyle: { "font-size": "24px" },
                    attrs: { icon: "SolidFolderAdd" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.addFolder(_vm.workspace)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }