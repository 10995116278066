import Lazy from './Lazy.vue';
import directive from './directive';
import mixin from './mixin';

/* istanbul ignore next */
Lazy.install = (Vue) => {
    Vue.component(Lazy.name, Lazy);
    Vue.use('lazy', directive);
};
Lazy.directive = directive;
Lazy.mixin = mixin;

export default Lazy;
