var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s2w-auto-update-selector-container" }, [
    _c("div", { staticClass: "s2w-auto-update-label" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("BaseSaveToWorkspace.AutoUpdate.otherSettingLabel")) +
          " "
      ),
    ]),
    _c(
      "div",
      {
        staticClass: "s2w-auto-update",
        class: {
          disabled: _vm.disabled,
        },
      },
      [
        _c("div", {
          staticClass: "s2w-auto-update-switch",
          class: { enable: _vm.enable },
          on: {
            click: function ($event) {
              return _vm.toggleAutoUpdate()
            },
          },
        }),
        _c("div", { staticClass: "s2w-auto-update-desc" }, [
          _c("span", [_vm._v(_vm._s(_vm.AutoUpdateLabel))]),
        ]),
        _c(
          "div",
          {
            staticClass: "s2w-auto-update-info",
            attrs: {
              "aria-label": _vm.AutoUpdateLabelTip,
              "data-balloon-pos": "up",
              "data-balloon-white": "",
            },
          },
          [
            _c("PtIcon", {
              staticStyle: { "font-size": "24px" },
              attrs: { icon: "SolidInfo" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "s2w-auto-update-query-container" }, [
          _c(
            "div",
            {
              staticClass: "s2w-auto-update-query-link",
              on: {
                click: function ($event) {
                  _vm.showQueryPopper = !_vm.showQueryPopper
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("BaseSaveToWorkspace.AutoUpdate.viewQuery")) +
                  " "
              ),
            ]
          ),
          _vm.showQueryPopper
            ? _c(
                "div",
                {
                  staticClass: "s2w-auto-update-query-popper",
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [
                  _c("div", { staticClass: "s2w-auto-update-query-content" }, [
                    _vm._v(" " + _vm._s(_vm.autoUpdate.q) + " "),
                  ]),
                  _vm.autoUpdate.efq
                    ? _c(
                        "div",
                        {
                          staticClass: "s2w-auto-update-query-content s2w-efq",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("BaseSaveToWorkspace.AutoUpdate.refine")
                              ) +
                              ": " +
                              _vm._s(_vm.autoUpdate.efq) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }