import { SyntaxTree } from '../../Values/SyntaxTree';
import { Node } from '../../Values/Node';
import {
  Group,
  LogicalAnd,
  LogicalNot,
  LogicalOr,
  Mandatory,
  Prohibited,
  Query,
  Term,
} from '../Values/Node';
import { Phrase, Tag, User, Word } from '../Values/Token';
import {
  E_QUERY_ITEM_CONDITION,
  IQueryItem,
  IQueryItemField,
  IQueryValueText,
} from '../../interface/query';

/**
 * SyntaxTree的数据转PharmSnap的Query结构的工具类
 */
export class SyntaxTreeRecognize {
  private static _instance: SyntaxTreeRecognize;

  private _source: string = '';

  public static getInstance(): SyntaxTreeRecognize {
    if (!this._instance) {
      this._instance = new SyntaxTreeRecognize();
    }
    return this._instance;
  }

  public trans2QueryItem(syntaxTree: SyntaxTree) {
    const { rootNode } = syntaxTree;
    this._source = syntaxTree.getSource();
    return this._generateNode(rootNode);
  }

  public getNodeName($node: Node) {
    switch (true) {
      case $node instanceof Term && $node.token instanceof Phrase:
        return 'PHRASE';
      case $node instanceof Term && $node.token instanceof Tag:
        return 'TAG';
      case $node instanceof Term && $node.token instanceof User:
        return 'USER';
      case $node instanceof Term && $node.token instanceof Word:
        return 'WORD';
      case $node instanceof LogicalAnd:
        return 'AND';
      case $node instanceof LogicalOr:
        return 'OR';
      case $node instanceof LogicalNot:
        return 'NOT';
      case $node instanceof Mandatory:
        return 'MANDATORY';
      case $node instanceof Prohibited:
        return 'PROHIBITED';
      case $node instanceof Group:
        return 'GROUP';
      case $node instanceof Query:
        return 'QUERY';
    }

    throw new Error('Did not recognize given node');
  }

  private _generateNode(node: Node): IQueryItem {
    if (node instanceof Query) {
      return this._transQuery(node);
    }
    if (node instanceof Group) {
      return this._transGroup(node);
    }
    if (node instanceof LogicalAnd) {
      return this._transAnd(node);
    }
    if (node instanceof LogicalOr) {
      return this._transOr(node);
    }
    if (node instanceof LogicalNot) {
      return this._transNot(node);
    }

    if (node instanceof Term && node.token instanceof Word) {
      return this._transWord(node.token);
    }
    if (node instanceof Term && node.token instanceof Phrase) {
      return this._transPhrase(node.token);
    }
    throw new Error('无法处理的数据类型');
  }

  private _transAnd(node: LogicalAnd): IQueryItem {
    const left = this._generateNode(node.leftOperand);
    const right = this._generateNode(node.rightOperand);
    return {
      type: 'group',
      must: [
        ...(left.type === 'group' && left.must?.length ? left.must : [left]),
        ...(right.type === 'group' && right.must?.length
          ? right.must
          : [right]),
      ],
    };
  }

  private _transOr(node: LogicalOr): IQueryItem {
    const left = this._generateNode(node.leftOperand);
    const right = this._generateNode(node.rightOperand);

    return {
      type: 'group',
      any: [
        ...(left.type === 'group' && left.any?.length ? left.any : [left]),
        ...(right.type === 'group' && right.any?.length ? right.any : [right]),
      ],
    };
  }

  private _transNot(node: LogicalNot): IQueryItem {
    const operand = this._generateNode(node.operand);

    function processQuery(query: IQueryItem) {
      if (query.type === 'field') {
        query.condition = E_QUERY_ITEM_CONDITION.NONE;
      } else if (query.type === 'group') {
        if (query.must) {
          query.must.forEach((i) => {
            processQuery(i);
          });
        }
        if (query.any) {
          query.any.forEach((i) => {
            processQuery(i);
          });
        }
      }
    }

    processQuery(operand);

    if (operand.type === 'group' && operand.must) {
      return {
        type: 'group',
        must: [...operand.must],
      };
    }

    if (operand.type === 'group' && operand.any) {
      return {
        type: 'group',
        any: [...operand.any],
      };
    }

    return operand;
  }

  private _transWord(token: Word): IQueryItemField {
    const item: IQueryValueText = {
      type: 'text',
      value: token.word,
      display_name_cn: token.word,
      display_name_en: token.word,
    };
    return {
      type: 'field',
      fields: [],
      value: [item],
    };
  }

  private _transPhrase(token: Phrase): IQueryItemField {
    const item: IQueryValueText = {
      type: 'text',
      value: token.phrase,
      display_name_cn: token.phrase,
      display_name_en: token.phrase,
    };
    return {
      type: 'field',
      fields: [],
      value: [item],
    };
  }

  private _transGroup(nodeGroup: Group): IQueryItem {
    const rt = nodeGroup.getNodes().map((n) => {
      return this._generateNode(n);
    });
    if (rt.length === 1) {
      return rt[0];
    }
    return {
      type: 'group',
      any: rt,
    };
  }

  private _transQuery(node: Query): IQueryItem {
    const nodes = node.getNodes();

    if (nodes.length === 1) {
      return this._generateNode(nodes[0]);
    }

    const hasLogicNot = nodes.some((n) => n instanceof LogicalNot);

    const rt = nodes.map((n) => {
      return this._generateNode(n);
    });

    if (hasLogicNot) {
      return {
        type: 'group',
        must: rt,
      };
    }

    return {
      type: 'group',
      any: rt,
    };
  }
}
