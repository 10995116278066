import BasicService from './Basic/BasicService';
import FolderSelector from './Basic/components/FolderSelector/FolderSelector';

import PaperService from './Paper/PaperService';
import PatentService from './Patent/PatentService';
import SequenceService from './Sequence/SequenceService';
import SequenceSubjectService from './SequenceSubject/SequenceSubjectService';

import SavePaperToWorkspace from './Paper/Paper.vue';
import SavePatentToWorkspace from './Patent/Patent.vue';
import SequenceSubject from './SequenceSubject/SequenceSubject.vue';

import VCService from './VC/VCService';
import SaveVCToWorkspace from './VC/VC.vue';

import MAService from './MA/MAService';
import SaveMAToWorkspace from './MA/MA.vue';

import MRService from './MR/MRService';
import SaveMRToWorkspace from './MR/MR.vue';

import DrugService from './Drug/DrugService';
import SaveDrugToWorkspace from './Drug/Drug.vue';

import ClinicalService from './Clinical/ClinicalService';
import SaveClinicalToWorkspace from './Clinical/Clinical.vue';

export default {
    BasicService,

    PaperService,
    SavePaperToWorkspace,

    PatentService,
    SavePatentToWorkspace,

    SequenceService,
    SaveSequenceToWorkspace: SavePatentToWorkspace,

    SequenceSubjectService,
    SaveSequenceSubjectToWorkspace: SequenceSubject,

    FolderSelector,

    VCService,
    SaveVCToWorkspace,

    MAService,
    SaveMAToWorkspace,

    MRService,
    SaveMRToWorkspace,

    DrugService,
    SaveDrugToWorkspace,

    ClinicalService,
    SaveClinicalToWorkspace,
};
