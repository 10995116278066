import {
  CORRECTION_ADJACENT_UNARY_OPERATOR_PRECEDING_OPERATOR_IGNORED,
  CORRECTION_BAILOUT_TOKEN_IGNORED,
  CORRECTION_BINARY_OPERATOR_FOLLOWING_OPERATOR_IGNORED,
  CORRECTION_BINARY_OPERATOR_MISSING_LEFT_OPERAND_IGNORED,
  CORRECTION_BINARY_OPERATOR_MISSING_RIGHT_OPERAND_IGNORED,
  CORRECTION_EMPTY_GROUP_IGNORED,
  CORRECTION_UN_SUPPORT_EMPTY_PHRASE,
  CORRECTION_LOGICAL_NOT_OPERATORS_PRECEDING_PREFERENCE_IGNORED,
  CORRECTION_UNARY_OPERATOR_MISSING_OPERAND_IGNORED,
  CORRECTION_UNMATCHED_GROUP_LEFT_DELIMITER_IGNORED,
  CORRECTION_UNMATCHED_GROUP_RIGHT_DELIMITER_IGNORED,
  CORRECTION_UN_SUPPORT_DOMAIN,
  TOKEN_BAILOUT,
  TOKEN_GROUP_BEGIN,
  TOKEN_GROUP_END,
  TOKEN_LOGICAL_AND,
  TOKEN_LOGICAL_NOT,
  TOKEN_LOGICAL_NOT_2,
  TOKEN_LOGICAL_OR,
  TOKEN_MANDATORY,
  TOKEN_PROHIBITED,
  TOKEN_TERM,
  TOKEN_WHITESPACE,
  CORRECTION_UN_SUPPORT_LOGIC_NOT,
  CORRECTION_UN_SUPPORT_WORD_LENGTH,
  CORRECTION_UN_SUPPORT_FIRST_NOT_LOGICAL,
  CORRECTION_UN_SUPPORT_NESTED_GROUP,
  CORRECTION_UN_SUPPORT_WORDS_WITHOUT_PHRASE,
} from '../constants';
import { Parsing } from '../Parsing';
import { Correction } from '../Values/Correction';
import { SyntaxTree } from '../Values/SyntaxTree';
import { Node } from '../Values/Node';
import { Token } from '../Values/Token';
import { TokenSequence } from '../Values/TokenSequence';
import { Group } from './Values/Node/Group';
import {
  LogicalAnd,
  LogicalNot,
  LogicalOr,
  Mandatory,
  Prohibited,
  Term,
  Query,
} from './Values/Node';
import { Phrase, Word } from './Values/Token';
import { ERROR_TOKEN_TYPE } from '../interface/error';
import { isEmpty, isNumber } from '../utils/common';

type ITokenShortcutType =
  | 'operatorNot'
  | 'operatorPreference'
  | 'operatorPrefix'
  | 'operatorUnary'
  | 'operatorBinary'
  | 'operator'
  | 'groupDelimiter'
  | 'binaryOperatorAndWhitespace';

/**
 * Galach implementation of the Parsing interface.
 */
export class Parser implements Parsing {
  private tokenShortcuts = new Map<ITokenShortcutType, number>([
    ['operatorNot', TOKEN_LOGICAL_NOT | TOKEN_LOGICAL_NOT_2],
    ['operatorPreference', TOKEN_MANDATORY | TOKEN_PROHIBITED],
    [
      'operatorPrefix',
      TOKEN_MANDATORY | TOKEN_PROHIBITED | TOKEN_LOGICAL_NOT_2,
    ],
    [
      'operatorUnary',
      TOKEN_MANDATORY |
        TOKEN_PROHIBITED |
        TOKEN_LOGICAL_NOT |
        TOKEN_LOGICAL_NOT_2,
    ],
    ['operatorBinary', TOKEN_LOGICAL_AND | TOKEN_LOGICAL_OR],
    [
      'operator',
      TOKEN_LOGICAL_AND |
        TOKEN_LOGICAL_OR |
        TOKEN_MANDATORY |
        TOKEN_PROHIBITED |
        TOKEN_LOGICAL_NOT |
        TOKEN_LOGICAL_NOT_2,
    ],
    ['groupDelimiter', TOKEN_GROUP_BEGIN | TOKEN_GROUP_END],
    [
      'binaryOperatorAndWhitespace',
      TOKEN_LOGICAL_AND | TOKEN_LOGICAL_OR | TOKEN_WHITESPACE,
    ],
  ]);

  private shifts: Record<number, (token: Token) => any> = {
    [TOKEN_WHITESPACE]: this.shiftWhitespace,
    [TOKEN_TERM]: this.shiftTerm,
    [TOKEN_GROUP_BEGIN]: this.shiftGroupBegin,
    [TOKEN_GROUP_END]: this.shiftGroupEnd,
    [TOKEN_LOGICAL_AND]: this.shiftBinaryOperator,
    [TOKEN_LOGICAL_OR]: this.shiftBinaryOperator,
    [TOKEN_LOGICAL_NOT]: this.shiftLogicalNot,
    [TOKEN_LOGICAL_NOT_2]: this.shiftLogicalNot2,
    [TOKEN_MANDATORY]: this.shiftPreference,
    [TOKEN_PROHIBITED]: this.shiftPreference,
    [TOKEN_BAILOUT]: this.shiftBailout,
  };

  private reductionGroups: Record<string, any[]> = {
    group: [
      this.reduceGroup,
      this.reducePreference,
      this.reduceLogicalNot,
      this.reduceLogicalAnd,
      this.reduceLogicalOr,
    ],
    unaryOperator: [
      this.reduceLogicalNot,
      this.reduceLogicalAnd,
      this.reduceLogicalOr,
    ],
    logicalOr: [],
    logicalAnd: [this.reduceLogicalOr],
    term: [
      this.reducePreference,
      this.reduceLogicalNot,
      this.reduceLogicalAnd,
      this.reduceLogicalOr,
    ],
  };

  /**
   * Input tokens.
   *
   */
  private tokens: Token[] = [];

  /**
   * Query stack.
   *
   */
  private stack: any[] = [];

  /**
   * An array of applied corrections.
   *
   */
  private corrections: Correction[] = [];

  public parse(tokenSequence: TokenSequence) {
    this.init(tokenSequence.tokens);

    while (!isEmpty(this.tokens)) {
      const node = this.shift();
      if (node instanceof Node) {
        this.reduce(node);
      }
    }

    this.reduceQuery();
    return new SyntaxTree(
      this.stack[this.stack.length - 1],
      tokenSequence,
      this.corrections,
    );
  }

  private shift() {
    const token = this.tokens.shift();
    if (token) {
      const shiftFunc = this.shifts[token.type];

      return shiftFunc.call(this, token);
    }
    return;
  }

  private reduce(node: Node) {
    let previousNode = null;
    let reductionIndex = null;

    while (node instanceof Node) {
      // Reset reduction index on first iteration or on Node change
      if (node !== previousNode) {
        reductionIndex = 0;
      }

      // If there are no reductions to try, put the Node on the stack
      // and continue shifting
      const reduction = this.getReduction(node, reductionIndex);
      if (!reduction) {
        this.stack.push(node);
        break;
      }
      previousNode = node;
      node = reduction.call(this, node);
      if (isNumber(reductionIndex)) {
        ++reductionIndex;
      }
    }
  }

  protected shiftWhitespace() {
    if (this.isTopStackToken(this.tokenShortcuts.get('operatorPrefix'))) {
      const token = this.stack.pop() as Token;
      token.errType = ERROR_TOKEN_TYPE.UNARY_OPERATOR_MISSING_OPERAND_IGNORED;
      this.addCorrection(CORRECTION_UNARY_OPERATOR_MISSING_OPERAND_IGNORED, [
        token,
      ]);
    }
  }

  protected shiftPreference($token: Token) {
    return this.shiftAdjacentUnaryOperator(
      $token,
      this.tokenShortcuts.get('operator'),
    );
  }

  protected shiftAdjacentUnaryOperator(token: Token, tokenMask?: number) {
    if (this.isToken(this.tokens[0], tokenMask)) {
      token.errType =
        ERROR_TOKEN_TYPE.ADJACENT_UNARY_OPERATOR_PRECEDING_OPERATOR_IGNORED;
      this.addCorrection(
        CORRECTION_ADJACENT_UNARY_OPERATOR_PRECEDING_OPERATOR_IGNORED,
        [token],
      );

      return null;
    }
    this.stack.push(token);
  }

  /**
   * 暂不支持not
   * @param token
   * @returns
   */
  protected shiftLogicalNot(token: Token) {
    if (!this.stack.length || this.isTopStackToken(TOKEN_LOGICAL_NOT)) {
      token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_FIRST_NOT_LOGICAL;
      this.addCorrection(CORRECTION_UN_SUPPORT_FIRST_NOT_LOGICAL, [token]);

      return null;
    }

    // token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_LOGIC_NOT;
    // this.addCorrection(CORRECTION_UN_SUPPORT_LOGIC_NOT, [token]);
    // return null;
    this.stack.push(token);
  }

  /**
   * 暂不支持 !
   * @param token
   * @returns
   */
  protected shiftLogicalNot2(token: Token) {
    token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_LOGIC_NOT;
    this.addCorrection(CORRECTION_UN_SUPPORT_LOGIC_NOT, [token]);
    return null;

    // const tokenMask =
    //   (this.tokenShortcuts.get('operator') as number) & ~TOKEN_LOGICAL_NOT_2;
    // return this.shiftAdjacentUnaryOperator(token, tokenMask);
  }

  protected shiftBinaryOperator(token: Token) {
    if (!this.stack.length || this.isTopStackToken(TOKEN_GROUP_BEGIN)) {
      token.errType =
        ERROR_TOKEN_TYPE.BINARY_OPERATOR_MISSING_LEFT_OPERAND_IGNORED;
      this.addCorrection(
        CORRECTION_BINARY_OPERATOR_MISSING_LEFT_OPERAND_IGNORED,
        [token],
      );

      return null;
    }

    if (this.isTopStackToken(this.tokenShortcuts.get('operator'))) {
      this.ignoreBinaryOperatorFollowingOperator(token);

      return null;
    }
    this.stack.push(token);
  }

  /**
   * 连续的操作词错误
   * @param token
   */
  private ignoreBinaryOperatorFollowingOperator(token: Token) {
    const precedingOperators = this.ignorePrecedingOperators(
      this.tokenShortcuts.get('operator'),
    );
    const followingOperators = this.ignoreFollowingOperators();

    // 前面的肯定是因为连续逻辑词出错
    // precedingOperators.forEach((t) => {
    //   t.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_CONSECUTIVE_LOGICAL_WORDS;
    // });
    // // 后面如果还有操作词&&stack还没有空
    // if (isEmpty(followingOperators) && isEmpty(this.stack)) {
    //   token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_END_WITH_LOGICAL_WORDS;
    // } else {
    //   token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_CONSECUTIVE_LOGICAL_WORDS;
    //   followingOperators.forEach((t, index) => {
    //     if (index === followingOperators.length - 1) {
    //       t.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_END_WITH_LOGICAL_WORDS;
    //     } else {
    //       t.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_CONSECUTIVE_LOGICAL_WORDS;
    //     }
    //   });
    // }
    const tokens = [...precedingOperators, token, ...followingOperators];
    tokens.forEach((t) => {
      t.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_CONSECUTIVE_LOGICAL_WORDS;
    });

    this.addCorrection(
      CORRECTION_BINARY_OPERATOR_FOLLOWING_OPERATOR_IGNORED,
      tokens,
    );
  }

  /**
   * 为了phs加的一些规则验证
   */
  protected usePhsCheckRules(token: Token) {
    let valid = true;
    // 有domain的word
    if (token instanceof Word && token.domain) {
      token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_DOMAIN;
      this.addCorrection(CORRECTION_UN_SUPPORT_DOMAIN, [token]);
      valid = false;
    }
    // 长度小于2的word
    if (token instanceof Word && token.word.length < 2) {
      token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_WORD_LENGTH;
      this.addCorrection(CORRECTION_UN_SUPPORT_WORD_LENGTH, [token]);
      valid = false;
    }
    // phrase 空字符串
    if (token instanceof Phrase && token.phrase === '') {
      token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_EMPTY_PHRASE;
      this.addCorrection(CORRECTION_UN_SUPPORT_EMPTY_PHRASE, [token]);
      valid = false;
    }
    return valid;
  }

  /**
   * @param token
   * @returns
   */
  protected shiftTerm(token: Token) {
    const valid = this.usePhsCheckRules(token);
    if (!valid) return;
    return new Term(token);
  }

  protected shiftGroupBegin(token: Token) {
    this.stack.push(token);
  }

  protected shiftGroupEnd(token: Token) {
    this.stack.push(token);

    return new Group();
  }

  protected shiftBailout(token: Token) {
    token.errType = ERROR_TOKEN_TYPE.BAILOUT_TOKEN_IGNORED;
    this.addCorrection(CORRECTION_BAILOUT_TOKEN_IGNORED, [token]);
  }

  protected reducePreference(node: Node) {
    if (!this.isTopStackToken(this.tokenShortcuts.get('operatorPreference'))) {
      return node;
    }

    const token = this.stack.pop();

    if (this.isToken(token, TOKEN_MANDATORY)) {
      return new Mandatory(node, token);
    }

    return new Prohibited(node, token);
  }

  protected reduceLogicalNot(node: Node) {
    if (!this.isTopStackToken(this.tokenShortcuts.get('operatorNot'))) {
      return node;
    }

    if (node instanceof Mandatory || node instanceof Prohibited) {
      this.ignoreLogicalNotOperatorsPrecedingPreferenceOperator();

      return node;
    }
    return new LogicalNot(node, this.stack.pop());
  }

  public ignoreLogicalNotOperatorsPrecedingPreferenceOperator() {
    const precedingOperators = this.ignorePrecedingOperators(
      this.tokenShortcuts.get('operatorNot'),
    );

    if (precedingOperators.length) {
      precedingOperators.forEach((t) => {
        t.errType =
          ERROR_TOKEN_TYPE.LOGICAL_NOT_OPERATORS_PRECEDING_PREFERENCE_IGNORED;
      });
      this.addCorrection(
        CORRECTION_LOGICAL_NOT_OPERATORS_PRECEDING_PREFERENCE_IGNORED,
        precedingOperators,
      );
    }
  }

  protected reduceLogicalAnd(node: Node) {
    if (this.stack.length <= 1 || !this.isTopStackToken(TOKEN_LOGICAL_AND)) {
      return node;
    }

    const token = this.stack.pop();
    const leftOperand = this.stack.pop();

    return new LogicalAnd(leftOperand, node, token);
  }

  /**
   * Reduce logical OR.
   *
   * @param bool $inGroup Reduce inside a group
   *
   * @return null|\QueryTranslator\Languages\Galach\Values\Node\LogicalOr|\QueryTranslator\Values\Node
   */
  protected reduceLogicalOr(node: Node, inGroup = false) {
    if (this.stack.length <= 1 || !this.isTopStackToken(TOKEN_LOGICAL_OR)) {
      return node;
    }

    // If inside a group don't look for following logical AND
    if (!inGroup) {
      this.popWhitespace();
      // If the next token is logical AND, put the node on stack
      // as that has precedence over logical OR
      if (this.isToken(this.tokens[0], TOKEN_LOGICAL_AND)) {
        this.stack.push(node);
        return null;
      }
    }

    const token = this.stack.pop();
    const leftOperand = this.stack.pop();

    return new LogicalOr(leftOperand, node, token);
  }

  protected reduceGroup(group: Group) {
    const rightDelimiter = this.stack.pop();
    // Pop dangling tokens
    this.popTokens(~TOKEN_GROUP_BEGIN);

    if (this.isTopStackToken(TOKEN_GROUP_BEGIN)) {
      const leftDelimiter = this.stack.pop();
      this.ignoreEmptyGroup(leftDelimiter, rightDelimiter);
      this.reduceRemainingLogicalOr(true);

      return null;
    }

    this.reduceRemainingLogicalOr(true);

    group.nodes = this.collectTopStackNodes();
    group.tokenLeft = this.stack.pop();
    group.tokenRight = rightDelimiter;

    return group;
  }

  /**
   * Collect all Nodes from the top of the stack.
   *
   */
  private collectTopStackNodes() {
    let nodes: Node[] = [];

    while (
      this.stack.length &&
      this.stack[this.stack.length - 1] instanceof Node
    ) {
      nodes = [this.stack.pop(), ...nodes];
    }

    return nodes;
  }

  /**
   * ()
   * 空的group错误检测
   * @param leftDelimiter
   * @param rightDelimiter
   */
  private ignoreEmptyGroup(leftDelimiter: Token, rightDelimiter: Token) {
    const precedingOperators = this.ignorePrecedingOperators(
      this.tokenShortcuts.get('operator'),
    );
    const followingOperators = this.ignoreFollowingOperators();
    precedingOperators.forEach((operator) => {
      operator.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_USELESS_LOGICAL_WORDS;
    });
    followingOperators.forEach((operator) => {
      operator.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_USELESS_LOGICAL_WORDS;
    });
    leftDelimiter.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_EXTRA_PARENTHESES;
    rightDelimiter.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_EXTRA_PARENTHESES;
    const tokens = [
      ...precedingOperators,
      leftDelimiter,
      rightDelimiter,
      ...followingOperators,
    ];
    this.addCorrection(CORRECTION_EMPTY_GROUP_IGNORED, tokens);
  }

  /**
   * Initialize the parser with given array of $tokens.
   *
   */
  private init(tokens: Token[]) {
    this.corrections = [];
    this.tokens = tokens.concat();

    this.cleanupGroupDelimiters(this.tokens);
    this.stack = [];
  }

  private getReduction(node: Node, reductionIndex?: number | null) {
    const reductionGroup = node.getReductionGroup();
    if (
      isNumber(reductionIndex) &&
      this.reductionGroups[reductionGroup][reductionIndex]
    ) {
      return this.reductionGroups[reductionGroup][reductionIndex];
    }

    return null;
  }

  private reduceQuery() {
    this.popTokens();
    this.reduceRemainingLogicalOr();
    let nodes: Node[] = [];

    while (!isEmpty(this.stack)) {
      nodes = [this.stack.pop(), ...nodes];
    }
    this.stack.push(new Query(nodes));
  }

  /**
   * Check if the given $token is an instance of Token.
   *
   * Optionally also checks given Token $typeMask.
   *
   * @param mixed $token
   * @param int $typeMask
   *
   * @return bool
   */
  private isToken(token: any, typeMask: number | null = null) {
    if (!(token instanceof Token)) {
      return false;
    }

    if (typeMask === null || token.type & typeMask) {
      return true;
    }

    return false;
  }

  private isTopStackToken(type: number | null = null) {
    return (
      !isEmpty(this.stack) &&
      this.isToken(this.stack[this.stack.length - 1], type)
    );
  }

  /**
   * Remove whitespace Tokens from the beginning of the token array.
   */
  private popWhitespace() {
    while (this.isToken(this.tokens[0], TOKEN_WHITESPACE)) {
      this.tokens.shift();
    }
  }

  /**
   * Remove all Tokens from the top of the query stack and log Corrections as necessary.
   *
   * Optionally also checks that Token matches given $typeMask.
   *
   * @param int $typeMask
   * 有一元运算符的时候 错误检测
   */
  private popTokens(typeMask?: number) {
    while (this.isTopStackToken(typeMask)) {
      const token = this.stack.pop() as Token;
      if (!token) return;
      if (token.type & (this.tokenShortcuts.get('operatorUnary') as number)) {
        token.errType = ERROR_TOKEN_TYPE.UNARY_OPERATOR_MISSING_OPERAND_IGNORED;
        this.addCorrection(CORRECTION_UNARY_OPERATOR_MISSING_OPERAND_IGNORED, [
          token,
        ]);
      } else {
        token.errType =
          ERROR_TOKEN_TYPE.BINARY_OPERATOR_MISSING_RIGHT_OPERAND_IGNORED;
        this.addCorrection(
          CORRECTION_BINARY_OPERATOR_MISSING_RIGHT_OPERAND_IGNORED,
          [token],
        );
      }
    }
  }

  private ignorePrecedingOperators(type?: number) {
    let tokens: Token[] = [];
    while (this.isTopStackToken(type)) {
      tokens = [this.stack.pop(), ...tokens];
    }

    return tokens;
  }

  private ignoreFollowingOperators() {
    const tokenMask = this.tokenShortcuts.get('binaryOperatorAndWhitespace');
    const tokens = [];
    while (this.isToken(this.tokens[0], tokenMask)) {
      const token = this.tokens.shift();
      if (!token) return tokens;
      if (token.type & (this.tokenShortcuts.get('operatorBinary') as number)) {
        tokens.push(token);
      }
    }

    return tokens;
  }

  /**
   * Reduce logical OR possibly remaining after reaching end of group or query.
   *
   * @param bool $inGroup Reduce inside a group
   */
  private reduceRemainingLogicalOr(inGroup = false) {
    if (this.stack.length && !this.isTopStackToken()) {
      const node = this.reduceLogicalOr(this.stack.pop(), inGroup);
      this.stack.push(node);
    }
  }

  /**
   * Clean up group delimiter tokens, removing unmatched left and right delimiter.
   *
   * Closest group delimiters will be matched first, unmatched remainder is removed.
   *
   * 括号的错误检测
   */
  private cleanupGroupDelimiters(tokens: Token[]) {
    const indexes = this.getUnmatchedGroupDelimiterIndexes(tokens);

    while (indexes.length) {
      const lastIndex = indexes.pop();
      if (isNumber(lastIndex)) {
        const token = tokens.splice(lastIndex, 1)[0];
        if (token.type === TOKEN_GROUP_BEGIN) {
          token.errType =
            ERROR_TOKEN_TYPE.UN_SUPPORT_MISSING_CLOSING_PARENTHESIS;
          this.addCorrection(
            CORRECTION_UNMATCHED_GROUP_LEFT_DELIMITER_IGNORED,
            [token],
          );
        } else {
          token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_EXTRA_CLOSING_PARENTHESIS;
          this.addCorrection(
            CORRECTION_UNMATCHED_GROUP_RIGHT_DELIMITER_IGNORED,
            [token],
          );
        }
      }
    }

    const notGroupIndexes = this.checkNotGroup();

    while (notGroupIndexes.length) {
      const index = notGroupIndexes.shift();
      if (isNumber(index)) {
        const token = tokens.splice(index, 1)[0];
        if (token.type === TOKEN_LOGICAL_NOT) {
          token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_NESTED_GROUP;
          this.addCorrection(CORRECTION_UN_SUPPORT_NESTED_GROUP, [token]);
        }
      }
    }

    const continueWordIndexes = this.checkContinuousWords();

    const continueWordTokens = continueWordIndexes.map(
      (index) => tokens[index],
    );

    if (continueWordTokens.length) {
      continueWordTokens.forEach((token) => {
        token.errType = ERROR_TOKEN_TYPE.UN_SUPPORT_WORDS_WITHOUT_PHRASE;
        this.addCorrection(CORRECTION_UN_SUPPORT_WORDS_WITHOUT_PHRASE, [token]);
      });
    }
  }

  /**
   * 检测没有使用引号包裹的连续多个单词
   */
  private checkContinuousWords() {
    const trackWords: number[] = [];

    for (const [index, token] of this.tokens.entries()) {
      if (this.isToken(token, TOKEN_TERM)) {
        trackWords.push(index);
      }
    }

    if (trackWords.length <= 1) return [];

    const continueWordIndex: number[] = [];

    // 检测两个 trackWord 之间的索引，是否都是空格
    for (let i = 0; i < trackWords.length; i++) {
      const beginIndex = trackWords[i];
      const endIndex = trackWords[i + 1];
      if (this.tokens[beginIndex] && this.tokens[beginIndex]) {
        const tokens = this.tokens.slice(beginIndex + 1, endIndex);
        if (tokens.length && tokens.every((t) => t.type === TOKEN_WHITESPACE)) {
          // 将beginIndex 到 endIndex 之间的索引都存储起来，两边都是闭区间
          continueWordIndex.push(
            ...Array.from(
              { length: endIndex - beginIndex + 1 },
              (_, i) => beginIndex + i,
            ),
          );
        }
      }
    }

    return continueWordIndex;
  }

  /**
   * 检测 not 后面跟随的group是否是单层的group
   */
  private checkNotGroup() {
    const trackNot: number[] = [];

    for (const [index, token] of this.tokens.entries()) {
      if (!this.isToken(token, TOKEN_LOGICAL_NOT)) {
        continue;
      }

      trackNot.push(index);
    }

    // 获取trackNot前一个索引和后一个索引之间的tokens，判断这些tokens是否有多个TOKEN_GROUP_BEGIN

    if (trackNot.length === 0) return [];

    const result = [];

    for (let i = 0; i < trackNot.length; i++) {
      const beginIndex = trackNot[i];
      const endIndex = trackNot[i + 1] || this.tokens.length;
      const tokens = this.tokens.slice(beginIndex, endIndex);
      const groupBegin = tokens.filter((t) => t.type === TOKEN_GROUP_BEGIN);
      if (groupBegin.length > 1) {
        result.push(trackNot[i]);
      }
    }

    return result;
  }

  private getUnmatchedGroupDelimiterIndexes(tokens: Token[]) {
    const trackLeft: number[] = [];
    const trackRight: number[] = [];

    for (const [index, token] of tokens.entries()) {
      if (!this.isToken(token, this.tokenShortcuts.get('groupDelimiter'))) {
        continue;
      }

      if (this.isToken(token, TOKEN_GROUP_BEGIN)) {
        trackLeft.push(index);
        continue;
      }

      if (isEmpty(trackLeft)) {
        trackRight.push(index);
      } else {
        trackLeft.pop();
      }
    }

    return [...trackLeft, ...trackRight];
  }

  /**
   * 添加错误数据
   * @param $type
   * @param tokens
   */
  private addCorrection($type: number, tokens: Token[]) {
    this.corrections.push(new Correction($type, tokens));
  }
}
