var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "s2w-workspace-folder-selector-container" }, [
    _c("div", { staticClass: "s2w-select-folder-label" }, [
      _c("span", { staticClass: "selector-label" }, [
        _vm._v(_vm._s(_vm.$t("BaseSaveToWorkspace.FolderSelector.label"))),
      ]),
    ]),
    _c("div", { staticClass: "s2w-workspace-folder-selector" }, [
      _vm.folderSelector.enableFoldersSearch
        ? _c(
            "div",
            { staticClass: "s2w-search-input" },
            [
              _c("div", { staticClass: "input-item" }, [
                _c("div", { staticClass: "search-target" }, [
                  _c(
                    "div",
                    {
                      staticClass: "search-switch-btn",
                      class: [_vm.folderSelector.searchType],
                      on: { click: _vm.toggleSearchType },
                    },
                    [
                      _c("PtIcon", {
                        staticClass: "search-switch-icon",
                        staticStyle: { "font-size": "20px" },
                        attrs: { icon: "SolidSwitchType" },
                      }),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "BaseSaveToWorkspace.FolderSelector.searchType." +
                                  _vm.folderSelector.searchType
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.folderSelector.searchKeywords,
                      expression: "folderSelector.searchKeywords",
                      modifiers: { trim: true },
                    },
                  ],
                  attrs: {
                    placeholder: _vm.$t(
                      "BaseSaveToWorkspace.FolderSelector.placeholder"
                    ),
                    maxlength: "40",
                  },
                  domProps: { value: _vm.folderSelector.searchKeywords },
                  on: {
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onKeypressEnter.apply(null, arguments)
                    },
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.folderSelector,
                          "searchKeywords",
                          $event.target.value.trim()
                        )
                      },
                      _vm.onKeywordsInput,
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _vm.folderSelector.searchKeywords
                ? _c("PtIcon", {
                    staticClass: "clear-btn",
                    staticStyle: { "font-size": "16px" },
                    attrs: { icon: "SolidCloseMedium" },
                    on: {
                      click: function ($event) {
                        return _vm.clearSearchInput()
                      },
                    },
                  })
                : _vm._e(),
              _c("PtIcon", {
                staticClass: "search-btn",
                staticStyle: { "font-size": "24px" },
                attrs: { icon: "SolidSearchLeft" },
                on: { click: _vm.onKeypressEnter },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm.folderSelector.loading
            ? _c(
                "div",
                { staticClass: "loading-pane" },
                [
                  _c("PtIcon", {
                    staticStyle: { "font-size": "48px" },
                    attrs: { icon: "SolidLoadingBars" },
                  }),
                ],
                1
              )
            : _vm.folderSelector.workspaceList &&
              _vm.folderSelector.workspaceList.length
            ? _c("WorkspaceList")
            : _c(
                "div",
                { staticClass: "no-data-pane" },
                [
                  _c("PtIcon", {
                    staticStyle: { "font-size": "40px" },
                    attrs: { icon: "SolidNoDataTip" },
                  }),
                  _c("div", [_vm._v(_vm._s(_vm.noSpaceTip))]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "add-workspace-btn",
          on: {
            click: function ($event) {
              return _vm.service.addWorkspace()
            },
          },
        },
        [
          _c("PtIcon", {
            staticStyle: { "font-size": "24px" },
            attrs: { icon: "SolidAddMedium" },
          }),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("BaseSaveToWorkspace.FolderSelector.addWorkspace")
              ) +
              " "
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }