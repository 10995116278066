// geetest 文档参考 https://docs.geetest.com/gt4/apirefer/api/web#onReady-callback
interface IGeetTestConfig {
    captchaId: string
    language?: IGeeTestLang
    product?: 'float' | 'popup' | 'bind'
    mask?: any
    nativeButton?: any
    rem?: string
    protocol?: string
    timeout?: number
    hideBar?: string[]
    apiServers?: string[]
    nextWidth?: string
    riskType?: string
    offlineCb?: any
    onError?: any
}

interface IGoogleCaptcha {
    captchaId: string
}
interface ICaptchaConfig {
    /**
     * 验证码类型,目前支持   'geeTest' | 'google'
     */
    type: ICaptchaType
    /**
     * 验证码公钥id
     */
    id: string
    /**
     * 极验UI交互的语言
     */
    lang: IConfigLang
    /**
     * 极验额外参数
     */
    obj?: {}
    /**
     * 失败回调
     */
    onFail?: any
}
type IConfigLang = 'cn' | 'en' | 'tw' | 'jp'
type IGeeTestLang = 'zho' | 'eng' | 'zho-tw'
type ICaptchaType = 'geeTest' | 'google'

/**
 * 极验的成功回调
 */
export interface IGeetestResponseData {
    lot_number: string
    captcha_output: string
    pass_token: string
    gen_time: string
}
/**
 * google验证码成功回调
 */
export interface IGoogleReCaptchaResponseData {
    recaptcha_token: string
}

export const GEETEST_TYPE: ICaptchaType = 'geeTest'
export const GOOGLE_TYPE: ICaptchaType = 'google'

/**
 * patsnap语言到极验语言映射表
 */
const langMap: Record<IConfigLang, IGeeTestLang> = {
    cn: 'zho',
    en: 'eng',
    tw: 'zho-tw',
    jp: 'eng',
}

function debug(message: any) {
    console.error('[@patsnap-utils/captcha-verification]', message)
}

export class ReCaptchaVerification {
    constructor(config: ICaptchaConfig) {
        this.init(config)
    }

    private type: ICaptchaType = GEETEST_TYPE
    private geetTestConfig: IGeetTestConfig = {
        captchaId: '',
        product: 'bind',
    }
    private googleCaptcha: IGoogleCaptcha = {
        captchaId: '',
    }
    private isScriptReady = false
    private get scriptUrl(): string {
        if (this.type === GEETEST_TYPE) {
            return 'https://static.geetest.com/v4/gt4.js'
        }
        if (this.type === GOOGLE_TYPE && this.googleCaptcha.captchaId) {
            return `https://recaptcha.net/recaptcha/enterprise.js?render=${this.googleCaptcha.captchaId}`
        }
        debug('Unknown captcha type, Please check')
        return ''
    }
    private onFail = function(fail: string) {
        console.log('fail', fail)
    }

    private init(config: ICaptchaConfig) {
        const { type, id, lang, obj, onFail } = config
        this.type = type

        if (onFail) {
            this.onFail = onFail
        }
        if (this.type === GEETEST_TYPE) {
            this.geetTestConfig = {
                ...this.geetTestConfig,
                captchaId: id,
                language: langMap[lang],
                ...obj,
            }
        }
        if (this.type === GOOGLE_TYPE) {
            this.googleCaptcha = {
                captchaId: id,
            }
        }
    }

    private async injectScript() {
        if (this.isScriptReady) {
            return Promise.resolve()
        }
        return new Promise<void>((resolve, reject) => {
            const script = document.createElement('script')
            script.addEventListener('load', () => {
                this.isScriptReady = true
                resolve()
            })
            script.addEventListener('error', reject)
            script.setAttribute('src', this.scriptUrl)
            document.body.appendChild(script)
        })
    }

    public async captchaValidate() {
        await this.injectScript()
        if (this.type === GEETEST_TYPE) {
            return this.geetestValidate()
        }

        if (this.type === GOOGLE_TYPE) {
            return this.googleValidateInvisible()
        }
        throw new Error('Unknown captcha type, Please check')
    }

    private async geetestValidate(): Promise<IGeetestResponseData> {
        return new Promise((resolve, reject) => {
            ;(window as any).initGeetest4(this.geetTestConfig, (captchaObj: any) => {
                // 加载完毕
                captchaObj.onReady(() => {
                    captchaObj.showBox()
                })
                // 验证成功执行
                captchaObj.onSuccess(() => {
                    const validRes = captchaObj.getValidate()
                    captchaObj.destroy()
                    resolve(validRes)
                })
                // 验证失败执行
                captchaObj.onFail((fail: any) => {
                    this.onFail(fail)
                })
                // 关闭验证弹框
                captchaObj.onClose(() => {
                    reject({
                        type: 'close',
                    })
                })
                // 验证错误
                captchaObj.onError((error: any) => {
                    reject({
                        type: 'error',
                        data: error,
                    })
                })
            })
        })
    }

    private async googleValidateInvisible(): Promise<IGoogleReCaptchaResponseData> {
        let captcha: any = (window as any).grecaptcha
        return new Promise((resolve, reject) => {
            try {
                captcha.enterprise.ready(() => {
                    captcha.enterprise
                        .execute(this.googleCaptcha.captchaId, {
                            action: 'common',
                        })
                        .then(function(token: string) {
                            resolve({ recaptcha_token: token })
                        })
                })
            } catch (error) {
                reject({
                    type: 'error',
                    data: error,
                })
            }
        })
    }
}
